import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { NoAvailablePowerOfAttorneyConversationAction } from '../../../api/client';
import { actionCreators as ConversationSupervisorStoreActionCreators } from '../../../store/ConversationSupervisorStore';
import { actionCreators as DebtorSearchStoreActionCreators } from '../../../store/DebtorSearchStore';
import { DebtorIndex } from '../../../api/client';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

interface PropsFromDispatch {
  addConversationAction: typeof ConversationSupervisorStoreActionCreators.addConversationAction;
  clearSearchResultsAction: typeof DebtorSearchStoreActionCreators.clearSearchResults;
  submitConversation: typeof ConversationSupervisorStoreActionCreators.submitConversation;
}

interface InjectedProps {
  toggleModal: () => void;
  debtor: DebtorIndex;
}

interface State {
  powerOfAttorneyText: string;
}

type ComponentProps = PropsFromDispatch & InjectedProps;
export class NoPowerOfAttorney extends React.Component<ComponentProps, State> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      powerOfAttorneyText: '',
    };
  }

  isValid() {
    return !!this.state.powerOfAttorneyText.trim();
  }

  handlePowerOfAttorneyTextChange = event => {
    this.setState({ powerOfAttorneyText: event.target.value });
  }

  handleOK = () => {
    const action: NoAvailablePowerOfAttorneyConversationAction = {
      text: this.state.powerOfAttorneyText,
      discriminator: 'NoAvailablePowerOfAttorneyConversationAction',
    };

    this.props.addConversationAction(action);
    this.props.submitConversation(this.props.debtor.id, this.props.debtor.name, true);
    this.props.clearSearchResultsAction();
    this.props.toggleModal();
  }
  public render() {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <TextField
          label="Beskrivelse"
          required={false}
          multiline={true}
          maxRows={10}
          margin="normal"
          variant="outlined"
          value={this.state.powerOfAttorneyText}
          onChange={this.handlePowerOfAttorneyTextChange}
        />
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
          <Button variant="contained" onClick={() => this.props.toggleModal()}>
            Avbryt
          </Button>
          <Button variant="contained" onClick={() => this.handleOK()} disabled={!this.isValid()}>
            OK
          </Button>
        </Box>
      </Box>
    );
  }
}

const mapDispatchToProps: PropsFromDispatch = {
  addConversationAction: ConversationSupervisorStoreActionCreators.addConversationAction,
  clearSearchResultsAction: DebtorSearchStoreActionCreators.clearSearchResults,
  submitConversation: ConversationSupervisorStoreActionCreators.submitConversation,
};

export default compose(
  connect(null, mapDispatchToProps)
)(NoPowerOfAttorney);
