import React from 'react';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import SmsIcon from '@mui/icons-material/TextsmsOutlined';
import SmsReceivedIcon from '@mui/icons-material/PhoneAndroid';
import SmsFailedIcon from '@mui/icons-material/PermDeviceInformation';
import PauseIcon from '@mui/icons-material/PauseCircleOutline';
import UndoIcon from '@mui/icons-material/UndoOutlined';
import LockIcon from '@mui/icons-material/LockOutlined';
import CreditIcon from '@mui/icons-material/MoneyOffOutlined';
import MailIcon from '@mui/icons-material/MailOutline';
import MoneyIcon from '@mui/icons-material/AttachMoneyOutlined';
import DoneIcon from '@mui/icons-material/Done';
import PhoneCallIcon from '@mui/icons-material/Call';
import SeeNoteFieldIcon from '@mui/icons-material/VisibilityOutlined';
import FeeAddedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import EmailSentIcon from '@mui/icons-material/AlternateEmail';
import { DigiPostIcon } from '../../../components/CustomIcons';
import { colors } from '../../../colors';
import { ClaimEventType } from '../../../api/client';

const icons = {
  'PaymentInformationSentByEmail' : { icon: EmailIcon, color: colors.black },
  'PaymentInformationSentBySms': { icon: SmsIcon, color: colors.black },
  'ObjectionRegistered' : { icon: PauseIcon, color: colors.orange },
  'InvoiceReturn' : { icon: UndoIcon, color: colors.black },
  'DebtCollectionWarningReturn' : { icon: UndoIcon, color: colors.black },
  'Lost' : { icon: LockIcon, color: colors.secondary.slategray },
  'Credit' : { icon: CreditIcon, color: colors.secondary.candy },
  'Letter' : { icon: MailIcon, color: colors.black },
  'DebtCollectionWarningLetter' : { icon: MailIcon, color: colors.black },
  'Payment' : { icon: MoneyIcon, color: colors.secondary.bamboo },
  'Remittance' : { icon: MoneyIcon, color: colors.green },
  'InvoiceFullyPaid' : { icon: DoneIcon, color: colors.green },
  'InvoiceSmsReceived' : { icon: SmsReceivedIcon, color: colors.green },
  'InvoiceSmsNotReceived' : { icon: SmsFailedIcon, color: colors.secondary.candy },
  'MobilePaySmsReceived' : { icon: SmsReceivedIcon, color: colors.green },
  'MobilePaySmsNotReceived' : { icon: SmsFailedIcon, color: colors.secondary.candy },
  'SmsSent' : { icon: SmsReceivedIcon, color: colors.black },
  'PhoneCall' : { icon: PhoneCallIcon, color: colors.black },
  'SeeNoteField' : { icon: SeeNoteFieldIcon, color: colors.black },
  'FeeAdded' : { icon: FeeAddedIcon, color: colors.black },
  'DigiPostInvoice' : { icon: DigiPostIcon, color: colors.black },
  'DigiPostDebtCollectionWarning' : { icon: DigiPostIcon, color: colors.black },
  'Email' : { icon: EmailSentIcon, color: colors.black }
};

interface Props {
  eventType: ClaimEventType;
}

export default function DebtorClaimEventIcon(props: Props) {
  const Icon = icons[ClaimEventType[props.eventType]] === undefined ? null : icons[ClaimEventType[props.eventType]].icon;
  const Color = icons[ClaimEventType[props.eventType]] === undefined ? null : icons[ClaimEventType[props.eventType]].color;
  return (
    Icon && <Icon style={{ color: Color }} />
  );
};
