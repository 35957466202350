import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { SelectedDebtorClaim, DebtorMadePaymentRecentlyAction, DebtorWillSendPaymentConfirmationAction, ConversationAction } from '../../../../api/client';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Step } from '../ConversationSupervisorStep';
import StepZilla from 'react-stepzilla';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { actionCreators as ConversationSupervisorStoreActionCreators } from '../../../../store/ConversationSupervisorStore';
import { actionCreators as DebtorClaimsStoreActionCreators } from '../../../../store/DebtorClaimsStore';
import PreviousStep from './PreviousStep';

interface PropsFromState {
  selectedDebtorClaims: SelectedDebtorClaim[];
}

interface PropsFromDispatch {
  addConversationSupervisorAction: typeof ConversationSupervisorStoreActionCreators.addConversationAction;
  clearDebtorClaimsSelection: typeof DebtorClaimsStoreActionCreators.clearDebtorClaimsSelection;
}

interface InjectedProps {
  jumpToStep?: typeof StepZilla.jumpToStep;
}

type ComponentProps = PropsFromState & PropsFromDispatch & InjectedProps;

export class PaymentNotRegistered extends React.Component<ComponentProps> {
  handleDebtorWillSendPaymentConfirmationAction = () => {
    const action: DebtorWillSendPaymentConfirmationAction = {
      selectedDebtorClaims: this.props.selectedDebtorClaims,
      discriminator: 'DebtorWillSendPaymentConfirmationAction',
    };

    this.addConversationActionAndJumpToSummary(action);
  }

  handleDebtorMadePaymentRecentlyAction = () => {
    const action: DebtorMadePaymentRecentlyAction = {
      selectedDebtorClaims: this.props.selectedDebtorClaims,
      discriminator: 'DebtorMadePaymentRecentlyAction',
    };

    this.addConversationActionAndJumpToSummary(action);
  }

  addConversationActionAndJumpToSummary = (action: ConversationAction) => {
    this.props.addConversationSupervisorAction(action);
    this.props.clearDebtorClaimsSelection();
    this.props.jumpToStep(Step.Summary);
  }

  public render() {
    return (
      <React.Fragment>
        <PreviousStep previousStep={() => this.props.jumpToStep(Step.HasPaid)} />
        <List>
          <ListItem sx={{
            paddingRight: 0,
          }} button={true} onClick={this.handleDebtorWillSendPaymentConfirmationAction}>
            <ListItemText primary="Debitor skal sende kvittering" />
            <ArrowRight />
          </ListItem>
          <ListItem sx={{
            paddingRight: 0,
          }} button={true} onClick={this.handleDebtorMadePaymentRecentlyAction}>
            <ListItemText primary="Betaling foretatt nylig" />
            <ArrowRight />
          </ListItem>
        </List>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ debtorClaims }: ApplicationState): PropsFromState => ({
  selectedDebtorClaims: debtorClaims.selectedDebtorClaims
});

const mapDispatchToProps: PropsFromDispatch = {
  addConversationSupervisorAction: ConversationSupervisorStoreActionCreators.addConversationAction,
  clearDebtorClaimsSelection: DebtorClaimsStoreActionCreators.clearDebtorClaimsSelection,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentNotRegistered);
