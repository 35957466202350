import { AuthenticationState, reducer as AuthenticationReducer, KnownAction as AuthenticationActions } from './AuthenticationStore';
import { ConversationSupervisorState, reducer as ConversationSupervisorReducer, KnownAction as ConversationSupervisorActions} from './ConversationSupervisorStore';
import { DebtorClaimsState, reducer as DebtorClaimsReducer, KnownAction as DebtorClaimsActions } from './DebtorClaimsStore';
import { DebtorDebtCollectionsState, reducer as DebtorDebtCollectionsReducer, KnownAction as DebtorDebtCollectionsActions } from './DebtorDebtCollectionsStore';
import { DebtorInvoicesState, reducer as DebtorInvoicesReducer, KnownAction as DebtorInvoicesActions } from './DebtorInvoicesStore';
import { reducer as DebtorClaimDownloadReducer, KnownAction as DebtorClaimDownloadActions } from './DebtorClaimDownloadStore';
import { DebtorLeapClaimsState, reducer as DebtorLeapClaimsReducer, KnownAction as DebtorLeapClaimsActions } from './DebtorLeapClaimsStore';
import { DebtorLogItemsState, reducer as DebtorLogItemsReducer, KnownAction as DebtorLogItemsActions } from './DebtorLogItemsStore';
import { DebtorOverviewState, reducer as DebtorOverviewReducer, KnownAction as DebtorOverviewActions } from './DebtorOverviewStore';
import { DebtorNationalIdentificationNumberSearchState, reducer as DebtorNationalIdentificationNumberSearchReducer, KnownAction as DebtorNationalIdentificationNumberSearchActions } from './DebtorNationalIdentificationNumberSearchStore';
import { DebtorSearchState, reducer as DebtorSearchReducer, KnownAction as DebtorSearchActions } from './DebtorSearchStore';
import { NotificationsState, reducer as NotificationsReducer, KnownAction as NotificationsActions } from './NotificationsStore';
import { PowerOfAttorneyState, reducer as PowerOfAttorneyReducer, KnownAction as PowerOfAttorneyActions } from './PowerOfAttorneyStore';
import { MenuNavigationState, reducer as MenuNavigationReducer, KnownAction as MenuNavigationActions } from './MenuNavigation';
import { ApplicationVersionState, reducer as ApplicationVersionReducer, KnownAction as ServiceWorkerActions } from './ServiceWorkerStore';

export interface ApplicationState {
  authentication: AuthenticationState;
  conversationSupervisor: ConversationSupervisorState;
  debtorClaims: DebtorClaimsState;
  debtorDebtCollections: DebtorDebtCollectionsState;
  debtorInvoices: DebtorInvoicesState;
  debtorLeapClaims: DebtorLeapClaimsState;
  debtorLogItems: DebtorLogItemsState;
  debtorOverview: DebtorOverviewState;
  debtorNationalIdentificationNumberSearch: DebtorNationalIdentificationNumberSearchState;
  debtorSearch: DebtorSearchState;
  notifications: NotificationsState;
  powerOfAttorney: PowerOfAttorneyState;
  menuNavigation: MenuNavigationState;
  applicationVersion: ApplicationVersionState;
}

export const reducers = {
  authentication: AuthenticationReducer,
  conversationSupervisor: ConversationSupervisorReducer,
  debtorClaims: DebtorClaimsReducer,
  debtorClaimDownload: DebtorClaimDownloadReducer,
  debtorDebtCollections: DebtorDebtCollectionsReducer,
  debtorInvoices: DebtorInvoicesReducer,
  debtorLeapClaims: DebtorLeapClaimsReducer,
  debtorLogItems: DebtorLogItemsReducer,
  debtorOverview: DebtorOverviewReducer,
  debtorNationalIdentificationNumberSearch: DebtorNationalIdentificationNumberSearchReducer,
  debtorSearch: DebtorSearchReducer,
  notifications: NotificationsReducer,
  powerOfAttorney: PowerOfAttorneyReducer,
  menuNavigation: MenuNavigationReducer,
  applicationVersion: ApplicationVersionReducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}

export type KnownActions =
  AuthenticationActions |
  ConversationSupervisorActions |
  DebtorClaimsActions |
  DebtorDebtCollectionsActions |
  DebtorInvoicesActions |
  DebtorClaimDownloadActions |
  DebtorLeapClaimsActions |
  DebtorLogItemsActions |
  DebtorOverviewActions |
  DebtorNationalIdentificationNumberSearchActions |
  DebtorSearchActions |
  NotificationsActions |
  PowerOfAttorneyActions |
  MenuNavigationActions |
  ServiceWorkerActions
  ;