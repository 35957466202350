import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { actionCreators as PowerOfAttorneyStoreActionCreators } from '../../../store/PowerOfAttorneyStore';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AvailablePowerOfAttorney } from '../../../api/client';
import { ApplicationState } from '../../../store';


interface InjectedProps {
  powerOfAttorney: AvailablePowerOfAttorney;
}

interface PropsFromDispatch {
  deactivatePowerOfAttorney: typeof PowerOfAttorneyStoreActionCreators.deactivatePowerOfAttorney;
}

interface PropsFromState {
  userProfile?: string;
}

interface State {
  open: boolean;
}

type ComponentProps = PropsFromDispatch & PropsFromState & InjectedProps;

export class DeactivatePowerOfAttorneyDialog extends React.Component<ComponentProps, State> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      open: false
    };
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  }

  handleDeactivatePowerOfAttorney = () => {
    this.props.deactivatePowerOfAttorney(this.props.powerOfAttorney.debtorId || '', this.props.powerOfAttorney.id, this.props.userProfile || '');
    this.handleClose();
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  public render() {
    return (
      <React.Fragment>
        <Box sx={{
          alignItems: 'center',
          display: 'inline-flex'
        }}>
          <Tooltip title="Deaktiver fullmakt">
            <IconButton aria-label="Delete" onClick={this.handleClickOpen}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Deaktiver fullmakt</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Er du helt sikker på at du vil deaktivere fullmakten?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.handleClose}>
              Avbryt
            </Button>
            <Button variant="contained" onClick={this.handleDeactivatePowerOfAttorney} color="primary" autoFocus={true}>
              Deaktiver fullmakten
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps: PropsFromDispatch = {
  deactivatePowerOfAttorney: PowerOfAttorneyStoreActionCreators.deactivatePowerOfAttorney,
};

const mapStateToProps = ({ authentication, notifications }: ApplicationState): PropsFromState => ({
  userProfile: authentication.userProfile,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(DeactivatePowerOfAttorneyDialog);
