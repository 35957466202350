import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Step } from '../ConversationSupervisorStep';
import StepZilla from 'react-stepzilla';
import ArrowRight from '@mui/icons-material/ArrowRight';
import PreviousStep from './PreviousStep';

interface Props {
  hasSelectedDebtorClaims: boolean;
}

interface InjectedProps {
  jumpToStep?: typeof StepZilla.jumpToStep;
}

type ComponentProps = Props & InjectedProps;

const PaymentInformation: React.FunctionComponent<ComponentProps> = props => (
  <React.Fragment>
    <PreviousStep previousStep={() => props.jumpToStep(Step.PaymentAgreement)} />
    <List>
      <ListItem sx={{paddingRight: 0}} button={true} disabled={!props.hasSelectedDebtorClaims} onClick={() => props.jumpToStep(Step.SendPaymentInformation)}>
        <ListItemText primary="Send betalingsinformasjon" />
        <ArrowRight />
      </ListItem>
    </List>
  </React.Fragment>
);

export default PaymentInformation;
