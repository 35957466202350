import { Reducer } from 'redux';
import { AppThunkAction } from '../store';
import { DebtorClaimEvent, Invoice } from '../api/client';
import { ActionTypes as NotificationsStoreActionTypes, AddNotificationAction } from './NotificationsStore';
import { FetchConfig } from './middlewares/FetchMiddleware';

export enum ActionTypes {
  GET_DEBTOR_INVOICES = '@@debtorInvoices/GET_DEBTOR_INVOICES',
  GET_DEBTOR_INVOICES_REQUEST = '@@debtorInvoices/GET_DEBTOR_INVOICES_REQUEST',
  GET_DEBTOR_INVOICES_SUCCESS = '@@debtorInvoices/GET_DEBTOR_INVOICES_SUCCESS',
  GET_DEBTOR_INVOICES_FAILURE = '@@debtorInvoices/GET_DEBTOR_INVOICES_FAILURE',
  GET_INVOICE_EVENTS = '@@debtorInvoices/GET_INVOICE_EVENTS',
  GET_INVOICE_EVENTS_REQUEST = '@@debtorInvoices/GET_INVOICE_EVENTS_REQUEST',
  GET_INVOICE_EVENTS_SUCCESS = '@@debtorInvoices/GET_INVOICE_EVENTS_SUCCESS',
  GET_INVOICE_EVENTS_FAILURE = '@@debtorInvoices/GET_INVOICE_EVENTS_FAILURE',
  CLEAR_DEBTOR_INVOICES = '@@debtorInvoices/CLEAR_DEBTOR_INVOICES'
}

export interface DebtorInvoicesState {
  invoices: Invoice[];
  isLoading: boolean;
  isLoadingEvents: boolean;
}

interface GetDebtorInvoicesAction { type: ActionTypes.GET_DEBTOR_INVOICES; fetchConfig: FetchConfig; }
interface GetDebtorInvoicesRequestAction { type: ActionTypes.GET_DEBTOR_INVOICES_REQUEST; id: string; }
interface GetDebtorInvoicesSuccessAction { type: ActionTypes.GET_DEBTOR_INVOICES_SUCCESS; response: Invoice[]; }
interface GetDebtorInvoicesFailureAction { type: ActionTypes.GET_DEBTOR_INVOICES_FAILURE; }
interface GetInvoiceEventsAction { type: ActionTypes.GET_INVOICE_EVENTS; fetchConfig: FetchConfig; }
interface GetInvoiceEventsRequestAction { type: ActionTypes.GET_INVOICE_EVENTS_REQUEST; debtorId: string; referenceId: number; invoiceNumber: string; }
interface GetInvoiceEventsSuccessAction { type: ActionTypes.GET_INVOICE_EVENTS_SUCCESS; debtorId: string; referenceId: number; invoiceNumber: string; response: DebtorClaimEvent[]; }
interface GetInvoiceEventsFailureAction { type: ActionTypes.GET_INVOICE_EVENTS_FAILURE; debtorId: string; referenceId: number; invoiceNumber: string; }
interface ClearDebtorInvoicesAction { type: ActionTypes.CLEAR_DEBTOR_INVOICES; }

export type KnownAction = GetDebtorInvoicesAction
  | GetDebtorInvoicesRequestAction
  | GetDebtorInvoicesSuccessAction
  | GetDebtorInvoicesFailureAction
  | GetInvoiceEventsAction
  | GetInvoiceEventsRequestAction
  | GetInvoiceEventsSuccessAction
  | GetInvoiceEventsFailureAction
  | ClearDebtorInvoicesAction
  | AddNotificationAction;

let abortController = new AbortController();

export const actionCreators = {
  getDebtorInvoices: (ids: string[]): AppThunkAction<KnownAction> => dispatch => {
    abortController = new AbortController();
    ids.forEach(id => {
      return dispatch({
        type: ActionTypes.GET_DEBTOR_INVOICES,
        fetchConfig: {
          init: { type: ActionTypes.GET_DEBTOR_INVOICES_REQUEST, id },
          path: `/api/Debtors/${id}/invoices`,
          signal: abortController.signal,
          success: (json) => {
            dispatch({ type: ActionTypes.GET_DEBTOR_INVOICES_SUCCESS, response: json });
          },
          failure: (error: string) => {            
            dispatch({ type: ActionTypes.GET_DEBTOR_INVOICES_FAILURE });
            if (error !== "AbortError") {
              dispatch({ type: NotificationsStoreActionTypes.ADD_NOTIFICATION, notification: { message: 'Kunne ikke hente debitor sine fakturaer', variant: 'error' } });
            }
          }
        }
      });
    });
  },
  getInvoiceEvents: (debtorId: string, referenceId: number, invoiceNumber: string): AppThunkAction<KnownAction> => dispatch => {
    abortController = new AbortController();
    return dispatch({
      type: ActionTypes.GET_INVOICE_EVENTS,
      fetchConfig: {
        init: { type: ActionTypes.GET_INVOICE_EVENTS_REQUEST, debtorId: debtorId, referenceId: referenceId, invoiceNumber: invoiceNumber },
        path: `/api/invoices/debtor/${debtorId}/referenceid/${referenceId}/events?invoiceNumber=${invoiceNumber}`,
        signal: abortController.signal,
        success: (json) => {
          dispatch({ type: ActionTypes.GET_INVOICE_EVENTS_SUCCESS, debtorId: debtorId, referenceId: referenceId, invoiceNumber: invoiceNumber, response: json });
        },
        failure: (error: string) => {
          dispatch({ type: ActionTypes.GET_INVOICE_EVENTS_FAILURE, debtorId: debtorId, referenceId: referenceId, invoiceNumber: invoiceNumber });
          dispatch({ type: NotificationsStoreActionTypes.ADD_NOTIFICATION, notification: { message: `Kunne ikke hente hendelser for faktura ${invoiceNumber}`, variant: 'error' } });
        }
      }
    });
  },
  clearDebtorInvoices: (): AppThunkAction<KnownAction> => dispatch => {
    dispatch({ type: ActionTypes.CLEAR_DEBTOR_INVOICES });
  }
};

export const initialState: DebtorInvoicesState = { invoices: [], isLoading: false, isLoadingEvents: false };

export const reducer: Reducer<DebtorInvoicesState | undefined, KnownAction> = (state: DebtorInvoicesState = initialState, action: KnownAction) => {
  switch (action.type) {
    case ActionTypes.GET_DEBTOR_INVOICES_REQUEST:
      return { ...state, isLoading: true };
    case ActionTypes.GET_DEBTOR_INVOICES_SUCCESS:
      return { ...state, invoices: state.invoices.concat(action.response), isLoading: false };
    case ActionTypes.GET_DEBTOR_INVOICES_FAILURE:
      return { ...state, isLoading: false };
    case ActionTypes.GET_INVOICE_EVENTS_REQUEST:
      return { ...state, isLoadingEvents: true };
    case ActionTypes.GET_INVOICE_EVENTS_SUCCESS:
      const invoiceIndex = state.invoices.findIndex(x => x.eventsReferenceId === action.referenceId);
      return {
        ...state, invoices: [
          ...state.invoices.slice(0, invoiceIndex).concat({ ...state.invoices[invoiceIndex], events: action.response, eventsHaveBeenFetched: true }),
          ...state.invoices.slice(invoiceIndex + 1)
        ], isLoadingEvents: false
      };
    case ActionTypes.GET_INVOICE_EVENTS_FAILURE:
      return { ...state, isLoadingEvents: false };
    case ActionTypes.CLEAR_DEBTOR_INVOICES:
      abortController.abort();
      return { ...state, invoices: [] };
    default:
      return state;
  }
};