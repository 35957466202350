import React from 'react';
import compose from 'recompose/compose';
import { ApplicationState } from '../../../store';
import { actionCreators as DebtorLogItemsStoreActionCreators } from '../../../store/DebtorLogItemsStore';
import { connect } from 'react-redux';
import { DebtorLogItem, SelectedDebtorClaim } from '../../../api/client';
import { getDebtorIdsFromQueryString } from '../../../components/Utils';
import { getDebtorLogItems } from '../../../store/Selectors';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import DebtorLogItemComponent from '../../../components/debtorLog/presentation/DebtorLogItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const topRowHeight = 333;

interface PropsFromState {
  logItems: DebtorLogItem[];
  isLoading: boolean;
  selectedDebtorClaims: SelectedDebtorClaim[];
}

interface State {
  height: number;
}

interface PropsFromDispatch {
  clearDebtorLogItems: typeof DebtorLogItemsStoreActionCreators.clearDebtorLogItems;
  getDebtorLogItems: typeof DebtorLogItemsStoreActionCreators.getDebtorLogItems;
}

type ComponentProps = State & PropsFromState & PropsFromDispatch & RouteComponentProps;

class DebtorLog extends React.Component<ComponentProps, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      height: window.innerHeight
    };
  }

  updateWindowHeight = () => {
    this.setState({
      height: window.innerHeight
    });
  }

  componentDidMount() {
    const debtorIds = getDebtorIdsFromQueryString(this.props.location);
    this.props.getDebtorLogItems(debtorIds);
    window.addEventListener('resize', this.updateWindowHeight, false);
  }

  componentWillUnmount() {
    this.props.clearDebtorLogItems();
    window.removeEventListener('resize', this.updateWindowHeight, false);
  }

  public render() {
    return (
      <React.Fragment>
        {this.props.isLoading ?
          <CircularProgress sx={{margin: '16px auto', display: 'block',}} /> :
          this.props.logItems.length === 0 &&
          <Typography sx={{ margin: '16px', fontSize: '14px'}}>Debitor har ingen logg</Typography>
        }
        <Box sx={{
          overflowY: 'auto',
          maxHeight: this.state.height - topRowHeight
        }}>
          {this.props.logItems &&
            this.props.logItems.map((item, i) =>
            <DebtorLogItemComponent
              key={i}
              logItem={item}
              selected={item.debtorClaimNumbers ? item.debtorClaimNumbers!.filter(o => this.props.selectedDebtorClaims.filter(n => n.number === o).length > 0).length > 0 : false}
            />)
          }
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  logItems: getDebtorLogItems(state),
  selectedDebtorClaims: state.debtorClaims.selectedDebtorClaims,
  isLoading: state.debtorLogItems.isLoading,
});

const mapDispatchToProps: PropsFromDispatch = {
  clearDebtorLogItems: DebtorLogItemsStoreActionCreators.clearDebtorLogItems,
  getDebtorLogItems: DebtorLogItemsStoreActionCreators.getDebtorLogItems,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(DebtorLog));
