import React, { useCallback } from 'react';
import { ApplicationState } from '../../store';
import { history } from '../../AppHistory';
import { useSelector } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { authenticatedAsCustomerCareWorker, authenticatedAsAdministrator, authenticatedAsTerminalWorker } from '../../store/AuthenticationStore';

export default function ApplicationBarMenu() {
  const hangleMenuNavigationChange = useCallback((event: object, newValue: string): void => {
    console.log('applicationBarMenu hangleMenuNavigationChange', event, newValue);
    history.push(`/${newValue}`);
  }, []);

  const administrator = useSelector<ApplicationState>(state => authenticatedAsAdministrator(state.authentication));
  const customerCareWorker = useSelector<ApplicationState>(state => authenticatedAsCustomerCareWorker(state.authentication));
  const terminalWorker = useSelector<ApplicationState>(state => authenticatedAsTerminalWorker(state.authentication));
  const menuNavigationPathValue = useSelector<ApplicationState>(state => state.menuNavigation.path);

  return (
    <React.Fragment>
      {menuNavigationPathValue && ([customerCareWorker, terminalWorker].filter(i => i === true).length > 1 || administrator) ?
        (
          <Tabs value={menuNavigationPathValue} onChange={hangleMenuNavigationChange}>
            {administrator ? <Tab value="administration" label="Administrator" /> : null}
            {customerCareWorker ? <Tab value="search" label="Kundesenter" /> : null}
            {terminalWorker ? <Tab value="terminal" label="Terminal" /> : null}
          </Tabs>
        ) : null
      }
    </React.Fragment>
  );
}
