import { unionWith } from 'lodash/array';
import { isEqual } from 'lodash/lang';
import { Reducer } from 'redux';
import { AppThunkAction } from '../store';
import { DebtCollection, DebtorClaimEvent } from '../api/client';
import { ActionTypes as NotificationsStoreActionTypes, AddNotificationAction } from './NotificationsStore';
import { FetchConfig } from './middlewares/FetchMiddleware';

export enum ActionTypes {
  GET_DEBTOR_DEBT_COLLECTIONS = '@@debtorDebtCollections/GET_DEBTOR_DEBT_COLLECTIONS',
  GET_DEBTOR_DEBT_COLLECTIONS_REQUEST = '@@debtorDebtCollections/GET_DEBTOR_DEBT_COLLECTIONS_REQUEST',
  GET_DEBTOR_DEBT_COLLECTIONS_SUCCESS = '@@debtorDebtCollections/GET_DEBTOR_DEBT_COLLECTIONS_SUCCESS',
  GET_DEBTOR_DEBT_COLLECTIONS_FAILURE = '@@debtorDebtCollections/GET_DEBTOR_DEBT_COLLECTIONS_FAILURE',
  GET_DEBT_COLLECTION_EVENTS = '@@debtorDebtCollections/GET_DEBT_COLLECTION_EVENTS',
  GET_DEBT_COLLECTION_EVENTS_REQUEST = '@@debtorDebtCollections/GET_DEBT_COLLECTION_EVENTS_REQUEST',
  GET_DEBT_COLLECTION_EVENTS_SUCCESS = '@@debtorDebtCollections/GET_DEBT_COLLECTION_EVENTS_SUCCESS',
  GET_DEBT_COLLECTION_EVENTS_FAILURE = '@@debtorDebtCollections/GET_DEBT_COLLECTION_EVENTS_FAILURE',
  CLEAR_DEBTOR_DEBT_COLLECTIONS = '@@debtorDebtCollections/CLEAR_DEBTOR_DEBT_COLLECTIONS'
}

export interface DebtorDebtCollectionsState {
  debtCollections: DebtCollection[];
  isLoading: boolean;
  isLoadingEvents: boolean;
}

interface GetDebtorDebtCollectionsAction { type: ActionTypes.GET_DEBTOR_DEBT_COLLECTIONS; fetchConfig: FetchConfig; }
interface GetDebtorDebtCollectionsRequestAction { type: ActionTypes.GET_DEBTOR_DEBT_COLLECTIONS_REQUEST; id: string; }
interface GetDebtorDebtCollectionsSuccessAction { type: ActionTypes.GET_DEBTOR_DEBT_COLLECTIONS_SUCCESS; response: DebtCollection[]; }
interface GetDebtorDebtCollectionsFailureAction { type: ActionTypes.GET_DEBTOR_DEBT_COLLECTIONS_FAILURE; }
interface GetDebtCollectionEventsAction { type: ActionTypes.GET_DEBT_COLLECTION_EVENTS; fetchConfig: FetchConfig; }
interface GetDebtCollectionEventsRequestAction { type: ActionTypes.GET_DEBT_COLLECTION_EVENTS_REQUEST; caseNumber: string; }
interface GetDebtCollectionEventsSuccessAction { type: ActionTypes.GET_DEBT_COLLECTION_EVENTS_SUCCESS; caseNumber: string; response: DebtorClaimEvent[]; }
interface GetDebtCollectionEventsFailureAction { type: ActionTypes.GET_DEBT_COLLECTION_EVENTS_FAILURE; }
interface ClearDebtorDebtCollections { type: ActionTypes.CLEAR_DEBTOR_DEBT_COLLECTIONS; }

export type KnownAction = GetDebtorDebtCollectionsAction
  | GetDebtorDebtCollectionsRequestAction
  | GetDebtorDebtCollectionsSuccessAction
  | GetDebtorDebtCollectionsFailureAction
  | GetDebtCollectionEventsAction
  | GetDebtCollectionEventsRequestAction
  | GetDebtCollectionEventsSuccessAction
  | GetDebtCollectionEventsFailureAction
  | ClearDebtorDebtCollections
  | AddNotificationAction;

let abortController = new AbortController();

export const actionCreators = {
  getDebtorDebtCollections: (ids: string[]): AppThunkAction<KnownAction> => dispatch => {
    abortController = new AbortController();
    ids.forEach(id => {
      return dispatch({
        type: ActionTypes.GET_DEBTOR_DEBT_COLLECTIONS,
        fetchConfig: {
          init: { type: ActionTypes.GET_DEBTOR_DEBT_COLLECTIONS_REQUEST, id },
          path: `/api/Debtors/${id}/debtcollections`,
          signal: abortController.signal,
          success: (json) => {
            if (json === null) {
              json = [];
            }
            dispatch({ type: ActionTypes.GET_DEBTOR_DEBT_COLLECTIONS_SUCCESS, response: json });
          },
          failure: (error: string) => {
            dispatch({ type: ActionTypes.GET_DEBTOR_DEBT_COLLECTIONS_FAILURE });
            if (error !== "AbortError") {
              dispatch({ type: NotificationsStoreActionTypes.ADD_NOTIFICATION, notification: { message: 'Kunne ikke hente debitor sine inkassosaker', variant: 'error' } });
            }
          },          
        }
      });
    });
  },

  getDebtCollectionEvents: (caseNumber: string): AppThunkAction<KnownAction> => dispatch => {
    abortController = new AbortController();
    return dispatch({
      type: ActionTypes.GET_DEBT_COLLECTION_EVENTS,
      fetchConfig: {
        init: { type: ActionTypes.GET_DEBT_COLLECTION_EVENTS_REQUEST, caseNumber },
        path: `/api/debtcollections/${caseNumber}/events`,
        signal: abortController.signal,
        success: (json) => {
          dispatch({ type: ActionTypes.GET_DEBT_COLLECTION_EVENTS_SUCCESS, caseNumber: caseNumber, response: json });
        },
        failure: (error: string) => {
          dispatch({ type: ActionTypes.GET_DEBT_COLLECTION_EVENTS_FAILURE });
          dispatch({ type: NotificationsStoreActionTypes.ADD_NOTIFICATION, notification: { message: `Kunne ikke hente hendelser for inkassosaken ${caseNumber}`, variant: 'error' } });
        }
      }
    });
  },
  clearDebtorDebtCollections: (): AppThunkAction<KnownAction> => dispatch => {
    dispatch({ type: ActionTypes.CLEAR_DEBTOR_DEBT_COLLECTIONS });
  }
};

export const initialState: DebtorDebtCollectionsState = { debtCollections: [], isLoading: false, isLoadingEvents: false };

export const reducer: Reducer<DebtorDebtCollectionsState | undefined, KnownAction> = (state: DebtorDebtCollectionsState = initialState, action: KnownAction) => {
  switch (action.type) {
    case ActionTypes.GET_DEBTOR_DEBT_COLLECTIONS_REQUEST:
      return { ...state, isLoading: true };
    case ActionTypes.GET_DEBTOR_DEBT_COLLECTIONS_SUCCESS:
      return { ...state, debtCollections: unionWith(state.debtCollections, action.response, isEqual), isLoading: false };
    case ActionTypes.GET_DEBTOR_DEBT_COLLECTIONS_FAILURE:
      return { ...state, debtCollections: [], isLoading: false};
    case ActionTypes.GET_DEBT_COLLECTION_EVENTS_REQUEST:
      return { ...state, isLoadingEvents: true };
    case ActionTypes.GET_DEBT_COLLECTION_EVENTS_SUCCESS:
      const invoiceIndex = state.debtCollections.findIndex(x => x.number === action.caseNumber);
      return {
        ...state, debtCollections: [
          ...state.debtCollections.slice(0, invoiceIndex).concat({ ...state.debtCollections[invoiceIndex], events: action.response, eventsHaveBeenFetched: true }),
          ...state.debtCollections.slice(invoiceIndex + 1)
        ], isLoadingEvents: false
      };
    case ActionTypes.GET_DEBT_COLLECTION_EVENTS_FAILURE:
      return { ...state, isLoadingEvents: false };
    case ActionTypes.CLEAR_DEBTOR_DEBT_COLLECTIONS:
      abortController.abort();
      return { ...state, debtCollections: [] };
    default:
      return state;
  }
};