import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import TextField from '@mui/material/TextField';
import { OtherReasonConversationAction, SelectedDebtorClaim } from '../../../../api/client';
import { actionCreators as ConversationSupervisorStoreActionCreators } from '../../../../store/ConversationSupervisorStore';
import { actionCreators as DebtorClaimsStoreActionCreators } from '../../../../store/DebtorClaimsStore';
import { hasSelectedDebtorClaims } from '../../../../store/Selectors';
import { Step } from '../ConversationSupervisorStep';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import StepZilla from 'react-stepzilla';

interface PropsFromState {
  selectedDebtorClaims: SelectedDebtorClaim[];
  hasSelectedDebtorClaims: boolean;
}

interface PropsFromDispatch {
  addConversationSupervisorAction: typeof ConversationSupervisorStoreActionCreators.addConversationAction;
  clearDebtorClaimsSelection: typeof DebtorClaimsStoreActionCreators.clearDebtorClaimsSelection;
}

interface InjectedProps {
  jumpToStep?: typeof StepZilla.jumpToStep;
}

interface State {
  noteText: string;
}

type ComponentProps = PropsFromState & PropsFromDispatch & InjectedProps;

export class OtherReason extends React.Component<ComponentProps, State> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      noteText: ''
    };
  }

  isValid = () => {
    return this.state.noteText.trim() && this.props.hasSelectedDebtorClaims;
  }

  handleChange = event => {
    this.setState({ noteText: event.target.value });
  }

  handleSubmit = event => {
    event.preventDefault();
    const action: OtherReasonConversationAction = {
      selectedDebtorClaims: this.props.selectedDebtorClaims,
      text: this.state.noteText,
      discriminator: 'OtherReasonConversationAction',
    };

    this.props.addConversationSupervisorAction(action);
    this.props.clearDebtorClaimsSelection();
    this.props.jumpToStep(Step.Summary);
  }

  public render() {
    return (
      <React.Fragment>
        <Box sx={{ paddingTop: '10px' }}>
          <TextField
            label="Notat"
            autoFocus={true}
            required={true}
            error={!this.isValid()}
            multiline={true}
            maxRows={25}
            margin="normal"
            sx={{ minWidth: '100%' }}
            value={this.state.noteText}
            onChange={this.handleChange}
          />
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
          <Button variant="contained" onClick={() => this.props.jumpToStep(Step.Start)}>
            Avbryt
          </Button>
          <Button variant="contained" id={'submit'} onClick={this.handleSubmit} disabled={!this.isValid()} type="submit">
            OK
          </Button>
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ debtorClaims }: ApplicationState): PropsFromState => ({
  selectedDebtorClaims: debtorClaims.selectedDebtorClaims,
  hasSelectedDebtorClaims: hasSelectedDebtorClaims(debtorClaims),
});

const mapDispatchToProps: PropsFromDispatch = {
  addConversationSupervisorAction: ConversationSupervisorStoreActionCreators.addConversationAction,
  clearDebtorClaimsSelection: DebtorClaimsStoreActionCreators.clearDebtorClaimsSelection,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(OtherReason);
