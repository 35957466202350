import { ClaimEventType, DebtorClaimDownloadInformation } from '../api/client';
import { Reducer } from 'redux';
import { AppThunkAction } from '../store';
import { FetchConfig } from './middlewares/FetchMiddleware';

export enum ActionTypes {
  DOWNLOAD_DEBTOR_CLAIM = '@@debtorClaims/DOWNLOAD_DEBTOR_CLAIM',
  DOWNLOAD_DEBTOR_CLAIM_REQUEST = '@@debtorClaims/DOWNLOAD_DEBTOR_CLAIM_REQUEST',
  DOWNLOAD_DEBTOR_CLAIM_SUCCESS = '@@debtorClaims/DOWNLOAD_DEBTOR_CLAIM_SUCCESS',
  DOWNLOAD_DEBTOR_CLAIM_FAILURE = '@@debtorClaims/DOWNLOAD_DEBTOR_CLAIM_FAILURE'
}

interface DownloadDebtorClaimAction { type: ActionTypes.DOWNLOAD_DEBTOR_CLAIM; fetchConfig: FetchConfig; }
interface DownloadDebtorClaimRequestAction { type: ActionTypes.DOWNLOAD_DEBTOR_CLAIM_REQUEST; debtorId: string; eventId: string; eventType: ClaimEventType; }
interface DownloadDebtorClaimSuccessAction { type: ActionTypes.DOWNLOAD_DEBTOR_CLAIM_SUCCESS; debtorId: string; eventId: string; eventType: ClaimEventType; response: DebtorClaimDownloadInformation; }
interface DownloadDebtorClaimFailureAction { type: ActionTypes.DOWNLOAD_DEBTOR_CLAIM_FAILURE; debtorId: string; eventId: string; eventType: ClaimEventType; }

export type KnownAction = DownloadDebtorClaimAction
  | DownloadDebtorClaimRequestAction
  | DownloadDebtorClaimSuccessAction
  | DownloadDebtorClaimFailureAction;

export const actionCreators = {
  downloadDebtorClaimPDF: (debtorId: string, eventId: string, eventType: ClaimEventType): AppThunkAction<KnownAction> => (dispatch) => {
    return dispatch({
      type: ActionTypes.DOWNLOAD_DEBTOR_CLAIM,
      fetchConfig: {
        init: { type: ActionTypes.DOWNLOAD_DEBTOR_CLAIM_REQUEST, debtorId, eventId, eventType },
        path: `/api/invoices/debtor/${debtorId}/eventId/${eventId}/eventType/${eventType}/download`,
        signal: undefined,
        success: (response) => {
          dispatch({ type: ActionTypes.DOWNLOAD_DEBTOR_CLAIM_SUCCESS, debtorId: debtorId, eventId: eventId, eventType: eventType, response: response });
        },
        failure: (error: string) => {
          dispatch({ type: ActionTypes.DOWNLOAD_DEBTOR_CLAIM_FAILURE, debtorId: debtorId, eventId: eventId, eventType: eventType });
        }
      }
    });
  }
};

export const reducer: Reducer<any | undefined, KnownAction> = (state: any = null, action: KnownAction) => {
  switch (action.type) {
    case ActionTypes.DOWNLOAD_DEBTOR_CLAIM_REQUEST:
      return null;
    case ActionTypes.DOWNLOAD_DEBTOR_CLAIM_SUCCESS:
      const downloadElement = document.createElement('a');
      downloadElement.setAttribute('href', action.response.path!);
      downloadElement.setAttribute('style', 'display: none');
      document.body.appendChild(downloadElement);
      downloadElement.click();
      downloadElement.parentElement!.removeChild(downloadElement);
      return null;
    case ActionTypes.DOWNLOAD_DEBTOR_CLAIM_FAILURE:
      return null;
    default:
      return null;
  }
};