import { Reducer } from 'redux';

export enum ActionTypes {
  UPDATE_SERVICEWORKER = '@@index/UPDATE_SERVICEWORKER'
}

export interface ApplicationVersionState {
  newVersionAvailable: boolean;
}

interface UpdateServiceWorkerAction { type: ActionTypes.UPDATE_SERVICEWORKER; }

export type KnownAction = UpdateServiceWorkerAction;

export const actionCreators = {
  updateServiceWorker: () => {
    return {
      type: ActionTypes.UPDATE_SERVICEWORKER,
    };
  }
};

export const initialState: ApplicationVersionState = { newVersionAvailable: false };

export const reducer: Reducer<ApplicationVersionState | undefined, KnownAction> = (state: ApplicationVersionState = initialState, action: KnownAction) => {
  switch (action.type) {
    case ActionTypes.UPDATE_SERVICEWORKER:
      return { ...state, newVersionAvailable: true };
    default:
      return state;
  }
};