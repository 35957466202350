import React from 'react';
import { ApplicationState } from './store';
import { connect } from 'react-redux';
import { create } from 'jss';
import ApplicationBar from './components/applicationBar/ApplicationBar';
import EnvironmentBar from './components/environment/EnvironmentBar';
import * as NotificationsStore from './store/NotificationsStore';
import NotificationsSnackbar from './components/notifications/NotificationsSnackbar';
import JssProvider from 'react-jss/lib/JssProvider';
import preset from 'jss-preset-default';
import NewVersionAvailableBar from './components/applicationVersion/NewVersionAvailableBar';
import { getStartingPageForLoginWithRoles } from './components/Utils';

interface PropsFromState {
  isAuthenticated: boolean;
  userProfile?: string;
  roles: string[];
  notifications: NotificationsStore.Notification[];
  newApplicationVersionIsAvailable: boolean;
}

type ComponentProps = PropsFromState & { children?: React.ReactNode };

class LayoutComponent extends React.Component<ComponentProps> {
  render() {
    const jss = create(preset());
    return (
      <JssProvider jss={jss}>
        <React.Fragment>
          <ApplicationBar userProfile={this.props.userProfile} defaultPath={getStartingPageForLoginWithRoles(this.props.roles)} />
          <React.Fragment>
            <EnvironmentBar />
            {this.props.newApplicationVersionIsAvailable === true ? <NewVersionAvailableBar /> : null}
            {this.props.children}
          </React.Fragment>
          <NotificationsSnackbar />
        </React.Fragment>
      </JssProvider>
    );
  }
}

const mapStateToProps = ({ authentication, notifications, applicationVersion }: ApplicationState): PropsFromState => ({
  isAuthenticated: authentication.isAuthenticated,
  userProfile: authentication.userProfile,
  roles: authentication.roles,
  notifications: notifications.messages,
  newApplicationVersionIsAvailable: applicationVersion.newVersionAvailable
});

export default connect(mapStateToProps)(LayoutComponent);
