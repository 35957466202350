import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { actionCreators as DebtorSearchStoreActionCreators } from '../../../store/DebtorSearchStore';
import { colors } from '../../../colors';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';


interface PropsFromDispatch {
  searchDebtors: typeof DebtorSearchStoreActionCreators.searchDebtors;
}

interface State {
  criteria: string;
}

type ComponentProps = PropsFromDispatch & RouteComponentProps & State;

export class DebtorSearchDialog extends React.Component<ComponentProps, State> {
  constructor(props: any) {
    super(props);
    this.state = { criteria: '' };
  }

  componentDidMount() {
    const searchCriteria = this.parseSearchCriteriaFromQueryString();
    if (searchCriteria !== null) {
      this.setState({ criteria: searchCriteria }, () => this.props.searchDebtors(this.state.criteria));
    }
  }

  parseSearchCriteriaFromQueryString(): string | null {
    if (this.props.location.search !== undefined) {
      const parsedQueryString = queryString.parse(window.location!.search.toLowerCase());
      if (parsedQueryString.searchcriteria !== null && parsedQueryString.searchcriteria !== undefined) {
        if (typeof (parsedQueryString.searchcriteria) === 'string') {
          return parsedQueryString.searchcriteria;
        }
        else {
          return parsedQueryString.searchcriteria.join(' ');
        }
      }
    }
    return null;
  }

  handleCriteriaChange = event => {
    this.setState({ criteria: event.target.value });
  }

  handleSubmit = event => {
    event.preventDefault();
    this.props.searchDebtors(this.state.criteria);
  }

  public render() {
    return (
      <Paper sx={{
        padding: '0px',
        margin: '20px auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        height: '88px',
        width: '780px',
        backgroundColor: colors.lightGray,
      }}>
        <form onSubmit={this.handleSubmit} >
          <TextField
            autoFocus={true}
            variant="standard"
            sx={{
              width: '700px',
              margin: 'auto'
            }}
            placeholder="Søk etter fødselsnr, fakturanr, saksnr, navn, tlf, kundenr, ordrenr eller KID (alt + s for fokus) "
            type="search"
            value={this.state.criteria}
            onChange={this.handleCriteriaChange}
            inputProps={{ accessKey: 's', 'data-id': 'searchCriteria' }}
          />
          <IconButton aria-label="Delete" type="submit" data-id="searchSubmit">
            <Search />
          </IconButton>
        </form>
      </Paper>
    );
  }
}

const mapDispatchToProps: PropsFromDispatch = {
  searchDebtors: DebtorSearchStoreActionCreators.searchDebtors,
};

export default compose(
  connect(null, mapDispatchToProps),
)(withRouter(DebtorSearchDialog));
