import { isEqual } from 'lodash/lang';
import { unionWith } from 'lodash/array';
import { Reducer } from 'redux';
import { AppThunkAction } from '../store';
import { DebtorLogItem, DebtorLogItemsResponse } from '../api/client';
import { ActionTypes as NotificationsStoreActionTypes, AddNotificationAction } from './NotificationsStore';
import { FetchConfig } from './middlewares/FetchMiddleware';

export enum ActionTypes {
  GET_DEBTOR_LOG_ITEMS = '@@debtorLogItems/GET_DEBTOR_LOG_ITEMS',
  GET_DEBTOR_LOG_ITEMS_REQUEST = '@@debtorLogItems/GET_DEBTOR_LOG_ITEMS_REQUEST',
  GET_DEBTOR_LOG_ITEMS_SUCCESS = '@@debtorLogItems/GET_DEBTOR_LOG_ITEMS_SUCCESS',
  GET_DEBTOR_LOG_ITEMS_FAILURE = '@@debtorLogItems/GET_DEBTOR_LOG_ITEMS_FAILURE',
  CLEAR_DEBTOR_LOG_ITEMS = '@@debtorLogItems/CLEAR_DEBTOR_LOG_ITEMS'
}

export interface DebtorLogItemsState {
  logItems: DebtorLogItem[];
  isLoading: boolean;
}

interface GetDebtorLogItemsAction { type: ActionTypes.GET_DEBTOR_LOG_ITEMS; fetchConfig: FetchConfig; }
interface GetDebtorLogItemsRequestAction { type: ActionTypes.GET_DEBTOR_LOG_ITEMS_REQUEST; id: string; }
interface GetDebtorLogItemsSuccessAction { type: ActionTypes.GET_DEBTOR_LOG_ITEMS_SUCCESS; response: DebtorLogItem[]; }
interface GetDebtorLogItemsFailureAction { type: ActionTypes.GET_DEBTOR_LOG_ITEMS_FAILURE; }
interface ClearDebtorLogItemsAction { type: ActionTypes.CLEAR_DEBTOR_LOG_ITEMS; }

export type KnownAction = GetDebtorLogItemsAction
  | GetDebtorLogItemsRequestAction
  | GetDebtorLogItemsSuccessAction
  | GetDebtorLogItemsFailureAction
  | ClearDebtorLogItemsAction
  | AddNotificationAction;

let abortController = new AbortController();

export const actionCreators = {
  getDebtorLogItems: (ids: string[]): AppThunkAction<KnownAction> => dispatch => {
    abortController = new AbortController();
    ids.forEach(id => {
      return dispatch({
        type: ActionTypes.GET_DEBTOR_LOG_ITEMS,
        fetchConfig: {
          init: { type: ActionTypes.GET_DEBTOR_LOG_ITEMS_REQUEST, id },
          path: `/api/Debtors/${id}/logitems`,
          signal: abortController.signal,
          success: (json : DebtorLogItemsResponse) => {
            dispatch({ type: ActionTypes.GET_DEBTOR_LOG_ITEMS_SUCCESS, response: json.logItems! });
            if (json.debtCollectionGetFailed){
              dispatch({ type: NotificationsStoreActionTypes.ADD_NOTIFICATION, notification: { message: 'Kunne ikke hente debitor sin logg for inkassosaker', variant: 'error' } });
            }
          },
          failure: (error: string) => {
            dispatch({ type: ActionTypes.GET_DEBTOR_LOG_ITEMS_FAILURE });
            if (error !== "AbortError") {
              dispatch({ type: NotificationsStoreActionTypes.ADD_NOTIFICATION, notification: { message: 'Kunne ikke hente debitor sin logg', variant: 'error' } });
            }         
          }
        }
      });
    });
  },
  clearDebtorLogItems: (): AppThunkAction<KnownAction> => dispatch => {
    dispatch({ type: ActionTypes.CLEAR_DEBTOR_LOG_ITEMS });
  }
};

export const initialState: DebtorLogItemsState = { logItems: [], isLoading: false };

export const reducer: Reducer<DebtorLogItemsState | undefined, KnownAction> = (state: DebtorLogItemsState = initialState, action: KnownAction) => {
  switch (action.type) {
    case ActionTypes.GET_DEBTOR_LOG_ITEMS_REQUEST:
      return { ...state, isLoading: true };
    case ActionTypes.GET_DEBTOR_LOG_ITEMS_SUCCESS:
      return { ...state, logItems: unionWith(state.logItems, action.response, isEqual), isLoading: false };
    case ActionTypes.GET_DEBTOR_LOG_ITEMS_FAILURE:
      return { ...state, isLoading: false };
    case ActionTypes.CLEAR_DEBTOR_LOG_ITEMS:
      if (state.isLoading) {
        abortController.abort();
      }
      return { ...state, logItems: [] };
    default:
      return state;
  }
};