import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Font, Image } from '@react-pdf/renderer';
import { CircularProgress } from '@mui/material';
import { useAppSelector } from '../../hooks';
import { getActiveDebtCollections, getActiveInvoices, getActiveLeapClaims } from '../../store/Selectors';
import { DebtCollection, DebtCollectionCaseType, DebtorClaim, DebtorClaimStage, Invoice, LeapClaim, ProvidedService, DebtCollectionCompany } from '../../api/client';
import { CrediCareLogo } from './CrediCareLogo';
import { formatDate } from '../Utils';
import { colors } from '../../colors';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Description';

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVc.ttf' },
    { src: 'https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1x4gaVc.ttf', fontWeight: 700 },
  ],
});

const spacing = 10;
const headerColor = '#163556';
const mainFontSize = 9;

const pdfStyles = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 5,
    paddingBottom: 30,
  },
  logo: {
    width: '25%',
    alignSelf: 'flex-end',
  },
  documentDate: {
    position: 'absolute',
    top: 35,
    fontSize: mainFontSize,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
    right: 5,
  },
  page: {
    fontFamily: 'Open Sans',
    padding: 30,
    display: 'flex',
    flexDirection: "column",
  },
  claim: {
    marginLeft: spacing,
    marginRight: spacing,
  },
  claimDivider: {
    marginTop: 15,
    backgroundColor: '#E4F3F3',
    left: 'auto',
    right: 'auto',
    borderRadius: '50%',
    height: 6,
  },
  pageNumbers: {
    marginLeft: 'auto',
    marginRight: spacing,
    fontSize: mainFontSize,
    position: 'absolute',
    bottom: 0,
    padding: 10,
    right: 0,
  },
    
  h1: {
    fontSize: 24,
    fontWeight: 700,
    color: headerColor,
  },
  h2: {
    marginTop: 5,
    marginBottom: 3,
    fontSize: 18,
    fontWeight: 700,
    color: headerColor,
  },
  text: {
    fontSize: mainFontSize,
  },
  paymentInfoText: {
    fontSize: 13,
  },
  invoiceNo: {
    fontSize: mainFontSize,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
  },
  details: {
    marginTop: spacing,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: '10px 10px 0 10px',
    border: '1px solid #00b1b1',
  },
  externalDebtCollectionInfo: {
    marginTop: spacing,
    padding: '10px 10px 10px 10px',
    border: '1px solid',
    backgroundColor: colors.secondary.lemon + '80',
  },
  externalDebtCollectionInfoHeader: {
    fontSize: 12,
    paddingBottom: 4
  },
  detailsItem: {
    marginBottom: 10,
  },
  detailsItemText: {
    fontSize: 9,
  },
  padLeft: {
    paddingLeft: 40,
  },
  bold: {
    fontWeight: 'bold',
  },
  inline: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  padTop: {
    paddingTop: 5,
  },
  downloadButton: {
    margin: 0,
    fontSize: 14
  },
  downloadIcon: {
    height: 20,
    marginLeft: -5,
    marginRight: 8
  }
});

const getClaimStageFriendlyName = (claimStage?: DebtorClaimStage) => {
  switch (claimStage) {
    case DebtorClaimStage.DebtCollection:
      return 'Inkassosak';
    case DebtorClaimStage.SentToDebtCollection:
    case DebtorClaimStage.DebtCollectionWarning:
      return 'Inkassovarsel';
    case DebtorClaimStage.Invoice:
      return 'Faktura';
    case DebtorClaimStage.Reminder:
      return 'Purring';
    case DebtorClaimStage.Undefined:
    case undefined:
      return 'udefinert';
    default:
      const stage: never = claimStage;
      console.warn('Unknown claim stage: ', stage);
  }
}

const toTitleCase = (str: string | undefined) =>  (str ?? '')
  .toLowerCase()
  .split(' ')
  .map(word => (word.charAt(0).toUpperCase() + word.slice(1)).replace(/-./g, x => x.toUpperCase()))
  .join(' ');

const Header = () => (
  <View fixed style={pdfStyles.header}>
    <Text style={[pdfStyles.text, pdfStyles.h1]} render={({ pageNumber }) => pageNumber === 1 && 'Oversikt over utestående'} />
    <CrediCareLogo style={pdfStyles.logo} />
    <div style={pdfStyles.documentDate}>
      <Text style={pdfStyles.bold} render={({ pageNumber }) => pageNumber === 1 && 'Dato: '} />
      <Text render={({ pageNumber }) => pageNumber === 1 && formatDate(new Date())} />
    </div>
  </View>
);

const PageNumber = () => (
  <View fixed style={pdfStyles.pageNumbers}>
    <Text
      render={({ pageNumber, totalPages }) =>
        `Side ${pageNumber} / ${totalPages}`
      }
    />
  </View>
);

const RowItem = ({ title, value, width }: { title: string; value: string | number | undefined; width: number }) => (
  <div style={{ ...{ width } }}>
    <div>
      <Text style={[pdfStyles.paymentInfoText, pdfStyles.bold, ]}>{title}</Text>
    </div>
    <div>
      <Text style={[pdfStyles.paymentInfoText]}>{value}</Text>
    </div>
  </div>
);

const numberFormat = new Intl.NumberFormat('nb', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const formatValueForDisplay = (value:string | number | undefined, unit: 'kr') => {
  if (typeof value === 'number') {
    return `${numberFormat.format(value)} ${unit}`;
  }
  return value;
}

const DetailsItem = ({ title, value, width }: { title: string; value: string | number | undefined; width: number }) => (
  <div style={{...pdfStyles.detailsItem, ...{ width }}}>
    <div>
      <Text style={[pdfStyles.text, pdfStyles.bold, pdfStyles.detailsItemText]}>{title}</Text>
    </div>
    <div>
      <Text style={[pdfStyles.text, pdfStyles.detailsItemText]}>{value}</Text>
    </div>
  </div>
);

const InlineItem= ({ title, value, titleStyle }: { title: string | undefined; value: string | number | undefined, titleStyle?: keyof Pick<typeof pdfStyles, 'h2' | 'bold'> }) => (
  <div style={pdfStyles.inline}>
      <Text style={[pdfStyles.text, titleStyle ? pdfStyles[titleStyle] : {} ]}>{title} </Text>
      <Text style={[pdfStyles.text, titleStyle === 'h2' ? { marginBottom: -4 } : {}]}>{value}</Text>
  </div>
);

const Description = ({text}: {text: string}) => (
  <Text style={[pdfStyles.text, pdfStyles.padTop]}>{text}</Text>
);

const ClaimSection = ({ claim }: { claim: LeapClaim | Invoice }) => {
  const debtCollectionStage = getClaimStageFriendlyName(claim.claimStage);
  const isDebtCollectionWarning = debtCollectionStage === 'Inkassovarsel';
  const isTransferredToExternalDebtCollection = claim.transferredToExternalCollection;
  return (
    <View style={{ ...pdfStyles.claim }} wrap={false}>
      <Text style={[pdfStyles.text, pdfStyles.h2]}>{debtCollectionStage}</Text>
      <InlineItem title='Fakturanr:' value={claim?.number} titleStyle='bold' />
      { !isTransferredToExternalDebtCollection &&
        <div>
          <InlineItem title='Fakturagebyr:' value={formatValueForDisplay(claim?.invoiceFeeAmount ?? 0, 'kr')} titleStyle='bold' />
          { isDebtCollectionWarning && <InlineItem title='Varselsgebyr:' value={formatValueForDisplay(claim?.extrajudicialCostAmount ?? 0, 'kr')} titleStyle='bold' /> } 
          { claim.providedServices?.length && <Description text={`Kravet gjelder tjenester mottatt hos ${toTitleCase(claim.providedServices[0].serviceProvider)} den ${formatDate(claim.providedServices[0].providedAt)} ved ${claim.providedServices[0].location}.`} /> }
        <div>
          <div style={pdfStyles.row}>
            <RowItem width={115} title='Kontonummer' value={claim.debtorPaymentAccount} />
            <RowItem width={170} title='KID' value={claim.kid} />
            <RowItem width={110} title='Forfallsdato' value={formatDate(claim.dueDate)} />
            <RowItem width={110} title='Utestående' value={formatValueForDisplay(claim.amount ?? 0, 'kr')} />
          </div>
        </div>
      </div>
      }
      { isTransferredToExternalDebtCollection &&
        <div>
          {claim.providedServices?.length && <Description text={`Kravet gjelder tjenester mottatt hos ${toTitleCase(claim.providedServices[0].serviceProvider)} den ${formatDate(claim.providedServices[0].providedAt)} ved ${claim.providedServices[0].location}.`} />}
          <ExternalDebtCollectionInfo debtCollectionCompany={claim.externalDebtCollectionCompany!} />
        </div>
      }
      <ClaimDivider />
    </View>
  );
};

const ClaimDivider = () => <div style={pdfStyles.claimDivider}></div>

const ProvidedServiceRow = ({ ps }: { ps: ProvidedService }) => (<View break>
  <Text style={[pdfStyles.text, { textDecoration: 'underline' }]}>{`Faktura ${ps.externalReferenceId}`}</Text>
   <Text style={[pdfStyles.text, { fontSize: mainFontSize }]}>{`Kravet gjelder tjenester mottatt hos ${toTitleCase(ps.serviceProvider)} den ${formatDate(ps.providedAt)} ved ${ps.location}.`}</Text>
</View>);

const DebtCollectionSection = ({ debtCollection }: { debtCollection: DebtCollection | LeapClaim; }) => {
  const debtCollectionStage = debtCollection.caseType === DebtCollectionCaseType.Judicial ? 'Rettslig inkassosak' : getClaimStageFriendlyName(debtCollection.claimStage);
  const isDebtCollectionWarning = debtCollection.claimStage === DebtorClaimStage.DebtCollectionWarning;
  const isTransferredToExternalDebtCollection = debtCollection.transferredToExternalCollection;
  const leapClaim: LeapClaim = debtCollection as LeapClaim;
  return (
    <View style={{ ...pdfStyles.claim }} wrap={true}>
      {!isTransferredToExternalDebtCollection &&
        <div>
          <View wrap={false}>
          <Text style={[pdfStyles.text, pdfStyles.h2, pdfStyles.padTop]}>{debtCollectionStage}</Text>
          <InlineItem title='Saksnr:' value={debtCollection?.number} titleStyle='bold' />
            isDebtCollectionWarning && <InlineItem title='Fakturagebyr:' value={formatValueForDisplay(leapClaim.invoiceFeeAmount ?? 0, 'kr')} titleStyle='bold' />
            { isDebtCollectionWarning && <InlineItem title='Varselsgebyr:' value={formatValueForDisplay(leapClaim.extrajudicialCostAmount ?? 0, 'kr')} titleStyle='bold' /> }
            { debtCollection.isCombinedDebtCollectionCase && <Text style={pdfStyles.text}>Saken inneholder flere inkassosaker</Text> }
            <div style={pdfStyles.row}>
              <RowItem width={115} title='Kontonummer' value={debtCollection.debtorPaymentAccount} />
              <RowItem width={170} title='KID' value={debtCollection.kid} />
              { isDebtCollectionWarning && <RowItem width={110} title='Forfallsdato' value={formatDate(leapClaim.dueDate)} /> }
              { !isDebtCollectionWarning && <RowItem width={110} title='' value='' /> }
              <RowItem width={110} title='Utestående' value={formatValueForDisplay(debtCollection.amount ?? 0, 'kr')} />
            </div>
            <div style={pdfStyles.details}>
              <DetailsItem width={80} title='Opprinnelig beløp' value={formatValueForDisplay(debtCollection.principalAmount, 'kr') ?? 0} />
              <DetailsItem width={105} title='Utestående hovedkrav' value={formatValueForDisplay(debtCollection.principalBalanceAmount, 'kr') ?? 0} />
              <DetailsItem width={95} title='Innbetalt hovedkrav' value={formatValueForDisplay(debtCollection.paidPrincipalAmount, 'kr') ?? 0} />
              <DetailsItem width={55} title='Renter' value={formatValueForDisplay(debtCollection.interestAmount, 'kr') ?? 0} />
              <DetailsItem width={80} title='' value='' />

              <DetailsItem width={80} title='Omkostninger' value={formatValueForDisplay(debtCollection.extrajudicialCostAmount, 'kr') ?? 0} />
              <DetailsItem width={105} title='Omkostningsrenter' value={formatValueForDisplay(debtCollection.costInterestAmount, 'kr') ?? 0} />
              <DetailsItem width={95} title='Skrivesalær' value={formatValueForDisplay(debtCollection.writingFeesAmount, 'kr') ?? 0} />
              <DetailsItem width={55} title='Rettsgebyr' value={formatValueForDisplay(debtCollection.legalFeesAmount, 'kr') ?? 0} />
              <DetailsItem width={80} title='Totalt innbetalt' value={formatValueForDisplay(debtCollection.paidAmount, 'kr') ?? 0} />
            </div>
          </View>

          {debtCollection.providedServices?.map((ps, i) => {
            return <View style={pdfStyles.padTop} key={ps.externalReferenceId} break={false}>
              {
                i === 0 && <View break>
                  <Text style={[pdfStyles.text, pdfStyles.bold, { marginTop: 5, marginBottom: 5 }]}>Kravspesifikasjon</Text>
                  <ProvidedServiceRow ps={ps} />
                </View>
              }
              {
                i > 0 && <ProvidedServiceRow ps={ps} />
              }
            </View>
          })}
        </div>}
      {isTransferredToExternalDebtCollection && <ExternalDebtCollectionInfo debtCollectionCompany={debtCollection.externalDebtCollectionCompany!}/> }
      <ClaimDivider />
    </View>
  );
};

const ExternalDebtCollectionInfo = ({ debtCollectionCompany }: { debtCollectionCompany: DebtCollectionCompany; }) => {
  return (
    <View wrap={false} style={pdfStyles.externalDebtCollectionInfo}>
      <Text style={[pdfStyles.externalDebtCollectionInfoHeader, pdfStyles.bold,]}>Saken er overført til innkreving hos {debtCollectionCompany.name}</Text>
      {debtCollectionCompany.displayPhone && <InlineItem title='Telefon:' value={debtCollectionCompany.displayPhone} titleStyle='bold' />}
      {debtCollectionCompany.displayEmail && <InlineItem title='Epost:' value={debtCollectionCompany.displayEmail} titleStyle='bold' />}
      {debtCollectionCompany.webpage && <InlineItem title='Nettside:' value={debtCollectionCompany.webpage} titleStyle='bold' />}
    </View>
  );
};

const DebtOverviewPDFDocument = ({ claims, debtCollections }: { claims: (LeapClaim | Invoice)[], debtCollections: (DebtCollection)[] }) => (
  <Document>
    <Page size="A4" wrap style={pdfStyles.page}>
        <Header />
        {claims.map((claim) => (
          <ClaimSection claim={claim} key={claim.number} />
        ))}
        {debtCollections.map(debtCollection => (
          <DebtCollectionSection debtCollection={debtCollection} key={debtCollection.number} />
        ))} 
        <div style={{ width: '96.5%', height: '200', marginTop: -10, marginLeft: 10, }}>
          <Image src='/images/debtoverview/footer.png'></Image>
        </div>
      <PageNumber />
    </Page>
  </Document>
);

function sortClaims<T extends DebtorClaim>(a: T, b: T) {
  return +b.date - +a.date;
}

const DebtOverview = () => {
  const invoices = useAppSelector(getActiveInvoices);
  const leapClaims = useAppSelector(getActiveLeapClaims);
  const debtCollections = [...useAppSelector(getActiveDebtCollections), ...leapClaims.filter(c => (c.claimStage === DebtorClaimStage.DebtCollection || c.claimStage === DebtorClaimStage.DebtCollectionWarning) && c.displayOnDebtOverview)].sort(sortClaims);
  const combinedInvoicesAndClaims = [...invoices, ...leapClaims.filter(c => c.claimStage !== DebtorClaimStage.DebtCollection && c.claimStage !== DebtorClaimStage.DebtCollectionWarning)].filter(c => c.displayOnDebtOverview).sort(sortClaims);

  return (
    <div>
      <PDFDownloadLink document={<DebtOverviewPDFDocument claims={combinedInvoicesAndClaims} debtCollections={debtCollections} />} fileName="totaloversikt.pdf">
        {({ loading }) => loading ? <CircularProgress /> : <Button variant="contained" style={pdfStyles.downloadButton}><DownloadIcon style={pdfStyles.downloadIcon} /> Last ned totaloversikt</Button>}
      </PDFDownloadLink>
    </div>
  );
};

export {
    DebtOverview
};
