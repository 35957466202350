import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function DebtorLogHeader() {
  return (
    <Box sx={{
      height: '56px',
      display: 'flex',
      alignItems: 'center',
    }}>
      <Typography sx={{
        fontSize: '18px',
        fontWeight: 600,
        marginLeft: '8px'
      }}>
        Logg
      </Typography>
    </Box>
  )
};
