import React from 'react';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { actionCreators } from '../../../store/ConversationSupervisorStore';
import DeleteIcon from '@mui/icons-material/Delete';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';

export interface Props {
  interruptionReason?: string;
  cancelConversationInterruption: typeof actionCreators.cancelConversationInterruption;
}

export const handleDelete = (props: Props) => {
  props.cancelConversationInterruption();
};

export default function ConversationInterruptedSummaryItem(props: Props) {
  return (
    <ListItem>
      <ListItemIcon
        sx={{
          alignSelf: 'start',
          paddingTop: '10px',
          minWidth: '26px'
        }}>
        <PhoneDisabledIcon sx={{ fontSize: '14px' }} />
      </ListItemIcon>
      <ListItemText
        primary="Samtale avbrutt"
        secondary={props.interruptionReason}
        primaryTypographyProps={{
          fontSize: 16,
          fontWeight: '600',
          letterSpacing: 0,
        }}
        secondaryTypographyProps={{
          fontSize: 14,
          fontWeight: '400',
          letterSpacing: 0,
          maxHeight: '60px',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      />
      <ListItemSecondaryAction>
        <IconButton
          aria-label="Slett"
          onClick={() => handleDelete(props)}
          sx={{
            alignSelf: 'end',
            padding: 0
          }}>
          <DeleteIcon sx={{ fontSize: '30px' }} />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
