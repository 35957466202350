import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

function reload() {
  window.location.reload();
}

export default function NewVersionAvailableBar() {
  return (
    <AppBar position="sticky" sx={{
      backgroundImage: "linear-gradient(120deg, 'primary.main', 'primary.dark')",
      marginTop: '10px',
      display: 'block',
      minHeight: '40px'
    }}>
      <Toolbar variant="dense" sx={{
        justifyContent: 'center',
        cursor: 'pointer'
      }} onClick={() => reload()} >
        <Typography variant="h6" color="inherit">
          Jippi. Ny versjon er tilgjengelig. Trykk her eller F5 for å oppdatere!
          </Typography>
      </Toolbar>
    </AppBar>
  );
};
