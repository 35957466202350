import React from 'react';
import { Step } from './ConversationSupervisorStep';
import ArrowRight from '@mui/icons-material/ArrowRight';
import NoteIcon from '@mui/icons-material/CreateOutlined';
import SummaryIcon from '@mui/icons-material/Notes';
import ObjectionIcon from '@mui/icons-material/PauseCircleOutline';
import CreditIcon from '@mui/icons-material/MoneyOff';
import InterruptedIcon from '@mui/icons-material/CallEndOutlined';
import PaidIcon from '@mui/icons-material/ReceiptOutlined';
import PaymentIcon from '@mui/icons-material/CreditCard';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import StepZilla from 'react-stepzilla';
import ListItemIcon from '@mui/material/ListItemIcon';

interface Props {
  hasConversationSupervisorActions: boolean;
  jumpToStep?: typeof StepZilla.jumpToStep;
}

export default function ConversationStart(props: Props) {
  return (
    <List>
      <ListItem sx={{
        paddingRight: 0,
      }} button={true} onClick={() => props.jumpToStep(Step.HasPaid)}>
        <ListItemIcon>
          <PaidIcon />
        </ListItemIcon>
        <ListItemText primary="Har betalt" />
        <ArrowRight />
      </ListItem>
      <ListItem sx={{
        paddingRight: 0,
      }} button={true} onClick={() => props.jumpToStep(Step.PaymentAgreement)}>
        <ListItemIcon>
          <PaymentIcon />
        </ListItemIcon>
        <ListItemText primary="Skal betale" />
        <ArrowRight />
      </ListItem>
      <ListItem sx={{
        paddingRight: 0,
      }} button={true} onClick={() => props.jumpToStep(Step.Objection)}>
        <ListItemIcon>
          <ObjectionIcon />
        </ListItemIcon>
        <ListItemText primary="Har innsigelse" />
        <ArrowRight />
      </ListItem>
      <ListItem sx={{
        paddingRight: 0,
      }} button={true} onClick={() => props.jumpToStep(Step.Credit)}>
        <ListItemIcon>
          <CreditIcon />
        </ListItemIcon>
        <ListItemText primary="Vil kreditere" />
        <ArrowRight />
      </ListItem>
      <ListItem sx={{
        paddingRight: 0,
      }} button={true} onClick={() => props.jumpToStep(Step.OtherReason)}>
        <ListItemIcon>
          <NoteIcon />
        </ListItemIcon>
        <ListItemText primary="Notat" />
        <ArrowRight />
      </ListItem>
      <ListItem sx={{
        paddingRight: 0,
      }} button={true} onClick={() => props.jumpToStep(Step.ConversationInterrupted)}>
        <ListItemIcon>
          <InterruptedIcon />
        </ListItemIcon>
        <ListItemText primary="Samtalen avbrutt" />
        <ArrowRight />
      </ListItem>
      <ListItem sx={{
        paddingRight: 0,
      }} button={true} onClick={() => props.jumpToStep(Step.Summary)} disabled={!props.hasConversationSupervisorActions}>
        <ListItemIcon>
          <SummaryIcon />
        </ListItemIcon>
        <ListItemText primary="Oppsummering" />
        <ArrowRight />
      </ListItem>
    </List>
  )
};
