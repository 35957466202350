import React from 'react';
import Typography from '@mui/material/Typography';

interface Props {
  accountNumber?: string;
}

function formatAccountNumber(value: string | undefined): string | undefined {
  if (value === undefined || value === '') {
    return value;
  }

  return `${value.slice(0, 4)}.${value.slice(4, 6)}.${value.slice(6)}`;
}

export default function AccountNumber(props: Props) {
  return (
    <Typography sx={{
      paddingRight: '20px',
    }}>
      Kontonummer: {formatAccountNumber(props.accountNumber)}
    </Typography>
  )
};
