import { AnyAction, Middleware, MiddlewareAPI, Dispatch } from 'redux';

const loggerMiddleware: Middleware = <S = any, D extends Dispatch = Dispatch>(store: MiddlewareAPI<D, S>) => (next: Dispatch<AnyAction>) => action => {
  if (process.env.NODE_ENV !== 'development') {
    return next(action);
  }

  console.group(action.type);
  console.log('dispatching', action);
  const result = next(action);
  console.log('next state', store.getState());
  console.groupEnd();

  return result;
};

export default loggerMiddleware;