import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { SelectedDebtorClaim, SendPaymentInformationConversationAction } from '../../../../api/client';
import { actionCreators as ConversationSupervisorStoreActionCreators } from '../../../../store/ConversationSupervisorStore';
import { actionCreators as DebtorClaimsStoreActionCreators } from '../../../../store/DebtorClaimsStore';
import { hasSelectedDebtorClaims } from '../../../../store/Selectors';
import { Step } from '../ConversationSupervisorStep';
import StepZilla from 'react-stepzilla';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { MuiChipsInput } from 'mui-chips-input';
import { parsePhoneNumber } from 'awesome-phonenumber';

interface PropsFromState {
  selectedDebtorClaims: SelectedDebtorClaim[];
  hasSelectedDebtorClaims: boolean;
}

interface PropsFromDispatch {
  addConversationSupervisorAction: typeof ConversationSupervisorStoreActionCreators.addConversationAction;
  clearDebtorClaimsSelection: typeof DebtorClaimsStoreActionCreators.clearDebtorClaimsSelection;
}

interface InjectedProps {
  jumpToStep?: typeof StepZilla.jumpToStep;
}

interface State {
  mobileNumbers: string[];
  emailAddresses: string[];
  additionalText: string;
}

type ComponentProps = PropsFromState & PropsFromDispatch & InjectedProps;

export class SendPaymentInformation extends React.Component<ComponentProps, State> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      mobileNumbers: [],
      emailAddresses: [],
      additionalText: ''
    };
  }

  isValidMobileNumbers = () => {
    return this.state.mobileNumbers.length > 0;
  }

  isValidEmailAddresses = () => {
    return this.state.emailAddresses.length > 0;
  }

  isValid = () => {
    return (this.isValidMobileNumbers() || this.isValidEmailAddresses()) && this.props.hasSelectedDebtorClaims;
  }

  handleChangeOnFreeText = event => {
    this.setState({ additionalText: event.target.value });
  }

  handleOnMobileNumberAdded = (value: string) => {
    let mobileNumber = '';
    if (value.startsWith('+')) {
      mobileNumber = value;
    }
    else {
      mobileNumber = parsePhoneNumber(value, { regionCode: 'NO' }).number?.international ?? '';
    }
    this.setState({
      mobileNumbers: [...this.state.mobileNumbers, mobileNumber]
    });
  }

  validateMobileNumber = (value : string) : boolean => {
    if (value.startsWith('+')) {
      return true
    }
    const phoneNumber = parsePhoneNumber(value, { regionCode: 'NO' });
    return phoneNumber.valid && phoneNumber.typeIsMobile;
  }

  handleOnMobileNumberDeleted = (value: string) => {
    this.setState({
      mobileNumbers: this.state.mobileNumbers.filter((c) => c !== value)
    });
  }

  handleOnEmailAddressAdded = (value: string) => {
    this.setState({
      emailAddresses: [...this.state.emailAddresses, value]
    });
  }

  handleOnEmailAddressDeleted = (value: string) => {
    this.setState({
      emailAddresses: this.state.emailAddresses.filter((c) => c !== value)
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    const action: SendPaymentInformationConversationAction = {
      selectedDebtorClaims: this.props.selectedDebtorClaims,
      mobilePhoneNumbers: this.state.mobileNumbers,
      emailAddresses: this.state.emailAddresses,
      additionalText: this.state.additionalText,
      discriminator: 'SendPaymentInformationConversationAction',
    };

    this.props.addConversationSupervisorAction(action);
    this.props.clearDebtorClaimsSelection();
    this.props.jumpToStep(Step.Summary);
  }

  public render() {
    return (
      <React.Fragment>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '10px'
        }}>
          <Typography>
            Send betalingsinformasjon
          </Typography>

          <MuiChipsInput
            disableEdition={true}
            label="Mobilnummer"
            value={this.state.mobileNumbers}
            onAddChip={this.handleOnMobileNumberAdded}
            onDeleteChip={this.handleOnMobileNumberDeleted}
            sx={{ marginTop:'10px' }}
            validate={this.validateMobileNumber}
          />

           <MuiChipsInput
              disableEdition={true}
              label="E-postadresser"
              value={this.state.emailAddresses}
              onAddChip={this.handleOnEmailAddressAdded}
              onDeleteChip={this.handleOnEmailAddressDeleted}
              sx={{ marginTop:'10px' }}
            />

          <TextField
            label="Tilleggsinformasjon til e-post"
            margin="normal"
            multiline={true}
            maxRows={10}
            value={this.state.additionalText}
            onChange={this.handleChangeOnFreeText}
          />
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
          <Button variant="contained" onClick={() => this.props.jumpToStep(Step.Start)}>
            Avbryt
          </Button>
          <Button variant="contained" id={'submit'} onClick={this.handleSubmit} disabled={!this.isValid()} type="submit">
            OK
          </Button>
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ debtorClaims }: ApplicationState): PropsFromState => ({
  selectedDebtorClaims: debtorClaims.selectedDebtorClaims,
  hasSelectedDebtorClaims: hasSelectedDebtorClaims(debtorClaims),
});

const mapDispatchToProps: PropsFromDispatch = {
  addConversationSupervisorAction: ConversationSupervisorStoreActionCreators.addConversationAction,
  clearDebtorClaimsSelection: DebtorClaimsStoreActionCreators.clearDebtorClaimsSelection,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(SendPaymentInformation);
