import React from 'react';
import { Typography } from '@mui/material';
import { formatPhoneNumber, urlify } from '../../../components/Utils';
import { colors } from '../../../colors';
import LinkUnopenedIcon from '@mui/icons-material/PhonelinkOff';
import LinkOpenedIcon from '@mui/icons-material/Phonelink';
import LockedIcon from '@mui/icons-material/LockOutlined';
import NotReceivedIcon from '@mui/icons-material/CallMissedOutgoing';
import CheckIcon from '@mui/icons-material/Check';
import TimerIcon from '@mui/icons-material/AccessTime';
import moment from 'moment';

interface Props {
  header: string;
  content: string;
}

const getFormattedTooltipContent = (header: string, content: string, props) => {
  var formattedContent: any = content;
  var color = colors.black;

  switch (header) {

    case 'Mottaker':
      if (content) { formattedContent = formatPhoneNumber(content); }
      break;

    case 'Melding':
      if (!content) {
        color = colors.darkred;
        formattedContent = 'Melding ukjent';
        break;
      }
      formattedContent = <span dangerouslySetInnerHTML={{__html: urlify(content)}}/>;
      break;

    case 'Første pålogging':
      const opened = moment(content, 'DD.MM.YYYY HH:mm:ss').isValid();
      color = opened ? colors.green : colors.darkred;
      formattedContent = opened ?
        <React.Fragment>
          <LinkOpenedIcon sx={{
            verticalAlign: 'bottom',
            height: '0.9em',
            color: colors.green
          }} /> {content}
        </React.Fragment> :
        <React.Fragment>
          <LinkUnopenedIcon sx={{
            verticalAlign: 'bottom',
            height: '0.9em',
            color: colors.darkred
          }} /> Ingen pålogging
        </React.Fragment>;
      break;

    case 'Bekreftet mottatt':
      const received = moment(content, 'DD.MM.YYYY HH:mm:ss').isValid();
      color = received ? colors.green : colors.darkred;
      formattedContent = received ?
        <React.Fragment>
          <CheckIcon sx={{
            verticalAlign: 'bottom',
            height: '0.9em',
            color: colors.green
          }} />
          {content}
        </React.Fragment> :
        <React.Fragment>
          <NotReceivedIcon sx={{
            verticalAlign: 'bottom',
            height: '0.9em',
            color: colors.darkred
          }} />
          Ikke mottatt
        </React.Fragment>;
      break;

    case 'Utløpsdato':
      const expires = moment(content, 'DD.MM.YYYY HH:mm:ss');
      if (!expires.isValid()) { break; }
      const now = moment();
      const hasExpired = expires < now;
      const diff = expires.diff(now);
      color = hasExpired ? colors.darkred : colors.green;
      formattedContent = hasExpired ?
        <React.Fragment>
          <LockedIcon sx={{
            verticalAlign: 'bottom',
            height: '0.9em',
            color: colors.darkred
          }} />
          {expires.format('DD.MM.YYYY HH:mm:ss')}
        </React.Fragment> :
        <React.Fragment>
          <TimerIcon sx={{
            verticalAlign: 'bottom',
            height: '0.9em',
            color: colors.green
          }} />
          {moment(diff).format('H')} timer og {moment(diff).format('m')} min igjen ({content})
        </React.Fragment>;
      break;

    default:
      break;
  }

  return <span style={{ color : color}}>{formattedContent}</span>;
};

export default function TooltipContent(props: Props) {
  return (
    <Typography sx={{ whiteSpace: 'pre-line' }}>
      <b>{props.header}: </b>{getFormattedTooltipContent(props.header, props.content, props)}
    </Typography>
  )
};
