import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { colors } from '../../../../colors';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import WarningIcon from '@mui/icons-material/PlaylistRemove';
import SuccessIcon from '@mui/icons-material/PlaylistAddCheck';
import { RegisterCreditNoteConversationAction, CreditType, SelectedDebtorClaim, DebtorClaim, ProviderSystem } from '../../../../api/client';
import { actionCreators as ConversationSupervisorStoreActionCreators } from '../../../../store/ConversationSupervisorStore';
import { actionCreators as DebtorClaimsStoreActionCreators } from '../../../../store/DebtorClaimsStore';
import {
  hasSelectedDebtorClaims, getDebtorClaimsEligibleForCredit, getDebtorClaimsEligibleForFeeCredit,
  getDebtorClaimsEligibleForInvoiceFeeCredit, getDebtorClaimsEligibleForNoticeFeeCredit, hasSelectedDebtorClaimsEligibleForFeeCredit,
  hasSelectedDebtorClaimsEligibleForInvoiceFeeCredit, hasSelectedDebtorClaimsEligibleForNoticeFeeCredit, getValidSelectedDebtorClaimsForCredit
} from '../../../../store/Selectors';
import { Step } from '../ConversationSupervisorStep';
import CreditStatusListItem from './CreditStatusListItem';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import StepZilla from 'react-stepzilla';
import FormGroup from '@mui/material/FormGroup';

interface PropsFromState {
  selectedDebtorClaims: SelectedDebtorClaim[];
  hasSelectedDebtorClaims: boolean;
  hasSelectedDebtorClaimsEligibleForFeeCredit: boolean;
  hasSelectedDebtorClaimsEligibleForInvoiceFeeCredit: boolean;
  hasSelectedDebtorClaimsEligibleForNoticeFeeCredit: boolean;
  debtorClaimsEligibleForCredit: DebtorClaim[];  
  debtorClaimsEligibleForFeesCredit: DebtorClaim[];
  debtorClaimsEligibleForInvoiceFeeCredit: DebtorClaim[];
  debtorClaimsEligibleForNoticeFeeCredit: DebtorClaim[];  
}

interface PropsFromDispatch {
  addConversationSupervisorAction: typeof ConversationSupervisorStoreActionCreators.addConversationAction;
  clearDebtorClaimsSelection: typeof DebtorClaimsStoreActionCreators.clearDebtorClaimsSelection;
  toggleDebtorClaimSelection: typeof DebtorClaimsStoreActionCreators.toggleDebtorClaimSelection;
}

interface InjectedProps {
  jumpToStep?: typeof StepZilla.jumpToStep;
}

interface State {
  noteText: string;
  creditType: CreditType | undefined;
  isCreditInvoiceFeeSelected: boolean;
  isCreditDebtCollectionNoticeFeeSelected: boolean;
}

type ComponentProps = PropsFromState & PropsFromDispatch & InjectedProps;

export class Credit extends React.Component<ComponentProps, State> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      noteText: '',
      creditType: undefined,
      isCreditInvoiceFeeSelected: false,
      isCreditDebtCollectionNoticeFeeSelected: false,
    };
  }

  isValid = () => {
    return this.state.noteText.trim() && this.props.hasSelectedDebtorClaims &&
      (this.state.creditType &&
        (this.state.creditType == CreditType.Full ||
          (this.state.creditType == CreditType.Fees && this.state.isCreditInvoiceFeeSelected || this.state.isCreditDebtCollectionNoticeFeeSelected)
        ));
  }

  handleTextFieldChange = event => {
    this.setState({ noteText: event.target.value });
  }

  handleRadioButtonClick = event => {
    if (event.target.checked === true) {
      this.setState({ creditType: event.target.value as CreditType });
      this.setState({ isCreditInvoiceFeeSelected: false });
      this.setState({ isCreditDebtCollectionNoticeFeeSelected: false });
    }
  }

  handleCheckboxClick = event => {
    if (event.target.value == CreditType.InvoiceFee) {
      this.setState({isCreditInvoiceFeeSelected: event.target.checked});
    }
    if (event.target.value == CreditType.DebtCollectionNoticeFee) {
      this.setState({ isCreditDebtCollectionNoticeFeeSelected: event.target.checked });
    }
  }  

  anySelectedDebtorClaimsAreEligibleForNoticeFeeCredit = () => {
    this.props.selectedDebtorClaims.forEach((selectedDebtorClaim) => {
      return this.props.debtorClaimsEligibleForNoticeFeeCredit.some((eligibleDebtorClaim) => eligibleDebtorClaim.number === selectedDebtorClaim.number);
    });
  }

  checkEligibilityOfSelectedCredit = () => {

    if (!this.state.creditType || (this.state.creditType == CreditType.Fees && !this.state.isCreditInvoiceFeeSelected && !this.state.isCreditDebtCollectionNoticeFeeSelected)) {
      return null;
    }
    
    const warningsList: { selectedDebtorClaim: SelectedDebtorClaim, showWarning: boolean, text: string }[] = [];

    this.props.selectedDebtorClaims.forEach((selectedDebtorClaim) => {
      let statusText = '';
      let showWarning = false;

      if (this.state.creditType! == CreditType.Full) {
        const isEligible = this.props.debtorClaimsEligibleForCredit.some((eligibleDebtorClaim) => eligibleDebtorClaim.number === selectedDebtorClaim.number);
        statusText += isEligible ? `Hele kravet kan krediteres (${selectedDebtorClaim.balanceAmount?.toFixed(2).replace(".", ",")} kr)` : 'Kravet kan ikke krediteres';
        showWarning = isEligible ? showWarning : true;
      }

      if (this.state.isCreditInvoiceFeeSelected) {        
        const isEligible = this.props.debtorClaimsEligibleForInvoiceFeeCredit.some((eligibleDebtorClaim) => eligibleDebtorClaim.number === selectedDebtorClaim.number);
        statusText += isEligible ? `Fakturagebyret kan krediteres (${selectedDebtorClaim.invoiceFeeAmount?.toFixed(2).replace(".", ",")} kr)` : 'Har ikke fakturagebyr';
        showWarning = isEligible ? showWarning : true;
      }

      if (this.state.isCreditDebtCollectionNoticeFeeSelected) {
        if (statusText.length > 0) statusText += '\n';
        const isEligible = this.props.debtorClaimsEligibleForNoticeFeeCredit.some((eligibleDebtorClaim) => eligibleDebtorClaim.number === selectedDebtorClaim.number);
        statusText += isEligible ? `Varselsgebyret kan krediteres (${selectedDebtorClaim.noticeFeeAmount?.toFixed(2).replace(".", ",")} kr)` :
          selectedDebtorClaim.providerSystem == ProviderSystem.LEAP ? 'Varselsgebyret kan ikke krediteres' : 'Har ikke varselsgebyr';
        showWarning = isEligible ? showWarning : true;
      }

      warningsList.push({
        selectedDebtorClaim: selectedDebtorClaim, showWarning: showWarning, text: statusText.trim()
      });
    });

    return (      
      this.state.creditType &&
      <List >
          {warningsList && Object.keys(warningsList).map((key) => (
            <ListItem key={key} sx={{
              paddingTop: '0',
              paddingBottom: '0',
              marginLeft: '-3px'
            }} >
              <ListItemIcon sx={{ alignSelf: 'start'} }>
                {
                  warningsList[key].showWarning
                    ? <WarningIcon sx={{ fontSize: '2.1rem', color: colors.orange, paddingTop: '4px' }} />
                    : <SuccessIcon sx={{ fontSize: '2.1rem', color: colors.primary.bondiblue, paddingTop: '4px' }} />
                }
              </ListItemIcon>
              <ListItemText
                sx={{
                  whiteSpace: 'pre-line',
                  paddingLeft: '10px'
                }}
                primary={warningsList[key].number}
                secondary={warningsList[key].text}
              />
            </ListItem>
          ))}
        </List>
    )
  }

  getCreditTypeToSubmit = () => {
           
    if (this.state.creditType && this.state.creditType == CreditType.Full) {
      return CreditType.Full;
    }
    if (this.state.creditType && this.state.creditType == CreditType.Fees) {

      if (this.state.isCreditInvoiceFeeSelected && this.state.isCreditDebtCollectionNoticeFeeSelected) {
        return CreditType.Fees;
      }

      if (this.state.isCreditInvoiceFeeSelected) {
        return CreditType.InvoiceFee;
      }

      if (this.state.isCreditDebtCollectionNoticeFeeSelected) {
        return CreditType.DebtCollectionNoticeFee;
      }

      return CreditType.Fees;
    }

    return CreditType.Invalid;
  }

  

  handleSubmit = event => {
    event.preventDefault();

    let creditTypeToSubmit = this.getCreditTypeToSubmit();
    let selectedDebtorClaimsToSubmit = getValidSelectedDebtorClaimsForCredit(this.props.selectedDebtorClaims, creditTypeToSubmit);

    const action: RegisterCreditNoteConversationAction = {
      selectedDebtorClaims: selectedDebtorClaimsToSubmit,
      reason: this.state.noteText,
      creditType: creditTypeToSubmit,
      discriminator: 'RegisterCreditNoteConversationAction',
    };

    this.props.addConversationSupervisorAction(action);
    this.props.clearDebtorClaimsSelection();
    this.props.jumpToStep(Step.Summary);
  }

  shouldComponentUpdate(props: any, state: State) {
    if (state.creditType == CreditType.Fees && !props.hasSelectedDebtorClaimsEligibleForFeeCredit) {
      this.setState({ creditType: undefined })
      return false;
    }
    if (state.isCreditInvoiceFeeSelected && !props.hasSelectedDebtorClaimsEligibleForInvoiceFeeCredit) {
      this.setState({ isCreditInvoiceFeeSelected: false })
      return false;
    }
    if (state.isCreditDebtCollectionNoticeFeeSelected && !props.hasSelectedDebtorClaimsEligibleForNoticeFeeCredit) {
      this.setState({ isCreditDebtCollectionNoticeFeeSelected: false })
      return false;
    }
    return true;
  }

  componentDidMount() {
    this.props.selectedDebtorClaims.forEach(
      selectedDebtorClaim => this.props.debtorClaimsEligibleForCredit.every(
        eligibleClaim => eligibleClaim.number !== selectedDebtorClaim.number)
        ? this.props.toggleDebtorClaimSelection({
          providerSystem: selectedDebtorClaim.providerSystem, number: selectedDebtorClaim.number!.toString(),
          type: selectedDebtorClaim.type!.toString(), externalReferenceId: selectedDebtorClaim.externalReferenceId!
        })
        : null
    );
  }

  public render() {
    return (
      <React.Fragment>
        <FormControl>
          <Typography sx={{
            marginBottom: '10px',
            marginTop: '25px',
            color: colors.black
          }}>
            Hva vil du kreditere?
          </Typography>
          <RadioGroup>
             <FormControlLabel sx={{ marginBottom: '-10px' }}
              value={CreditType.Full}
              control={
                <Radio sx={{
                  color: colors.darkGray,
                  '&$checked': { color: 'primary.bondiblue' }
                }}
                  onClick={this.handleRadioButtonClick}
                  checked={this.state.creditType == CreditType.Full}
              />
              }
              label={'Hele fakturaen'}
            />
            <FormControlLabel sx={{ marginBottom: '-10px' }}
              value={CreditType.Fees}
              control={
                <Radio sx={{
                  color: colors.darkGray,
                  '&$checked': { color: 'primary.bondiblue' }
                }}
                  onClick={this.handleRadioButtonClick}
                  checked={this.state.creditType == CreditType.Fees}
                />
              }
              label={'Gebyrer'}
              disabled={!this.props.hasSelectedDebtorClaimsEligibleForFeeCredit}
            />
          </RadioGroup>
         
          <FormGroup>
            <FormControlLabel
              sx={{
                marginBottom: '-10px',
                marginLeft: '15px',
              }}
              control={
                <Checkbox sx={{
                  color: colors.darkGray,
                  '&$checked': { color: 'primary.bondiblue' }
                }}
                  disabled={this.props.hasSelectedDebtorClaimsEligibleForInvoiceFeeCredit === false || !this.state.creditType || this.state.creditType.toString() !== CreditType.Fees.toString()}
                  value={CreditType.InvoiceFee}
                  onClick={this.handleCheckboxClick}
                  checked={this.state.isCreditInvoiceFeeSelected}
                  name={'InvoiceFeeCheckBox'}
                />
              }
              label={'Fakturagebyr'}
            />
            <FormControlLabel
              sx={{
                marginBottom: '-10px',
                marginLeft: '15px',
              }}
              control={
                <Checkbox sx={{
                  color: colors.darkGray,
                  '&$checked': { color: 'primary.bondiblue' }
                }}
                  disabled={this.props.hasSelectedDebtorClaimsEligibleForNoticeFeeCredit === false || !this.state.creditType || this.state.creditType != CreditType.Fees}
                  value={CreditType.DebtCollectionNoticeFee}
                  onClick={this.handleCheckboxClick}
                  checked={this.state.isCreditDebtCollectionNoticeFeeSelected}
                />
              }
              label={'Varselsgebyr'}
            />
          </FormGroup>
        </FormControl>
        {this.props.selectedDebtorClaims.length > 0 && this.state.creditType
          && (this.state.creditType == CreditType.Full || (this.state.creditType == CreditType.Fees && this.state.isCreditInvoiceFeeSelected || this.state.isCreditDebtCollectionNoticeFeeSelected))
          && <Box sx={{
          padding: '12px 8px',
          marginTop: '20px',
          background: '#E0F2F166'
        }}>
          {this.state.creditType && this.props.selectedDebtorClaims.map(x =>
            <CreditStatusListItem key={x.providerSystem + x.externalReferenceId}
              selectedDebtorClaim={x}
              selectedCreditType={this.state.creditType!}
              isCreditInvoiceFeeSelected={this.state.isCreditInvoiceFeeSelected}
              isCreditDebtCollectionNoticeFeeSelected={this.state.isCreditDebtCollectionNoticeFeeSelected} />)
          }
        </Box>}
        <TextField
          label="Årsak"
          autoFocus={true}
          required={true}
          error={!this.state.noteText.trim()}
          multiline={true}
          maxRows={15}
          margin="normal"
          sx={{
            marginTop: '30px',
            minWidth: '100%'
          }}
          value={this.state.noteText}
          onChange={this.handleTextFieldChange}
        />
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
          <Button variant="contained" onClick={() => this.props.jumpToStep(Step.Start)}>
            Avbryt
          </Button>
          <Button variant="contained" id={'submit'} onClick={this.handleSubmit} disabled={!this.isValid()} type="submit">
            OK
          </Button>
        </Box>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  selectedDebtorClaims: state.debtorClaims.selectedDebtorClaims,
  hasSelectedDebtorClaims: hasSelectedDebtorClaims(state.debtorClaims),
  hasSelectedDebtorClaimsEligibleForFeeCredit: hasSelectedDebtorClaimsEligibleForFeeCredit(state),
  hasSelectedDebtorClaimsEligibleForInvoiceFeeCredit: hasSelectedDebtorClaimsEligibleForInvoiceFeeCredit(state),
  hasSelectedDebtorClaimsEligibleForNoticeFeeCredit: hasSelectedDebtorClaimsEligibleForNoticeFeeCredit(state),
  debtorClaimsEligibleForCredit: getDebtorClaimsEligibleForCredit(state),
  debtorClaimsEligibleForFeesCredit: getDebtorClaimsEligibleForFeeCredit(state),
  debtorClaimsEligibleForInvoiceFeeCredit: getDebtorClaimsEligibleForInvoiceFeeCredit(state),
  debtorClaimsEligibleForNoticeFeeCredit: getDebtorClaimsEligibleForNoticeFeeCredit(state)
});

const mapDispatchToProps: PropsFromDispatch = {
  addConversationSupervisorAction: ConversationSupervisorStoreActionCreators.addConversationAction,
  clearDebtorClaimsSelection: DebtorClaimsStoreActionCreators.clearDebtorClaimsSelection,
  toggleDebtorClaimSelection: DebtorClaimsStoreActionCreators.toggleDebtorClaimSelection,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Credit);
