import React from 'react';
import Typography from '@mui/material/Typography';
import AccountNumber from '../../common/AccountNumber';
import Card from '@mui/material/Card';
import { colors } from '../../../colors';

interface Props {
  kid?: string | undefined;
  accountNumber?: string | undefined;
}

export default function DebtorPaymentInformation(props: Props) {
  return (
    <Card
      variant="outlined"
      sx={{
        maxWidth: '60%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '0 auto',
        backgroundColor: colors.primary.milkyway,
        marginTop: '16px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '20px',
        paddingRight: '8px'
      }}>
      <Typography>KID-nummer: {props.kid}</Typography>
      <AccountNumber accountNumber={props.accountNumber} />
    </Card>
  )
};
