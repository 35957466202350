import React from 'react';
import AdministratorPage from '../../components/authentication/AdministratorPage';
import Typography from '@mui/material/Typography';

const Administration: React.FunctionComponent = () => (
  <React.Fragment>
    <Typography sx={{
      color: 'secondary.dark'
    }}>Administration page</Typography>
  </React.Fragment>
);

export default AdministratorPage(Administration);
