import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { ResultOfDebtorIndex } from '../../../api/client';
import Person from '@mui/icons-material/Person';
import { formatNationalIdentityNumber, formatPhoneNumber } from '../../../components/Utils';
import { history } from '../../../AppHistory';
import { actionCreators as ConversationSupervisingStoreActionCreators } from '../../../store/ConversationSupervisorStore';
import { actionCreators as DebtorClaimsStoreActionCreators } from '../../../store/DebtorClaimsStore';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DebtorSearchResultReason from './DebtorSearchResultReason';
import PowerOfAttorney from '../../../components/powerOfAttorney/PowerOfAttorney';
import { actionCreators as DebtorSearchActionCreators } from '../../../store/DebtorSearchStore';

interface Props {
  item: ResultOfDebtorIndex;
  resetConversationSupervisor: typeof ConversationSupervisingStoreActionCreators.resetConversationSupervisor;
  resetDebtorClaims: typeof DebtorClaimsStoreActionCreators.resetDebtorClaims;
  selectDebtor: typeof DebtorSearchActionCreators.selectDebtor;
}

export default function DebtorSearchResultItem(props: Props) {
  const handleRedirect = () => {
    props.resetConversationSupervisor();
    props.resetDebtorClaims();
    props.selectDebtor(props.item);
    history.push(`/debtorlookup`);
  };

  return (
    <Card raised={true} sx={{ width: '750px', margin: '10px auto' }}>
      <CardContent sx={{ minHeight: '100px'}}>
        <Box sx={{ mb: '10px' }}>
          <Person sx={{
            float: 'left',
            marginTop: '3px'
          }} />
          <Typography variant="h6" sx={{
            float: 'left',
            marginRight: '10px',
            marginLeft: '10px',
            fontWeight: 'bold'
          }}>{props.item.document!.name}</Typography>
          <Typography sx={{
            fontSize: '1.25rem',
            lineHeight: '1.6'
          }}>
            {formatNationalIdentityNumber(props.item.document!.nationalIdentityNumber) || props.item.document!.dateOfBirth}
          </Typography>
        </Box>
        <Box sx={{ mb: '10px' }}>
          <Typography>{props.item.document!.street}</Typography>
          <Typography>{props.item.document!.postCode} {props.item.document!.postArea}</Typography>
          <Typography>{props.item.document!.country}</Typography>
        </Box>
        <Box sx={{ mb: '10px' }}>
          {props.item.document!.phoneNumbers && props.item.document!.phoneNumbers!.map((item, i) => <Typography key={i}>{formatPhoneNumber(item)}</Typography>)}
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
          <PowerOfAttorney debtor={props.item} />
          <Button variant="contained" onClick={handleRedirect}>
            Legitimasjon
          </Button>
          <Button variant="contained" onClick={handleRedirect}>
            Ringer selv
          </Button>
        </Box>
        <Divider />
        <DebtorSearchResultReason highlights={props.item.highlights} />
      </CardContent>
    </Card>
  );
};
