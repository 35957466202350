import React from 'react';
import DebtorSearchDialog from '../debtorSearch/containers/DebtorSearchDialog';
import DebtorSearchResults from '../debtorSearch/containers/DebtorSearchResults';
import customerCareWorkerPage from '../authentication/CustomerCareWorkerPage';
import SubmittedConversationsDrawer from '../submittedConversations/containers/SubmittedConversationsDrawer';
import Box from '@mui/material/Box';

function DebtorSearch() {
  return (
    <Box sx={{
      display: 'grid',
      gridTemplateColumns: '95% auto',
      gridTemplateRows: '120px auto',
      backgroundColor: 'background.default',
      position: 'relative',
    }}>
      <Box sx={{
        gridColumnStart: '1',
        gridRowStart: '1',
      }}>
        <DebtorSearchDialog />
      </Box>

      <Box sx={{
        gridColumnStart: '1',
        gridRowStart: '2',
      }}>
        <DebtorSearchResults />
      </Box>

      <Box sx={{
        gridColumnStart: '2',
        gridRowStart: '2',
        position: 'fixed',
        bottom: '0px',
        right: '10px',
      }}>
        <SubmittedConversationsDrawer />
      </Box>
    </Box>
  )
};

export default customerCareWorkerPage(DebtorSearch);
