export const colors = {
  grape: '#502882',
  gray: '#585858',
  darkGray: '#ABABAB',
  shadow: '#D5D5D5',
  marble: '#ECF1F4',
  lightGrape: '#EEEAF3',
  lightPurple: '#EFEBF3',
  lightGray: '#FAFAFA',
  lightCyan: '#E0F2F166',
  white: '#FFFFFF',
  raspberry: '#B4148C',
  red: '#E20052',
  darkred: '#CD001A',
  green: '#00C24F',
  blue: '#3737ff',
  lightRed: '#E57373',
  smoke: '#A394A9',
  black: '#000000',
  amber: '#FFD100',
  orange: '#FF9800',
  primary: {
    bondiblue: '#00B1B1',
    bluezodiac: '#163556',
    milkyway: '#E4F3F3',
  },
  secondary: {
    jaggedice: '#B8E6E6',
    slategray: '#708090',
    bamboo: '#6CBA86',
    salmon: '#FF9F7A',
    springgreen: '#28FFAB',
    bluelagoon: '#005E69',
    zeus: '#3C3C3C',
    candy: '#DC5A6E',
    lemon: '#FFD31B',
    lightLemon: '#FFD31BFA',
  }
};