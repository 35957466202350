import React from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { colors } from '../../../colors';
import { DebtCollectionCompany } from '../../../api/client';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

interface Props {
  companyInfo: DebtCollectionCompany;
}

export default function ExternalDebtCollectionInfo(props: Props) {
  return (
    <React.Fragment>
      <Card
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'left',
          borderRadius: 0,
          borderTopWidth: '1px',
          borderBottomWidth: '1px',
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderColor: colors.primary.bluezodiac,
          margin: '0 auto',          
          backgroundColor: colors.secondary.lemon + '80',
          marginBottom: '4px',
          paddingTop: '10px',
          paddingBottom: '10px',
          paddingLeft: '15px',
          paddingRight: '8px'
        }}>
        <div style={{ width: '50px' }}>
          <ForwardToInboxIcon sx={{ fontSize: '35px'}} />
        </div>
        <div>
          <Typography fontWeight='bold'>Overført til {props.companyInfo.name}</Typography>
          {props.companyInfo.displayPhone && <Typography>Telefon: {props.companyInfo.displayPhone}</Typography>}
          {props.companyInfo.displayEmail && <Typography>Epost: {props.companyInfo.displayEmail}</Typography>}
          {props.companyInfo.webpage && <Typography>Nettside: {props.companyInfo.webpage}</Typography>}
        </div>
      </Card>
    </React.Fragment>
  )

};
