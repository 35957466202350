import React from 'react';
import List from '@mui/material/List';
import { Conversation } from '../../../api/client';
import { actionCreators } from '../../../store/ConversationSupervisorStore';
import { Step } from './ConversationSupervisorStep';
import ConversationSummaryItem from './ConversationSummaryItem';
import ConversationInterruptedSummaryItem from './ConversationInterruptedSummaryItem';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import StepZilla from 'react-stepzilla';

interface Props {
  conversation: Conversation;
  cancelConversationInterruption: typeof actionCreators.cancelConversationInterruption;
  jumpToStep: typeof StepZilla.jumpToStep;
  removeConversationAction: typeof actionCreators.removeConversationAction;
  submitConversation: typeof actionCreators.submitConversation;
}

export const CanSubmitConversation = (conversation: Conversation) => {
  return conversation.actions!.length !== 0 || conversation.wasInterrupted;
};

export default function ConversationSummaryList(props: Props) {
  return (
    <React.Fragment>
      <Box sx={{
        paddingTop: '10px',
        minHeight: '370px',
      }}>
        <List dense={true}>
          {props.conversation.actions!.map((item, i) =>
            <ConversationSummaryItem
              key={i}
              index={i}
              item={item}
              conversationWasInterrupted={props.conversation.wasInterrupted}
              removeConversationAction={props.removeConversationAction}
            />)
          }
          {props.conversation.wasInterrupted &&
            <ConversationInterruptedSummaryItem
              interruptionReason={props.conversation.interruptionReason}
              cancelConversationInterruption={props.cancelConversationInterruption}
            />
          }
        </List>
      </Box>
      <Box sx={{
        float: 'right',
      }}>
        <Button variant="contained" onClick={() => props.jumpToStep(Step.Start)} disabled={props.conversation.wasInterrupted}>
          Ny hendelse
        </Button>
        <Button variant="contained" onClick={() => props.submitConversation()} disabled={!CanSubmitConversation(props.conversation)}>
          Fullfør samtalen
        </Button>
      </Box>
    </React.Fragment>
  )
};
