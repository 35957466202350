import { Reducer } from 'redux';
import { AppThunkAction } from '../store';

export enum ActionTypes {
  LOGON_SUCCESS = '@@authentication/LOGON_SUCCESS',
}

export interface AuthenticationState {
  isAuthenticated: boolean;
  userProfile?: string;
  roles: string[];
}

interface LogonSuccessAction {
  type: ActionTypes.LOGON_SUCCESS;
  payload: { user: { name: string, idToken?: { roles?: string[] } } };
}

export type KnownAction = LogonSuccessAction;

export const initialState = { isAuthenticated: false, roles: [] };

export const actionCreators = {
  authenticated: (payload: any): AppThunkAction<KnownAction> => (dispatch) => {
    localStorage.setItem('accessToken', payload.jwtAccessToken);
    dispatch({ type: ActionTypes.LOGON_SUCCESS, payload });
  }
};

export const reducer: Reducer<AuthenticationState | undefined, KnownAction> = (state = initialState, action: KnownAction) => {
  switch (action.type) {
    case ActionTypes.LOGON_SUCCESS:
      return {
        ...state,
        userProfile: decodeURI(escape(action.payload.user.name)),
        roles: (action.payload.user.idToken && action.payload.user.idToken.roles) || [],
        isAuthenticated: true,
      };
    default:
      return state;
  }
};

export const authenticatedAsAdministrator = (state: AuthenticationState) => {
  return state.roles.some(i => i === 'Administrator');
};
export const authenticatedAsCustomerCareWorker = (state: AuthenticationState) => {
  return state.roles.some(i => i === 'CustomerCareWorker') || authenticatedAsAdministrator(state);
};
export const authenticatedAsTerminalWorker = (state: AuthenticationState) => {
  return state.roles.some(i => i === 'TerminalWorker') || authenticatedAsAdministrator(state);
};