import React from 'react';
import SecurePage from '../../components/authentication/SecurePage';
import { Redirect } from 'react-router-dom';
import { PageProps } from './DefaultPage';

const customerCareWorkerSecurePageHoc = Page => class CustomerCareWorkerPage extends React.Component<PageProps> {
  render() {
    if (!this.props.customerCareWorker) {
      return <Redirect to="/forbidden" />;
    }

    return <Page {...this.props} />;
  }
};

const secureCustomCareWorkerPage = Page => SecurePage(customerCareWorkerSecurePageHoc(Page));

export default secureCustomCareWorkerPage;
