import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Layout from './Layout';
import Login from './components/authentication/Login';
import RootNode from './components/rootNode/RootNode';
import DebtorSearch from './components/debtorSearch/DebtorSearch';
import DebtorSearchResultFindRelatedDebtorIds from './components/debtorSearch/DebtorSearchResultFindRelatedDebtorIds';
import DebtorOverview from './components/debtorOverview/DebtorOverview';
import NotFound from './components/notFound/NotFound';
import IncomingCall from './components/callcenter/IncomingCall';
import TerminalWorker from './components/terminalWorker/TerminalWorker';
import Forbidden from './components/forbidden/Forbidden';
import Administration from './components/administration/Administration';

class App extends React.Component {

  render() {
    return (
      <Layout>
        <Switch>
          <Route exact={true} path="/login" component={Login} />
          <Route exact={true} path="/search" component={DebtorSearch} />
          <Route exact={true} path="/debtor" component={DebtorOverview} />
          <Route exact={true} path="/debtorlookup" component={DebtorSearchResultFindRelatedDebtorIds} />
          <Route exact={true} path="/callcenter/incoming" component={IncomingCall} />
          <Route exact={true} path="/terminal" component={TerminalWorker} />
          <Route exact={true} path="/administration" component={Administration} />
          <Route exact={true} path="/" component={RootNode} />
          <Route exact={true} path="/forbidden" component={Forbidden} />
          <Route exact={true} path="*" component={NotFound} />
        </Switch>
      </Layout>
    );
  }
}

export default App;