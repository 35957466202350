enum ConversationSupervisorStep {
  Start = 0,
  OtherReason = 1,
  ConversationInterrupted = 2,
  Summary = 3,
  PaymentAgreement = 4,
  PaymentInformation = 5,
  SendPaymentInformation = 6,
  HasPaid = 7,
  PaymentNotRegistered = 8,
  Objection = 9,
  Credit = 10,
}

enum ConversationSupervisorStepsGroup {
  HasPaid = 0,
  WillPay = 1,
  HasObjection = 2,
  Other = 3,
  Interrupted = 4,
  Summary = 5,
  Credit = 6,
}

export const steps = [
  // Har betalt
  { id: ConversationSupervisorStep.HasPaid, group: ConversationSupervisorStepsGroup.HasPaid },
  { id: ConversationSupervisorStep.PaymentNotRegistered, group: ConversationSupervisorStepsGroup.HasPaid },
  // Vil betale
  { id: ConversationSupervisorStep.PaymentAgreement, group: ConversationSupervisorStepsGroup.WillPay },
  { id: ConversationSupervisorStep.PaymentInformation, group: ConversationSupervisorStepsGroup.WillPay },
  { id: ConversationSupervisorStep.SendPaymentInformation, group: ConversationSupervisorStepsGroup.WillPay },
  // Har innsigelse
  { id: ConversationSupervisorStep.Objection, group: ConversationSupervisorStepsGroup.HasObjection },
  // Annet
  { id: ConversationSupervisorStep.OtherReason, group: ConversationSupervisorStepsGroup.Other },
  // Samtalen avbrutt
  { id: ConversationSupervisorStep.ConversationInterrupted, group: ConversationSupervisorStepsGroup.Interrupted },
  // Oppsummering
  { id: ConversationSupervisorStep.Summary, group: ConversationSupervisorStepsGroup.Summary },
  // Kreditering
  { id: ConversationSupervisorStep.Credit, group: ConversationSupervisorStepsGroup.Credit },
 ];

export { ConversationSupervisorStep as Step };
export { ConversationSupervisorStepsGroup as StepsGroup };
