import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { actionCreators as PowerOfAttorneyStoreActionCreators } from '../../../store/PowerOfAttorneyStore';
import FormControlLabel from '@mui/material/FormControlLabel';
import { formatDate } from '../../../components/Utils';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Button from '@mui/material/Button';
import { AvailablePowerOfAttorney } from '../../../api/client';
import { ApplicationState } from '../../../store';
import { getExistingPowerOfAttorneys } from '../../../store/Selectors';
import DeactivatePowerOfAttorneyDialog from '../../../components/powerOfAttorney/containers/DeactivatePowerOfAttorneyDialog';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface InjectedProps {
  availablePowerOfAttorneys: AvailablePowerOfAttorney[];
  toggleModal: () => void;
  handleOk: () => void;
}
interface PropsFromDispatch {
  registerSelectedPowerOfAttorney: typeof PowerOfAttorneyStoreActionCreators.registerSelectedPowerOfAttorney;
}

interface PropsFromState {
  availablePowerOfAttorneys: AvailablePowerOfAttorney[];
  selectedPowerOfAttorney: AvailablePowerOfAttorney | null;
}

type ComponentProps = PropsFromState & PropsFromDispatch & InjectedProps;

export class ExistingPowerOfAttorneys extends React.Component<ComponentProps> {
  handlePowerOfAttorneySelection = (powerOfAttorney: AvailablePowerOfAttorney) => (event) => {
    this.props.registerSelectedPowerOfAttorney(powerOfAttorney);
  }

  isValid() {
    return !!this.props.selectedPowerOfAttorney;
  }

  formatAccess(includeAccess: boolean, includeAgreement: boolean) {
    return `${includeAccess && !includeAgreement ? 'innsyn' : includeAccess && includeAgreement ? 'innsyn og avtale' : !includeAccess && includeAgreement ? 'avtale' : ''}`;
  }
  public render() {
    return (
      <Box sx={{
        flexDirection: 'column',
        flex: 1
      }}>
        <React.Fragment>
          <RadioGroup>
            {this.props.availablePowerOfAttorneys.map((item, i) =>
              <FormControlLabel
                value={i.toString()}
                key={i}
                sx={{
                  display: 'inline-flex',
                  borderBottom: '1px solid #ccc',
                  padding: '12px 0px',
                  ...(item.active === true && {
                    backgroundColor: 'rgba(151, 151, 151, 0.11)'
                  }),
                }}
                control={<Radio color="primary" />}
                onChange={this.handlePowerOfAttorneySelection(item)}
                disabled={!item.active}
                label={
                  <Box sx={{
                    display: 'inline-flex',
                  }}>
                    <React.Fragment>
                      <Typography sx={{fontSize:'14px'}}>
                        Registrert {this.formatAccess(item.includeAccess, item.includeAgreement)}, {formatDate(item.registeredAtUtc)} av {item.registeredBy}:<br />
                        <i>Fullmakt gis av </i>: {item.onBehalfOf}<br />
                        <i>Fullmakt gis til </i>: {item.grantTo}<br />
                        <i>Med varighet </i>: {item.duration}<br />
                      </Typography>
                      {!item.active && item.deactivatedAtUtc &&
                        <Typography>
                          <strong>
                            Deaktivert {formatDate(new Date(item.deactivatedAtUtc))} av {item.deactivatedBy}
                          </strong>
                        </Typography>}
                    </React.Fragment>
                    {item.active && <DeactivatePowerOfAttorneyDialog powerOfAttorney={item} />}
                  </Box>}
              />
            )}
          </RadioGroup>
        </React.Fragment>
        <Box sx={{ display: 'flex', justifyContent:'flex-end' }}>
          <Button variant="contained" onClick={() => this.props.toggleModal()}>
            Avbryt
          </Button>
          <Button variant="contained" onClick={() => this.props.handleOk()} disabled={!this.isValid()}>
            OK
          </Button>
        </Box>
      </Box>
    );
  }
}

const mapDispatchToProps: PropsFromDispatch = {
  registerSelectedPowerOfAttorney: PowerOfAttorneyStoreActionCreators.registerSelectedPowerOfAttorney,
};

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  availablePowerOfAttorneys: getExistingPowerOfAttorneys(state),
  selectedPowerOfAttorney: state.powerOfAttorney.selectedPowerOfAttorney
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ExistingPowerOfAttorneys);
