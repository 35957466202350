import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { actionCreators as PowerOfAttorneyStoreActionCreators } from '../../../store/PowerOfAttorneyStore';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { colors } from '../../../colors';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { AvailablePowerOfAttorney } from '../../../api/client';

interface PropsFromState {
  selectedPowerOfAttorney: AvailablePowerOfAttorney | null;
}

interface PropsFromDispatch {
  clearPowerOfAttorneys: typeof PowerOfAttorneyStoreActionCreators.clearPowerOfAttorneys;
}

interface InjectedProps extends RouteComponentProps<{}> {
}

type ComponentProps = PropsFromState & PropsFromDispatch & InjectedProps;

class DebtorSelectedPowerOfAttorneyBanner extends React.Component<ComponentProps> {

  componentWillUnmount() {
    this.props.clearPowerOfAttorneys();
  }

  public render() {
    return (
      <React.Fragment>
        {this.props.selectedPowerOfAttorney ?
          <AppBar position="sticky" sx={{
            backgroundColor: colors.orange,
          }}>
            <Toolbar variant="dense" sx={{
              minHeight: 0,
              justifyContent: 'center'
            }}>
              <Typography variant="caption" color="inherit">
                {'På vegne av: ' + this.props.selectedPowerOfAttorney.onBehalfOf + ', ' +
                  'får: ' + this.props.selectedPowerOfAttorney.grantTo + ' ' +
                  (this.props.selectedPowerOfAttorney.includeAccess === true ?
                    this.props.selectedPowerOfAttorney.includeAgreement === true ? 'innsyn og avtale' : 'innsyn' :
                    this.props.selectedPowerOfAttorney.includeAgreement === true ? 'avtale' : '') +
                  ', med varighet: ' + this.props.selectedPowerOfAttorney.duration}
              </Typography>
            </Toolbar>
          </AppBar>
          : null
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  selectedPowerOfAttorney: state.powerOfAttorney.selectedPowerOfAttorney
});

const mapDispatchToProps: PropsFromDispatch = {
  clearPowerOfAttorneys: PowerOfAttorneyStoreActionCreators.clearPowerOfAttorneys,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(DebtorSelectedPowerOfAttorneyBanner));
