import React from 'react';
import { SelectedDebtorClaim, CreditType, ProviderSystem } from '../../../../api/client';
import { colors } from '../../../../colors';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SuccessIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import WarningIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ErrorIcon from '@mui/icons-material/CancelOutlined';

interface Props {
  selectedDebtorClaim: SelectedDebtorClaim;
  selectedCreditType: CreditType;
  isCreditInvoiceFeeSelected: boolean;
  isCreditDebtCollectionNoticeFeeSelected: boolean;
}

const getCreditStatusIcon = (hasValidOptions: boolean, hasInvalidOptions: boolean) => {
  
  if (hasValidOptions && !hasInvalidOptions) {
    return <SuccessIcon sx={{ fontSize: '2.1rem', color: colors.green, paddingTop: '6px' }} />
  }

  if (hasValidOptions && hasInvalidOptions) {
    return <WarningIcon sx={{ fontSize: '2.1rem', color: '#2196F3', paddingTop: '6px' }} />
  }

  if (!hasValidOptions && hasInvalidOptions) {
    return <ErrorIcon sx={{ fontSize: '2.1rem', color: colors.orange, paddingTop: '6px' }} />
  }
}

export default function CreditStatusListItem(props: Props) {
  if (!props.selectedCreditType || (props.selectedCreditType == CreditType.Fees && !props.isCreditInvoiceFeeSelected && !props.isCreditDebtCollectionNoticeFeeSelected)) {
    return null;
  }

  let statusText = '';
  let hasValidCreditOptions = false;
  let hasInvalidCreditOptions = false;

  if (props.selectedCreditType! == CreditType.Full) {
    const isEligible = props.selectedDebtorClaim.balanceAmount && props.selectedDebtorClaim.balanceAmount > 0;
    statusText += isEligible ? `Hele kravet kan krediteres (${props.selectedDebtorClaim.balanceAmount!.toFixed(2).replace(".", ",")} kr)` : 'Kravet kan ikke krediteres';
    
    if (isEligible) {
      hasValidCreditOptions = true; 
    } else {
      hasInvalidCreditOptions = true;
    }

  }

  if (props.isCreditInvoiceFeeSelected) {
    const isEligible = props.selectedDebtorClaim.invoiceFeeAmount && props.selectedDebtorClaim.invoiceFeeAmount > 0;
    statusText += isEligible ? `Fakturagebyret kan krediteres (${props.selectedDebtorClaim.invoiceFeeAmount!.toFixed(2).replace(".", ",")} kr)` : 'Har ikke fakturagebyr';
    
    if (isEligible) {
      hasValidCreditOptions = true;
    } else {
      hasInvalidCreditOptions = true;
    }

  }

  if (props.isCreditDebtCollectionNoticeFeeSelected) {
    if (statusText.length > 0) statusText += '\n';
    const isEligible = props.selectedDebtorClaim.noticeFeeAmount && props.selectedDebtorClaim.noticeFeeAmount > 0;
    statusText += isEligible ? `Varselsgebyret kan krediteres (${props.selectedDebtorClaim.noticeFeeAmount!.toFixed(2).replace(".", ",")} kr)` :
    props.selectedDebtorClaim.providerSystem == ProviderSystem.LEAP ? 'Varselsgebyret kan ikke krediteres' : 'Har ikke varselsgebyr';    

    if (isEligible) {
      hasValidCreditOptions = true;
    } else {
      hasInvalidCreditOptions = true;
    }
  }

  return (
    <ListItem sx={{
      paddingTop: '0',
      paddingBottom: '0',
      marginLeft: '-3px'
    }} >
      <ListItemIcon sx={{ alignSelf: 'start' }}>
        {
          getCreditStatusIcon(hasValidCreditOptions, hasInvalidCreditOptions)
        }
      </ListItemIcon>
      <ListItemText
        sx={{
          whiteSpace: 'pre-line',
          paddingLeft: '10px'
        }}
        primary={props.selectedDebtorClaim.number}
        secondary={statusText}
      />
    </ListItem>
  )
};