import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { colors } from '../../colors';

export default function EnvironmentBar() {
  const notProduction =!
  (window.location.hostname.startsWith('conveneportal.convenegroup.com') || window.location.hostname.startsWith('app-mcportal-webapp-prd.azurewebsites.net'));

  return (
    <AppBar position="sticky" sx={{
      backgroundColor: colors.secondary.springgreen
    }} style={{ display: notProduction ? 'block' : 'none' }}>
      <Toolbar variant="dense" sx={{
        minHeight: '0px',
        justifyContent: 'center'
      }}>
        <Typography variant="caption" color="inherit">
          Ikke produksjon!
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
