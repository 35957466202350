import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import Snackbar from '@mui/material/Snackbar';
import { actionCreators, Notification } from '../../store/NotificationsStore';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface PropsFromState {
  notifications: Notification[];
}

interface PropsFromDispatch {
  removeNotification: typeof actionCreators.removeNotification;
}

interface State {
  open: boolean;
}

type ComponentProps = PropsFromState & PropsFromDispatch;

class NotificationsSnackbar extends React.Component<ComponentProps, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      open: this.props.notifications.length > 0,
    };
  }

  componentDidUpdate() {
    if (!this.state.open && this.props.notifications.length > 0) {
      this.setState({ open: true });
    }
  }

  handleClose = () => {
    this.props.removeNotification();
    this.setState({ open: false });
  }

  public render() {

    return (
      <React.Fragment>
        {this.state.open && this.props.notifications[0] &&
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={this.state.open}
            autoHideDuration={10000}
            onClose={this.handleClose}>
            <Alert onClose={this.handleClose} severity={this.props.notifications[0].variant} sx={{ width: '100%' }}>
              {this.props.notifications[0].message}
            </Alert>
          </Snackbar>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ notifications }: ApplicationState): PropsFromState => ({
  notifications: notifications.messages,
});

const mapDispatchToProps: PropsFromDispatch = {
  removeNotification: actionCreators.removeNotification,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(NotificationsSnackbar);
