import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { SubmittedConversation } from '../../../store/ConversationSupervisorStore';
import { getSubmittedConversations } from '../../../store/Selectors';
import ContactPhone from '@mui/icons-material/ContactPhone';
import Drawer from '@mui/material/Drawer';
import SubmittedConversations from '../../../components/submittedConversations/presentation/SubmittedConversations';

interface PropsFromState {
  submittedConversations: SubmittedConversation[];
}

export class SubmittedConversationsDrawer extends React.Component<PropsFromState> {
  state = {
    drawerIsOpen: false,
  };

  toggleDrawer = () => {
    this.setState({
      drawerIsOpen: !this.state.drawerIsOpen,
    });
  }

  public render() {
    return (
      <React.Fragment>
        <Drawer anchor="right" open={this.state.drawerIsOpen} onClose={() => this.toggleDrawer()}>
          <SubmittedConversations submittedConversations={this.props.submittedConversations} />
        </Drawer>
        <ContactPhone onClick={() => this.toggleDrawer()} style={{ width: '48px', height: '48px' }} color="primary" />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  submittedConversations: getSubmittedConversations(state),
});

export default compose(connect(mapStateToProps))(SubmittedConversationsDrawer);
