import * as React from 'react';
import { history } from '../../../AppHistory';
import { SubmittedConversation } from '../../../store/ConversationSupervisorStore';
import { createDebtorIdsQueryString, formatEpochToTime } from '../../../components/Utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Lock from '@mui/icons-material/Lock';

interface Props {
  submittedConversations: SubmittedConversation[];
}

const handleClick = (conversation) => {
  if (!conversation.noAvailablePowerOfAttorney) {
    history.push('/debtor?' + createDebtorIdsQueryString(conversation.relatedDebtorIds));
  }
};

export default function SubmittedConversations(props: Props) {
  return (
    <Box sx={{
      padding: '10px',
      width: '400px',
      textAlign: 'center',
    }}>
      <Typography sx={{
        fontSize: '18px',
        fontWeight: 'bold',
        letterSpacing: '0.4px',
      }}>
        Samtaler i dag
      </Typography>
      <Table sx={{
        tableLayout: 'fixed',
      }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{
              width: '10px',
            }} />
            <TableCell>Debitor</TableCell>
            <TableCell align="right" sx={{
              width: '25%',
            }}>Tidspunkt</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.submittedConversations.map((conversation, i) => {
            return (
              <TableRow key={i} hover={true} onClick={() => handleClick(conversation)}>
                <TableCell>
                  {conversation.noAvailablePowerOfAttorney && <Lock />}
                </TableCell>
                <TableCell sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}>
                  {conversation.debtorName}
                </TableCell>
                <TableCell align="right" sx={{
                  width: '25%',
                }}>
                  {formatEpochToTime(conversation.submittedAt)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  )
};
