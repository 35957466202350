import React from 'react';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';

interface Props {
  previousStep: () => void;
}

export default function PreviousStep(props: Props) {
  return (
    <Button variant="flat" sx={{
      marginTop: '10px'
    }} onClick={props.previousStep}>
      <ArrowBack />
    </Button>
  )
};
