import {
  ConfirmDebtorClaimsAsClosedAction,
  CreditType,
  DebtorMadePaymentRecentlyAction,
  DebtorWillSendPaymentConfirmationAction,
  ObjectionConversationAction,
  OtherReasonConversationAction,
  SendPaymentInformationConversationAction,
  RegisterCreditNoteConversationAction,
  ConversationAction
} from './client';

declare module './client' {
  export interface OtherReasonConversationAction {
    discriminator: 'OtherReasonConversationAction';
  }
  export interface SendPaymentInformationConversationAction {
    discriminator: 'SendPaymentInformationConversationAction';
  }
  export interface ConfirmDebtorClaimsAsClosedAction {
     discriminator: 'ConfirmDebtorClaimsAsClosedAction';
  }
  export interface DebtorMadePaymentRecentlyAction {
     discriminator: 'DebtorMadePaymentRecentlyAction';
  }
  export interface DebtorWillSendPaymentConfirmationAction {
     discriminator: 'DebtorWillSendPaymentConfirmationAction';
  }
  export interface ObjectionConversationAction {
     discriminator: 'ObjectionConversationAction';
  }
  export interface RegisterCreditNoteConversationAction {
    discriminator: 'RegisterCreditNoteConversationAction';
  }
  export interface NoAvailablePowerOfAttorneyConversationAction {
    discriminator: 'NoAvailablePowerOfAttorneyConversationAction';
  }
  export interface PaymentInformationSent {
    discriminator: 'PaymentInformationSent';
  }
  export interface ObjectionRegistered {
    discriminator: 'ObjectionRegistered';
  }
  export interface CreditNoteRegistered {
    discriminator: 'CreditNoteRegistered';
  }
}

export type KnownConversationActions =
  OtherReasonConversationAction |
  SendPaymentInformationConversationAction |
  ConfirmDebtorClaimsAsClosedAction |
  DebtorMadePaymentRecentlyAction |
  DebtorWillSendPaymentConfirmationAction |
  ObjectionConversationAction |
  RegisterCreditNoteConversationAction
;

type ConversationActionBase = Omit<ConversationAction, 'discriminator'>;

export function getConversationActionName(conversationAction: KnownConversationActions | ConversationActionBase) {
  if ('discriminator' in conversationAction) {
    switch (conversationAction.discriminator) {
      case 'SendPaymentInformationConversationAction':
        return 'Send betalingsinformasjon';
      case 'ObjectionConversationAction':
        return 'Har innsigelse';
      case 'RegisterCreditNoteConversationAction': {
        if (conversationAction.creditType == CreditType.Full) {
          return 'Krediter alt utestående';
        }
        if (conversationAction.creditType == CreditType.Fees) {
          return 'Krediter alle gebyrer';
        }
        if (conversationAction.creditType == CreditType.InvoiceFee) {
          return 'Krediter fakturagebyret';
        }
        if (conversationAction.creditType == CreditType.DebtCollectionNoticeFee) {
          return 'Krediter varselsgebyret';
        }
        return 'Ukjent krediteringstype';
      }
      case 'OtherReasonConversationAction':
      case 'ConfirmDebtorClaimsAsClosedAction':
      case 'DebtorMadePaymentRecentlyAction':
      case 'DebtorWillSendPaymentConfirmationAction':
        return 'Notat';
      default: {
        const nope: never = conversationAction;
        console.warn('Ukjent hendelse, kan ikke gi navn: ', nope);
        return 'Ukjent hendelse';
      }
    }
  }
  return 'Ukjent hendelse'
}
export function getConversationActionSummary(conversationAction: KnownConversationActions | ConversationActionBase) {
  if('discriminator' in conversationAction) {
    switch(conversationAction.discriminator) {
      case 'SendPaymentInformationConversationAction': {
        let summary = 'Betalingsinformasjon sendes på ';
        if (conversationAction.mobilePhoneNumbers?.length) {
          summary += 'sms til ' + conversationAction.mobilePhoneNumbers.join(', ') + ' ';
        }
        if (conversationAction.emailAddresses?.length) {
          summary += 'e-post til ' + conversationAction.emailAddresses.join(', ') + ' ';
        }
        if (conversationAction.emailAddresses?.length && conversationAction.additionalText) {
          summary += 'med følgende tilleggsinformasjon: ' + conversationAction.additionalText;
        }
        return summary;
      }
      case 'ObjectionConversationAction':
        return `Registrer innsigelse med notat: ${conversationAction.reason}`;
      case 'OtherReasonConversationAction':
        return conversationAction.text ?? '';
      case 'ConfirmDebtorClaimsAsClosedAction':
        return 'Har betalt. Bekrefter sak avsluttet.';
      case 'DebtorMadePaymentRecentlyAction':
      return 'Betaling ikke mottatt. Betaling foretatt nylig.';
      case 'DebtorWillSendPaymentConfirmationAction':
        return 'Betaling ikke mottatt. Debitor skal sende kvittering.';
      case 'RegisterCreditNoteConversationAction':
        return conversationAction.reason ?? '';
      default: {
        const nope: never = conversationAction;
        console.warn('Ukjent hendelse, kan ikke gi oppsummering: ', nope);
        return '';
      }
    }
  }
  return ''
}
