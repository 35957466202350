import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { ResultOfDebtorIndex } from '../../../api/client';
import { actionCreators as ConversationSupervisorStoreActionCreators } from '../../../store/ConversationSupervisorStore';
import { actionCreators as DebtorClaimsStoreActionCreators } from '../../../store/DebtorClaimsStore';
import { actionCreators as DebtorSearchStoreActionCreators } from '../../../store/DebtorSearchStore';
import CircularProgress from '@mui/material/CircularProgress';
import DebtorSearchResultItem from '../../../components/debtorSearch/presentation/DebtorSearchResultItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface PropsFromState {
  criteria: string;
  isSearching: boolean;
  results?: ResultOfDebtorIndex[];
}

interface PropsFromDispatch {
  resetConversationSupervisor: typeof ConversationSupervisorStoreActionCreators.resetConversationSupervisor;
  resetDebtorClaims: typeof DebtorClaimsStoreActionCreators.resetDebtorClaims;
  selectDebtor: typeof DebtorSearchStoreActionCreators.selectDebtor;
}

type ComponentProps = PropsFromState & PropsFromDispatch;

class DebtorSearchResults extends React.Component<ComponentProps> {
  public render() {
    const noResults = !!this.props.criteria && (!this.props.results || this.props.results!.length < 1);

    return (
      <Box>
        {this.props.isSearching &&
          <Box sx={{
            width: '75px',
            margin: '0 auto',
          }}>
            <CircularProgress size={75} />
          </Box>
        }

        {!this.props.isSearching && noResults &&
          <Box sx={{
            display: 'flex',
          }}>
            <Typography variant="h5" sx={{
              margin: '0 auto'
            }}>Søk etter '{this.props.criteria}' ga ingen treff</Typography>
          </Box>
        }

        {!this.props.isSearching && !noResults &&
          <Box sx={{
            width: '95%',
            margin: '0 auto',
          }} data-id="searchResults">
            {this.props.results!.map((item, i) =>
              <DebtorSearchResultItem
                key={i}
                item={item}
                resetConversationSupervisor={this.props.resetConversationSupervisor}
                resetDebtorClaims={this.props.resetDebtorClaims}
                selectDebtor={this.props.selectDebtor}
              />)
            }
          </Box>
        }
      </Box>
    );
  }
}

const mapStateToProps = ({ debtorSearch }: ApplicationState): PropsFromState => ({
  criteria: debtorSearch.criteria,
  isSearching: debtorSearch.isSearching,
  results: debtorSearch.results ? debtorSearch.results.results : []
});

const mapDispatchToProps: PropsFromDispatch = {
  resetConversationSupervisor: ConversationSupervisorStoreActionCreators.resetConversationSupervisor,
  resetDebtorClaims: DebtorClaimsStoreActionCreators.resetDebtorClaims,
  selectDebtor: DebtorSearchStoreActionCreators.selectDebtor
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(DebtorSearchResults);
