import React from 'react';
import { formatDate } from '../../Utils';
import { PaymentInformationSent } from '../../../api/client';
import Typography from '@mui/material/Typography';

interface Props {
  item: PaymentInformationSent;
}

export default function PaymentInformationSentDetails(props: Props) {
  return (
    <React.Fragment>
      <Typography>
        <b>Mottakere:</b> {props.item.recipients === undefined ? '' : props.item.recipients.join(', ')}
      </Typography>
      <Typography>
        <b>Dato:</b> {formatDate(props.item.eventDate)}
      </Typography>
      <Typography>
        <b>Av:</b> {props.item.registeredBy}
      </Typography>
      <Typography sx={{ whiteSpace: 'pre-line' }}>
        <b>Innhold:</b><br />{props.item.content}
      </Typography>
    </React.Fragment>
  )
};
