import { Reducer } from 'redux';
import { AppThunkAction } from '../store';
import { Conversation, ConversationAction } from '../api/client';
import { ActionTypes as DebtorSearchStoreActionTypes, ClearSearchResultsAction } from '../store/DebtorSearchStore';
import { ActionTypes as NotificationsStoreActionTypes, AddNotificationAction } from '../store/NotificationsStore';
import { getDebtorIdsFromQueryString } from '../components/Utils';
import { history } from '../AppHistory';
import { FetchConfig } from '../store/middlewares/FetchMiddleware';
import { v4 } from 'uuid';
import moment from 'moment';

export enum ActionTypes {
  ADD_CONVERSATION_ACTION = '@@conversationSupervisor/ADD_CONVERSATION_ACTION',
  ADD_SUBMITTED_CONVERSATION = '@@conversationSupervisor/ADD_SUBMITTED_CONVERSATION',
  CANCEL_CONVERSATION_INTERRUPTION = '@@conversationSupervisor/CANCEL_CONVERSATION_INTERRUPTION',
  INTERRUPT_CONVERSATION = '@@conversationSupervisor/INTERRUPT_CONVERSATION',
  REMOVE_CONVERSATION_ACTION = '@@conversationSupervisor/REMOVE_CONVERSATION_ACTION',
  RESET_CONVERSATION_SUPERVISOR = '@@conversationSupervisor/RESET_CONVERSATION_SUPERVISOR',
  SET_SUPERVISOR_STEP = '@@conversationSupervisor/SET_SUPERVISOR_STEP',
  SUBMIT_CONVERSATION = '@@conversationSupervisor/SUBMIT_CONVERSATION',
  SUBMIT_CONVERSATION_REQUEST = '@@conversationSupervisor/SUBMIT_CONVERSATION_REQUEST',
  SUBMIT_CONVERSATION_SUCCESS = '@@conversationSupervisor/SUBMIT_CONVERSATION_SUCCESS',
  SUBMIT_CONVERSATION_FAILURE = '@@conversationSupervisor/SUBMIT_CONVERSATION_FAILURE',
}

export interface SubmittedConversation {
  debtorName: string;
  relatedDebtorIds: string[];
  noAvailablePowerOfAttorney: boolean;
  submittedAt: number;
}

export interface ConversationSupervisorState {
  conversation: Conversation;
  step: number;
  isSubmitting: boolean;
  submittedConversations: SubmittedConversation[];
}

interface AddConversationActionAction { type: ActionTypes.ADD_CONVERSATION_ACTION; action: ConversationAction; }
interface AddSubmittedConversationAction { type: ActionTypes.ADD_SUBMITTED_CONVERSATION; submittedConversation: SubmittedConversation; }
interface CancelConversationInterruption { type: ActionTypes.CANCEL_CONVERSATION_INTERRUPTION; }
interface InterruptConversationAction { type: ActionTypes.INTERRUPT_CONVERSATION; interruptionReason: string; }
interface RemoveConversationActionAction { type: ActionTypes.REMOVE_CONVERSATION_ACTION; index: number; }
interface ResetConversationSupervisorAction { type: ActionTypes.RESET_CONVERSATION_SUPERVISOR; }
interface SetSupervisorStepAction { type: ActionTypes.SET_SUPERVISOR_STEP; step: number; }
interface SubmitConversationAction { type: ActionTypes.SUBMIT_CONVERSATION; fetchConfig: FetchConfig; }
interface SubmitConversationRequestAction { type: ActionTypes.SUBMIT_CONVERSATION_REQUEST; }
interface SubmitConversationSuccessAction { type: ActionTypes.SUBMIT_CONVERSATION_SUCCESS; }
interface SubmitConversationFailureAction { type: ActionTypes.SUBMIT_CONVERSATION_FAILURE; }

export type KnownAction = AddConversationActionAction
  | AddSubmittedConversationAction
  | CancelConversationInterruption
  | InterruptConversationAction
  | RemoveConversationActionAction
  | ResetConversationSupervisorAction
  | SetSupervisorStepAction
  | SubmitConversationAction
  | SubmitConversationRequestAction
  | SubmitConversationSuccessAction
  | SubmitConversationFailureAction
  | ClearSearchResultsAction
  | AddNotificationAction;

export const actionCreators = {
  addConversationAction: (action: ConversationAction): AppThunkAction<KnownAction> => dispatch => {
    dispatch({ type: ActionTypes.ADD_CONVERSATION_ACTION, action });
  },
  cancelConversationInterruption: (): AppThunkAction<KnownAction> => dispatch => {
    dispatch({ type: ActionTypes.CANCEL_CONVERSATION_INTERRUPTION });
  },
  interruptConversation: (interruptionReason: string): AppThunkAction<KnownAction> => dispach => {
    dispach({ type: ActionTypes.INTERRUPT_CONVERSATION, interruptionReason });
  },
  removeConversationAction: (index: number): AppThunkAction<KnownAction> => dispatch => {
    dispatch({ type: ActionTypes.REMOVE_CONVERSATION_ACTION, index });
  },
  resetConversationSupervisor: () => dispatch => {
    dispatch({ type: ActionTypes.RESET_CONVERSATION_SUPERVISOR });
  },
  setSupervisorStep: (step: number) => dispatch => {
    dispatch({ type: ActionTypes.SET_SUPERVISOR_STEP, step });
  },
  submitConversation: (debtorId: string | undefined = undefined, debtorName: string | undefined = undefined, noAvailablePowerOfAttorney: boolean = false):
    AppThunkAction<KnownAction> => (dispatch, getState) => {
      const conversation = getState().conversationSupervisor.conversation;
      conversation.id = v4();
      conversation.debtorId = debtorId ? debtorId : getState().debtorOverview.debtor!.id;

      const submittedConversation: SubmittedConversation = {
        debtorName: debtorName ? debtorName : getState().debtorOverview.debtor!.name!,
        relatedDebtorIds: getDebtorIdsFromQueryString(history.location),
        noAvailablePowerOfAttorney: noAvailablePowerOfAttorney,
        submittedAt: moment().valueOf(),
      };

      return dispatch({
        type: ActionTypes.SUBMIT_CONVERSATION,
        fetchConfig: {
          init: { type: ActionTypes.SUBMIT_CONVERSATION_REQUEST },
          path: `/api/conversations`,
          method: 'POST',
          body: conversation,
          signal: undefined,
          success: (json) => {
            dispatch({ type: ActionTypes.SUBMIT_CONVERSATION_SUCCESS });
            dispatch({ type: ActionTypes.ADD_SUBMITTED_CONVERSATION, submittedConversation: submittedConversation });
            dispatch({ type: DebtorSearchStoreActionTypes.CLEAR_SEARCH_RESULTS });
            dispatch({ type: NotificationsStoreActionTypes.ADD_NOTIFICATION, notification: { message: 'Samtale fullført', variant: 'success' } });
            history.push('/search');
          },
          failure: (error: string) => {
            dispatch({ type: ActionTypes.SUBMIT_CONVERSATION_FAILURE });
            dispatch({ type: NotificationsStoreActionTypes.ADD_NOTIFICATION, notification: { message: 'Kunne ikke fullføre samtalen', variant: 'error' } });
          }
        }
      });
    },
};

export const initialState: ConversationSupervisorState = {
  conversation: { id: '', actions: [], wasInterrupted: false },
  step: 0,
  isSubmitting: false,
  submittedConversations: [],
};

export const reducer: Reducer<ConversationSupervisorState | undefined, KnownAction> = (state: ConversationSupervisorState = initialState, action: KnownAction) => {
  switch (action.type) {
    case ActionTypes.ADD_CONVERSATION_ACTION:
      return { ...state, conversation: { ...state.conversation, actions: [...state.conversation.actions!, action.action] } };
    case ActionTypes.CANCEL_CONVERSATION_INTERRUPTION:
      return { ...state, conversation: { ...state.conversation, wasInterrupted: false, interruptionReason: '' } };
    case ActionTypes.INTERRUPT_CONVERSATION:
      return { ...state, conversation: { ...state.conversation, wasInterrupted: true, interruptionReason: action.interruptionReason } };
    case ActionTypes.REMOVE_CONVERSATION_ACTION:
      return {
        ...state, conversation: {
          ...state.conversation, actions: [
            ...state.conversation.actions!.slice(0, action.index),
            ...state.conversation.actions!.slice(action.index + 1)
          ]
        }
      };
    case ActionTypes.RESET_CONVERSATION_SUPERVISOR:
      return { ...initialState, submittedConversations: [...state.submittedConversations] };
    case ActionTypes.SET_SUPERVISOR_STEP:
      return { ...state, step: action.step };
    case ActionTypes.SUBMIT_CONVERSATION_REQUEST:
      return { ...state, isSubmitting: true };
    case ActionTypes.SUBMIT_CONVERSATION_SUCCESS:
      return { ...initialState, submittedConversations: [...state.submittedConversations] };
    case ActionTypes.SUBMIT_CONVERSATION_FAILURE:
      return { ...state, isSubmitting: false };
    case ActionTypes.ADD_SUBMITTED_CONVERSATION:
      return { ...state, submittedConversations: [...state.submittedConversations.filter(x => x.submittedAt >= moment().startOf('day').valueOf()), action.submittedConversation] };
    default:
      return state;
  }
};