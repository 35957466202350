import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const DigiPostIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="50"
        height="26"
        viewBox="0 0 180 93"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M24.5264 1.2294H0V72.9446H24.5264C46.1794 72.9446 60.6489 57.9869 60.6489 37.087C60.6489 16.1871 46.1794 1.2294 24.5264 1.2294ZM23.7054 61.6751H13.3407V12.4989H23.7054C37.7645 12.4989 47.0003 22.6415 47.0003 37.087C47.0003 51.5325 37.7645 61.6751 23.7054 61.6751Z" fill="#E32D22"/>
        <path d="M80.5125 14.6504C76.4076 14.6504 73.1238 11.372 73.1238 7.37642C73.1238 3.27842 76.4076 0 80.5125 0C84.6173 0 87.9012 3.27842 87.9012 7.37642C87.9012 11.372 84.6173 14.6504 80.5125 14.6504ZM74.15 72.9446V19.2607H86.7723V72.9446H74.15Z" fill="#E32D22"/>
        <path
          d="M139.963 19.2607V29.9155C136.884 22.6415 130.419 18.3386 122.209 18.3386C108.971 18.3386 99.7354 28.9934 99.7354 44.6683C99.7354 60.3432 108.971 70.998 122.209 70.998C129.701
          70.998 135.755 67.4123 139.142 61.3677V67.9245C139.142 78.4769 133.806 82.8823 125.185 82.8823C118.412 82.8823 114.102 80.5259 112.46 74.6863L101.377 78.6818C103.84 87.6974 112.153
          92.3077 126.314 92.3077C141.502 92.3077 151.559 83.8043 151.559 68.2319V19.2607H139.963ZM125.596 61.5726C117.694 61.5726 112.46 55.1182 112.46 44.6683C112.46 34.2184 117.591 27.764
          125.596 27.764C133.806 27.764 138.937 34.1159 138.937 44.6683C138.937 55.2207 133.806 61.5726 125.596 61.5726Z"
          fill="#E32D22"
        />
        <path d="M172.21 14.6504C168.105 14.6504 164.821 11.372 164.821 7.37642C164.821 3.27842 168.105 0 172.21 0C176.315 0 179.598 3.27842 179.598 7.37642C179.598 11.372 176.315 14.6504 172.21 14.6504ZM165.847 72.9446V19.2607H178.47V72.9446H165.847Z" fill="#E32D22"/>
      </svg>
    </SvgIcon>
  );
};