import React from 'react';
import SecurePage from '../../components/authentication/SecurePage';
import { Redirect } from 'react-router-dom';
import { PageProps } from './DefaultPage';

const administratorSecurePageHoc = Page => class AdministratorPage extends React.Component<PageProps> {
  render() {
    if (!this.props.administrator) {
      return <Redirect to="/forbidden" />;
    }

    return <Page {...this.props} />;
  }
};

const secureAdministrationPage = Page => SecurePage(administratorSecurePageHoc(Page));

export default secureAdministrationPage;
