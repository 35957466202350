import { Reducer } from 'redux';
import { AppThunkAction } from '../store';

export enum ActionTypes {
  SET_PATH = '@@menuNavigation/SET_PATH',
}

export interface MenuNavigationState {
  path: string;
}

export interface SetPathAction { type: ActionTypes.SET_PATH; path: string; }

export type KnownAction = SetPathAction;

export const actionCreators = {
  setPath: (path: string): AppThunkAction<KnownAction> => dispatch => {
    dispatch({ type: ActionTypes.SET_PATH, path });
  },
};

export const initialState: MenuNavigationState = { path: '' };

export const reducer: Reducer<MenuNavigationState | undefined, KnownAction> = (state: MenuNavigationState = initialState, action: KnownAction) => {
  switch (action.type) {
    case ActionTypes.SET_PATH:
      return { ...state, path: action.path };
    default:
      return state;
  }
};