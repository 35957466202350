import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { actionCreators as DebtorOverviewStoreActionCreators } from '../../../store/DebtorOverviewStore';
import { getDebtorIdsFromQueryString } from '../../../components/Utils';
import { getTotalOutstanding } from '../../../store/Selectors';
import { DebtorIndex } from '../../../api/client';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Debtor from '../../../components/debtorOverview/presentation/Debtor';

interface PropsFromState {
  debtor: DebtorIndex | null;
  outstanding: number;
  isLoadingDebtor: boolean;
}

interface PropsFromDispatch {
  getDebtor: typeof DebtorOverviewStoreActionCreators.getDebtor;
}

type ComponentProps = PropsFromState & PropsFromDispatch & RouteComponentProps;

class DebtorBanner extends React.Component<ComponentProps> {
  componentDidMount() {
    this.props.getDebtor(getDebtorIdsFromQueryString(this.props.location)[0]);
  }

  public render() {
    return (
      <React.Fragment>
        {this.props.isLoadingDebtor ?
          <CircularProgress sx={{
            margin: '0 auto',
            marginTop: '50px',
            display: 'block',
          }} size={75} /> :
          <Debtor debtor={this.props.debtor} outstanding={this.props.outstanding} />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  debtor: state.debtorOverview.debtor,
  isLoadingDebtor: state.debtorOverview.isLoading,
  outstanding: getTotalOutstanding(state),
});

const mapDispatchToProps: PropsFromDispatch = {
  getDebtor: DebtorOverviewStoreActionCreators.getDebtor,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(DebtorBanner));
