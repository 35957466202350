import React from 'react';
import Box from '@mui/material/Box';
import { colors } from '../../../colors';

const systemInformation = {};
systemInformation['Invoice'] = { systemLetter: 'MMSYS', backgroundColor: colors.secondary.bluelagoon, tooltip: 'MMSYS' };
systemInformation['LeapClaim'] = { systemLetter: 'LEAP', backgroundColor: colors.primary.bondiblue, tooltip: 'Leap' };
systemInformation['LeapDebtCollectionClaim'] = { systemLetter: 'LEAP', backgroundColor: colors.primary.bondiblue, tooltip: 'Leap' };
systemInformation['DebtCollection'] = { systemLetter: 'PRED', backgroundColor: colors.red, tooltip: 'Predator' };

interface Props {
  system: any;
}

export default function DebtorClaimSystemBadge(props: Props) {
  const systemInfo = systemInformation[props.system];

  return (systemInfo && systemInfo.backgroundColor && systemInfo.tooltip) ? (
    <Box sx={{
        float: 'left',
        borderRadius: '2px',
        minWidth: '52px',
        padding: '1px 0px',
        textAlign: 'center',
        marginRight: '5px',
        marginTop: '1px',
        marginBottom: '-1px',
        color: colors.white,
        fontFamily: 'Open Sans,sans-serif',
        fontSize: '12px',
        fontWeight: 700,
        backgroundColor: systemInfo.backgroundColor
      }}
      title={systemInfo.tooltip}
    >
      <span>
        {systemInfo.systemLetter}
      </span>
    </Box>
  ) : null;
};
