import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import arrowLeft from '../../assets/images/icons/arrow-left.svg';

export default function Forbidden() {
  return (
    <Box sx={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      '& h1, & h2': {
        marginBottom: '24px'
      },
    }}>
      <Typography sx={{
        color: 'primary.main',
        fontWeight: 'bold'
      }} variant="h3">
        403 - Oida, denne siden har du ikke tilgang til...
      </Typography>
      <Box sx={{
        width: '75%',
        margin: '0 auto'
      }}>
        <Typography variant="h6">
          Om du mener du skal ha tilgang, ta kontakt med din teamleder.
        </Typography>
        <Link to="/" style={{
          textDecoration: 'none',
          fontWeight: 'bold',
          color: 'primary.dark',
        }}>
          <Box>
            <img src={arrowLeft} alt="icon" style={{
              float: 'left',
              marginRight: '10px'
            }} />
            <Typography variant="h6" sx={{
              textDecoration: 'none',
              fontWeight: 'bold',
              color: 'primary.dark',
            }}>
              Gå til forsiden
            </Typography>
          </Box>
        </Link>
      </Box>
    </Box>
  )
};
