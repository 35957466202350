import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as Store from '.';
import thunk from 'redux-thunk';
import loggerMiddleware from './middlewares/LoggerMiddleware';
import fetchMiddleware from './middlewares/FetchMiddleware';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['conversationSupervisor', 'debtorSearch', 'routing']
};

export default function configureStore(initialState?: Store.ApplicationState) {
  const rootReducer = buildRootReducer(Store.reducers);
  const composeEnhancers = composeWithDevTools({});
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk, fetchMiddleware, loggerMiddleware)
    ));

  const persistor = persistStore(store);

  // Enable Webpack hot module replacement for reducers
  if (module.hot) {
    module.hot.accept('.', () => {
      const nextStore = require<typeof Store>('.');
      const nextReducers = buildRootReducer(nextStore.reducers);
      const nextPersistedReducers = persistReducer(persistConfig, nextReducers);
      store.replaceReducer(nextPersistedReducers);
    });
  }

  return { store, persistor };
}

function buildRootReducer(allReducers: any) {
  return combineReducers<Store.ApplicationState>(Object.assign({}, allReducers));
}
