import { Reducer } from 'redux';
import { AppThunkAction } from '../store';

export enum ActionTypes {
  ADD_NOTIFICATION = '@@notifications/ADD_NOTIFICATION',
  REMOVE_NOTIFICATION = '@@notifications/REMOVE_NOTIFICATION',
  CLEAR_NOTIFICATIONS = '@@notifications/CLEAR_NOTIFICATIONS',
}

export interface NotificationsState {
  messages: Notification[];
}

type NotificationVariant = 'error' | 'info' | 'success' | 'warning';

export interface Notification {
  message: string;
  variant: NotificationVariant;
}

export interface AddNotificationAction { type: ActionTypes.ADD_NOTIFICATION; notification: Notification; }
export interface RemoveNotificationAction { type: ActionTypes.REMOVE_NOTIFICATION; }
export interface ClearNotificationsAction { type: ActionTypes.CLEAR_NOTIFICATIONS; }

export type KnownAction = AddNotificationAction | RemoveNotificationAction | ClearNotificationsAction;

export const actionCreators = {
  addNotification: (notification: Notification): AppThunkAction<KnownAction> => dispatch => {
    dispatch({ type: ActionTypes.ADD_NOTIFICATION, notification });
  },
  removeNotification: (): AppThunkAction<KnownAction> => dispatch => {
    dispatch({ type: ActionTypes.REMOVE_NOTIFICATION });
  },
  clearNotifications: (): AppThunkAction<KnownAction> => (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_NOTIFICATIONS });
  }
};

export const initialState: NotificationsState = { messages: [] };

export const reducer: Reducer<NotificationsState | undefined, KnownAction> = (state: NotificationsState = initialState, action: KnownAction) => {
  switch (action.type) {
    case ActionTypes.ADD_NOTIFICATION:
      return { ...state, messages: state.messages.concat(action.notification) };
    case ActionTypes.REMOVE_NOTIFICATION:
      return {
        ...state, messages: [
          ...state.messages.slice(0, 0),
          ...state.messages.slice(1)
        ]
      };
    case ActionTypes.CLEAR_NOTIFICATIONS:
      return { ...state, messages: [] };
    default:
      return state;
  }
};
