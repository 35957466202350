import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { ClaimEventType } from '../../../api/client';
import DebtorClaimEventIcon from '../../debtorClaimEvents/presentation/DebtorClaimEventIcon';
import DebtorClaimEventDetails from './DebtorClaimEventDetails';
import TooltipContent from '../presentation/TooltipContent';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { actionCreators as DebtorClaimDownloadStoreActionCreators } from '../../../store/DebtorClaimDownloadStore';
import { colors } from './../../../colors';

interface PropsFromDispatch {
  downloadDebtorClaimPDF: typeof DebtorClaimDownloadStoreActionCreators.downloadDebtorClaimPDF;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.marble,
    color: colors.black,
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    fontSize: '0.875rem',
    fontWeight: '500',
    maxWidth: '600px',
    marginTop: '4px',
    padding: '10px'
  },
}));

interface InjectedProps {
  debtorId: string;
  eventId: string;
  eventType: ClaimEventType;
  eventTooltip: { [key: string]: string; } | undefined;
}

type ComponentProps = PropsFromDispatch & InjectedProps;

class DebtorClaimEventDetailsTooltip extends React.Component<ComponentProps> {

  downloadClaimPDF = () => {
    this.props.downloadDebtorClaimPDF(this.props.debtorId, this.props.eventId, this.props.eventType);
  }

  public render() {

    const disableHover = (
      [
        ClaimEventType.PaymentInformationSentBySms,
        ClaimEventType.PaymentInformationSentByEmail,
        ClaimEventType.ObjectionRegistered
      ].indexOf(this.props.eventType!) === -1

      && !this.props.eventTooltip
    );

    const disableDownload = (
      [
        ClaimEventType.Letter,
        ClaimEventType.DebtCollectionWarningLetter,
        ClaimEventType.InvoiceSmsReceived,
        ClaimEventType.InvoiceSmsNotReceived,
        ClaimEventType.DigiPostInvoice,
        ClaimEventType.DigiPostDebtCollectionWarning,
      ].indexOf(this.props.eventType!) === -1
    );

    return (
      <HtmlTooltip
        title={
          this.props.eventTooltip && this.props.eventTooltip !== undefined ?
          Object.keys(this.props.eventTooltip!).map((key) => (<TooltipContent key={key} header={key} content={this.props.eventTooltip![key]} /> )) :
          <DebtorClaimEventDetails id={this.props.eventId} />
        }
        disableHoverListener={disableHover}
        placement="bottom-start"
        enterDelay={100}
        leaveDelay={200}
        PopperProps={{
          style: {
            opacity: 1
          }
        }}
      >
        <Box onClick={!disableDownload ? this.downloadClaimPDF : undefined} sx={{
          display: 'inline',
          ...(!disableDownload === true && { cursor: 'pointer' })
        }}>
          <DebtorClaimEventIcon eventType={this.props.eventType} />
        </Box>
      </HtmlTooltip>
    );
  }
}

const mapDispatchToProps: PropsFromDispatch = {
  downloadDebtorClaimPDF: DebtorClaimDownloadStoreActionCreators.downloadDebtorClaimPDF,
};

export default compose(
  connect(null, mapDispatchToProps)
)(DebtorClaimEventDetailsTooltip);

        /*
            backgroundColor: '#0f0',//colors.marble
            color: 'primary.dark',
            boxShadow: '#f00',//colors.shadow
            fontSize: '0.875rem',
            fontWeight: '500',
            maxWidth: '600px',
            marginTop: '4px',
            padding: '10px'
        */