import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Logo from '../../components/logo/Logo';
import ApplicationVersion from '../../components/applicationBar/ApplicationVersion';
import ApplicationBarMenu from '../../components/applicationBar/ApplicationBarMenu';

interface Props {
  userProfile?: string;
  defaultPath: string;
}

export default function ApplicationBar(props: Props) {
  return (
    <AppBar position="static" sx={{
      backgroundImage: (theme) => `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`
    }}>
      <Toolbar sx={{
        height: '88px'
      }}>
        <Box sx={{
          paddingLeft: '144px',
        }}>
          <Logo defaultPath={props.defaultPath} />
        </Box>
        <Box sx={{
          paddingLeft: '144px',
        }}>
          <ApplicationBarMenu />
        </Box>
        <Typography sx={{
          marginLeft: 'auto',
          fontSize: '24px',
          color: 'secondary.main'
        }}>
          {props.userProfile}
        </Typography>
        <Box sx={{
          position: 'absolute',
          top: '0px',
          right: '24px'
        }}>
          <ApplicationVersion />
        </Box>
      </Toolbar>
    </AppBar>
  )
};
