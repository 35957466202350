import React, { useCallback, useEffect } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { authenticatedAsAdministrator, authenticatedAsCustomerCareWorker, authenticatedAsTerminalWorker } from '../../store/AuthenticationStore';
import { actionCreators as MenuNavigationActionCreators } from '../../store/MenuNavigation';

export interface PageProps {
  isAuthenticated: boolean;
  roles: string[];
  administrator: boolean;
  customerCareWorker: boolean;
  terminalWorker: boolean;
}

interface PropsFromDispatch {
  setMenuPath: typeof MenuNavigationActionCreators.setPath;
}

type ComponentProps = PageProps & PropsFromDispatch;

const defaultPageHoc = Page => (props: ComponentProps) => {
  const updateMenuPath = useCallback((path: string) => {
    switch (path) {
      case '/search':
      case '/debtor':
      case '/callcenter/incoming':
        props.setMenuPath('search');
        break;
      case '/terminal':
        props.setMenuPath('terminal');
        break;
      case '/administration':
        props.setMenuPath('administration');
        break;
      default:
        props.setMenuPath('');
        break;
    }
  }, [props]);

  useEffect(() => {
    updateMenuPath(window.location.pathname);
  }, [updateMenuPath]);

  return (
    <Page {...props} />
  );
};

const mapStateToProps = ({ authentication }: ApplicationState): PageProps => ({
  isAuthenticated: authentication.isAuthenticated,
  roles: authentication.roles,
  administrator: authenticatedAsAdministrator(authentication),
  customerCareWorker: authenticatedAsCustomerCareWorker(authentication),
  terminalWorker: authenticatedAsTerminalWorker(authentication),
});

const mapDispatchToProps: PropsFromDispatch = {
  setMenuPath: MenuNavigationActionCreators.setPath
};

const connectedDefaultPage = Page => compose(connect(mapStateToProps, mapDispatchToProps))(defaultPageHoc(Page));

export default connectedDefaultPage;
