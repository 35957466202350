import React from 'react';
import Box from '@mui/material/Box';
import { DebtorClaim } from '../../../api/client';
import DebtorClaimComponent from '../../debtorOverview/containers/DebtorClaim';

interface Props {
  childClaims: DebtorClaim [];
}

export default function DebtorClaimChildren(props: Props) {
  return (
    <Box sx={{
      width: '90%',
      margin: '0 auto',
      marginBottom: '16px',
      paddingBottom: 0
    }}>
      {props.childClaims && props.childClaims.map((item, i) => <DebtorClaimComponent key={i} debtorClaim={item} isTopLevel={false} />)}
    </Box>
  )
};
