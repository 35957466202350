import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import { actionCreators } from '../../../../store/ConversationSupervisorStore';
import { ConversationAction } from '../../../../api/client';
import { Step } from '../ConversationSupervisorStep';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import StepZilla from 'react-stepzilla';
import { getConversationActionSummary } from '../../../../api/actions';

interface PropsFromState {
  actions?: ConversationAction[];
}

interface PropsFromDispatch {
  interruptConversation: typeof actionCreators.interruptConversation;
}

interface InjectedProps {
  jumpToStep?: typeof StepZilla.jumpToStep;
}

interface State {
  reason: string;
  reasonFreeText: string;
}

type ComponentProps = PropsFromState & PropsFromDispatch & InjectedProps;

export class ConversationInterrupted extends React.Component<ComponentProps, State> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      reason: '',
      reasonFreeText: '',
    };
  }

  getInterruptionReason = (reason: string, reasonFreeText: string, actions?: ConversationAction[]) => {
    let summary = `Samtale avbrutt pga. ${reason === 'annet' ? '' : reason} ${reasonFreeText}. `;

    if (actions!.length > 0) {
      summary += 'Ble foreløpig enige om følgende: ';
      actions!.forEach((item) => {
        summary += getConversationActionSummary(item);
      });
    }

    return summary;
  }

  isValid = () => {
    if (this.state.reason === 'annet' && !this.state.reasonFreeText.trim()) {
      return false;
    }
    return this.state.reason;
  }

  handleReasonChange = event => {
    this.setState({ reason: event.target.value });
  }

  handleReasonFreeTextChange = event => {
    this.setState({ reasonFreeText: event.target.value });
  }

  handleSubmit = event => {
    event.preventDefault();
    const interruptionReason = this.getInterruptionReason(this.state.reason, this.state.reasonFreeText, this.props.actions);
    this.props.interruptConversation(interruptionReason);
    this.props.jumpToStep(Step.Summary);
  }

  public render() {
    return (
      <FormControl sx={{
        width: '100%',
      }}>
        <Box sx={{
          paddingTop: '10px',
        }}>
          <RadioGroup
            aria-label="Gender"
            name="conversationInterruptedReason"
            value={this.state.reason}
            onChange={this.handleReasonChange}
          >
            <FormControlLabel value="feil debitor" control={<Radio color="primary" />} label="Feil debitor" />
            <FormControlLabel value="uten fullmakt" control={<Radio color="primary" />} label="Uten fullmakt" />
            <FormControlLabel value="la på/avsluttet" control={<Radio color="primary" />} label="La på/avsluttet" />
            <FormControlLabel value="brutt" control={<Radio color="primary" />} label="Brutt" />
            <FormControlLabel value="annet" control={<Radio color="primary" />} label="Annet" />
          </RadioGroup>
          {this.state.reason === 'annet' &&
            <TextField
              label="Oppgi grunn"
              autoFocus={true}
              required={true}
              error={!this.isValid()}
              multiline={true}
              maxRows={20}
              margin="normal"
              sx={{
                minWidth: '100%',
              }}
              value={this.state.reasonFreeText}
              onChange={this.handleReasonFreeTextChange}
            />
          }

        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
          <Button variant="contained" onClick={() => this.props.jumpToStep(Step.Start)}>
            Avbryt
          </Button>
          <Button variant="contained" id={'submit'} onClick={this.handleSubmit} disabled={!this.isValid()} type="submit">
            OK
          </Button>
        </Box>
      </FormControl>
    );
  }
}

const mapStateToPros = ({ conversationSupervisor }: ApplicationState): PropsFromState => ({
  actions: conversationSupervisor.conversation.actions,
});

const mapDispatchToProps: PropsFromDispatch = {
  interruptConversation: actionCreators.interruptConversation,
};

export default compose(
  connect(mapStateToPros, mapDispatchToProps)
)(ConversationInterrupted);
