import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DebtorIndex } from '../../../api/client';
import { formatNationalIdentityNumber, formatPhoneNumber } from '../../Utils';
import { DebtOverview } from '../../debtOverview/DebtOverview';
import currencyFormatter from 'currency-formatter';

interface Props {
  debtor: DebtorIndex | null;
  outstanding: number;
}

export default function Debtor(props: Props) {
  return props.debtor ? (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '1000px',
      minWidth: '700px',
      margin: '0 auto',
    }}>
      <Box sx={{
        marginBottom: '14px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
      }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', marginRight: '30px' }}>
          {props.debtor.name}
        </Typography>
        <Typography variant="h5" sx={{ textAlign: 'right', minWidth: '160px' }}>
          {formatNationalIdentityNumber(props.debtor.nationalIdentityNumber) || props.debtor.dateOfBirth}
        </Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'end',
        marginBottom: '20px'
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Typography>
            {props.debtor.street}
          </Typography>
          <Typography>
            {props.debtor.postCode} {props.debtor.postArea}
          </Typography>
          <Typography>
            {props.debtor.country}
          </Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'right'
        }}>
          {props.debtor.phoneNumbers && [...new Set(props.debtor.phoneNumbers)].map((item, i) => <Typography key={i}>{formatPhoneNumber(item)}</Typography>)}
          <Typography>
            {props.debtor.email}
          </Typography>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'end',
        marginBottom: '20px'
      }}>
        <DebtOverview />
        <Typography variant="h5" style={{ float: 'right' }}>
          Totalt utestående: <Box sx={{
            display: 'inline',
            ...(props.outstanding === 0 && {
              color: 'secondary.dark'
            }), ...(props.outstanding !== 0 && {
              color: 'error.main',
              fontWeight: 'bold'
            }),
          }}
          >
            {currencyFormatter.format(props.outstanding, { code: 'NOK' })}
          </Box>
        </Typography>
      </Box>
    </Box>
  )
  : (
    <Box sx={{
      extend: 'root',
      textAlign: 'center',
    }}>
      <Typography variant="h5">
        Kunne ikke hente debitor data
      </Typography>
      <DebtOverview />
    </Box>
  )
};
