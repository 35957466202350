import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface Props {
  creditorName: any;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[5],
    fontSize: '13px',
    fontWeight: 500,
    maxWidth: '600px',
    marginTop: '-10px'
  },
}));

export default function CreditorName(props: Props) {
  return (
    <LightTooltip
      title={props.creditorName ? props.creditorName : ''}
      enterDelay={600}
      leaveDelay={300}
      placement="bottom-start"
    >
      <Typography variant='body2' sx={{
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}>
        {props.creditorName}
      </Typography>
    </LightTooltip>
  );
};
