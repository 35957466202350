import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Conversation } from '../../../api/client';
import { actionCreators } from '../../../store/ConversationSupervisorStore';
import ConversationSummaryList from '../../conversationSupervisor/components/ConversationSummaryList';
import StepZilla from 'react-stepzilla';

interface PropsFromState {
  conversation: Conversation;
}

interface PropsFromDispatch {
  cancelConversationInterruption: typeof actionCreators.cancelConversationInterruption;
  removeConversationAction: typeof actionCreators.removeConversationAction;
  submitConversation: typeof actionCreators.submitConversation;
}

interface InjectedProps {
  jumpToStep?: typeof StepZilla.jumpToStep;
}

type ComponentProps = PropsFromState & PropsFromDispatch & InjectedProps;

class ConversationSummary extends React.Component<ComponentProps> {
  public render() {
    return (
      <ConversationSummaryList
        conversation={this.props.conversation}
        cancelConversationInterruption={this.props.cancelConversationInterruption}
        jumpToStep={this.props.jumpToStep}
        removeConversationAction={this.props.removeConversationAction}
        submitConversation={this.props.submitConversation}
      />
    );
  }
}

const mapStateToProps = ({ conversationSupervisor }: ApplicationState): PropsFromState => ({
  conversation: conversationSupervisor.conversation,
});

const mapDispatchToProps: PropsFromDispatch = {
  cancelConversationInterruption: actionCreators.cancelConversationInterruption,
  removeConversationAction: actionCreators.removeConversationAction,
  submitConversation: actionCreators.submitConversation,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ConversationSummary);
