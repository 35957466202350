import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/ConversationSupervisorStore';
import { colors } from '../../colors';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as H from 'history';
import ConversationSupervisor from '../../components/conversationSupervisor/components/ConversationSupervisor';
import DebtorBanner from '../../components/debtorOverview/containers/DebtorBanner';
import DebtorSelectedPowerOfAttorneyBanner from '../../components/debtorOverview/containers/DebtorSelectedPowerOfAttorneyBanner';
import DebtorClaimsList from '../../components/debtorOverview/containers/DebtorClaimsList';
import DebtorLog from '../../components/debtorLog/containers/DebtorLog';
import DebtorLogHeader from '../../components/debtorLog/presentation/DebtorLogHeader';
import customerCareWorkerPage from '../../components/authentication/CustomerCareWorkerPage';
import SubmittedConversationsDrawer from '../../components/submittedConversations/containers/SubmittedConversationsDrawer';
import { actionCreators as DebtorNationalIdentificationNumberSearchActionCreators } from '../../store/DebtorNationalIdentificationNumberSearchStore';
import Box from '@mui/material/Box';

interface PropsFromDispatch {
  resetConversationSupervisor: typeof actionCreators.resetConversationSupervisor;
  clearSearchDebtorsByNationalIdentificationNumberResults: typeof DebtorNationalIdentificationNumberSearchActionCreators.clearSearchDebtorsByNationalIdentificationNumberResults;
}

interface State {
  key?: H.LocationKey;
}

type ComponentProps = PropsFromDispatch & RouteComponentProps;

class DebtorOverview extends React.Component<ComponentProps, State> {
  state = {
      key: undefined,
  };

  componentDidMount() {
    this.props.clearSearchDebtorsByNationalIdentificationNumberResults();
  }

  componentDidUpdate(prevProps: ComponentProps) {
    if (prevProps.location.key !== this.props.location.key) {
      this.setState({ key: this.props.location.key });
      this.props.resetConversationSupervisor();
    }
  }

  public render() {
    return (
      <React.Fragment>
        <React.Fragment>
          <DebtorSelectedPowerOfAttorneyBanner key={this.state.key} />
        </React.Fragment>
        <Box sx={{
          display: 'grid',
          gridTemplateColumns: '400px 1fr 520px',
          gridTemplateRows: '220px 1px 1fr',
          backgroundColor: 'background.default',
          position: 'relative',
        }}>
          <Box sx={{
            gridColumnStart: '2',
            padding: '20px',
            width: '100%',
            boxSizing: 'border-box'
          }}>
            <DebtorBanner key={this.state.key} />
          </Box>
          <Box sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.10)',
            boxShadow: '0 -1px 1px 0 rgba(0, 0, 0, 0.30)',
            gridRowStart: 2,
            gridColumnStart: 1,
            gridColumnEnd: 4
          }} />
          <Box sx={{
            gridColumnStart: '1',
            gridRowStart: '1',
            display: 'flex',
            flexDirection: 'column-reverse',
          }}>
            <DebtorLogHeader/>
          </Box>
          <Box sx={{
            gridColumnStart: '1',
            gridRowStart: '3',
          }}>
            <DebtorLog key={this.state.key} />
          </Box>
          <Box sx={{
            gridColumnStart: '2',
            gridRowStart: '3',
            padding: '20px',
            backgroundColor: colors.lightGray,
            minHeight: '55vh',
          }}>
            <DebtorClaimsList key={this.state.key} />
          </Box>
          <Box sx={{
            gridColumnStart: '3',
            gridRowStart: '1',
            gridRowEnd: '3',
            position: 'sticky',
            top: '0px',
            left: '20px',
            paddingRight: '10px',
            paddingTop: '67px',
            minHeight: '442px',
          }} >
            <ConversationSupervisor key={this.state.key} />
          </Box>
          <Box sx={{
            gridColumnStart: '3',
            gridRowStart: '3',
            position: 'fixed',
            bottom: '0px',
            right: '10px',
          }}>
            <SubmittedConversationsDrawer />
          </Box>
          <Box sx={{
            gridColumnStart: '3',
            gridRowStart: '3',
            backgroundColor: colors.lightGray
          }} />
        </Box>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps: PropsFromDispatch = {
  resetConversationSupervisor: actionCreators.resetConversationSupervisor,
  clearSearchDebtorsByNationalIdentificationNumberResults: DebtorNationalIdentificationNumberSearchActionCreators.clearSearchDebtorsByNationalIdentificationNumberResults
};

export default compose(
  connect(null, mapDispatchToProps)
)(customerCareWorkerPage(withRouter(DebtorOverview)));
