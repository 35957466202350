import { Reducer } from 'redux';
import { AppThunkAction } from '../store';
import { DebtorClaimEvent, SelectedDebtorClaim } from '../api/client';
import { FetchConfig } from './middlewares/FetchMiddleware';
import { ActionTypes as NotificationsStoreActionTypes, AddNotificationAction } from './NotificationsStore';

export enum ActionTypes {
  TOGGLE_DEBTOR_CLAIM_SELECTION = '@@debtorClaims/TOGGLE_DEBTOR_CLAIM_SELECTION',
  CLEAR_DEBTOR_CLAIMS_SELECTION = '@@debtorClaims/CLEAR_DEBTOR_CLAIMS_SELECTION',
  GET_DEBTOR_CLAIM_EVENT_DETAILS = '@@debtorClaims/GET_DEBTOR_CLAIM_EVENT_DETAILS',
  GET_DEBTOR_CLAIM_EVENT_DETAILS_REQUEST = '@@debtorClaims/GET_DEBTOR_CLAIM_EVENT_DETAILS_REQUEST',
  GET_DEBTOR_CLAIM_EVENT_DETAILS_SUCCESS = '@@debtorClaims/GET_DEBTOR_CLAIM_EVENT_DETAILS_SUCCESS',
  GET_DEBTOR_CLAIM_EVENT_DETAILS_FAILURE = '@@debtorClaims/GET_DEBTOR_CLAIM_EVENT_DETAILS_FAILURE',
  RESET_DEBTOR_CLAIMS = '@@debtorClaims/RESET_DEBTOR_CLAIMS'
}

export interface FetchedDebtorClaimEventDetails {
  [id: string]: DebtorClaimEvent;
}

export interface DebtorClaimsState {
  selectedDebtorClaims: SelectedDebtorClaim[];
  debtorClaimEventDetails: FetchedDebtorClaimEventDetails;
  isLoadingDebtorClaimEventDetails: boolean;
}

interface ToggleDebtorClaimSelectionAction { type: ActionTypes.TOGGLE_DEBTOR_CLAIM_SELECTION; debtorClaim: SelectedDebtorClaim; }
interface ClearDebtorClaimsSelectionAction { type: ActionTypes.CLEAR_DEBTOR_CLAIMS_SELECTION; }
interface GetDebtorClaimEventDetailsAction { type: ActionTypes.GET_DEBTOR_CLAIM_EVENT_DETAILS; fetchConfig: FetchConfig; }
interface GetDebtorClaimEventDetailsRequestAction { type: ActionTypes.GET_DEBTOR_CLAIM_EVENT_DETAILS_REQUEST; id: string; }
interface GetDebtorClaimEventDetailsSuccessAction { type: ActionTypes.GET_DEBTOR_CLAIM_EVENT_DETAILS_SUCCESS; id: string; debtorClaimEventDetail: DebtorClaimEvent; }
interface GetDebtorClaimEventDetailsFailureAction { type: ActionTypes.GET_DEBTOR_CLAIM_EVENT_DETAILS_FAILURE; id: string; }
interface ResetDebtorClaimsAction { type: ActionTypes.RESET_DEBTOR_CLAIMS; }

export type KnownAction = ToggleDebtorClaimSelectionAction
  | ClearDebtorClaimsSelectionAction
  | GetDebtorClaimEventDetailsAction
  | GetDebtorClaimEventDetailsRequestAction
  | GetDebtorClaimEventDetailsSuccessAction
  | GetDebtorClaimEventDetailsFailureAction
  | ResetDebtorClaimsAction
  | AddNotificationAction;

export const actionCreators = {
  toggleDebtorClaimSelection: (debtorClaim: SelectedDebtorClaim): AppThunkAction<KnownAction> => dispatch => {
    dispatch({ type: ActionTypes.TOGGLE_DEBTOR_CLAIM_SELECTION, debtorClaim });
  },
  clearDebtorClaimsSelection: (): AppThunkAction<KnownAction> => dispatch => {
    dispatch({ type: ActionTypes.CLEAR_DEBTOR_CLAIMS_SELECTION });
  },
  resetDebtorClaims: (): AppThunkAction<KnownAction> => dispatch => {
    dispatch({ type: ActionTypes.RESET_DEBTOR_CLAIMS});
  },
  getDebtorClaimEventDetails: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
    if (getState().debtorClaims.debtorClaimEventDetails[id]) {
      return getState().debtorClaims.debtorClaimEventDetails[id];
    }

    return dispatch({
      type: ActionTypes.GET_DEBTOR_CLAIM_EVENT_DETAILS,
      fetchConfig: {
        init: { type: ActionTypes.GET_DEBTOR_CLAIM_EVENT_DETAILS_REQUEST, id },
        path: `/api/debtorclaimevents/${id}/details`,
        signal: undefined,
        success: (json) => {
          dispatch({ type: ActionTypes.GET_DEBTOR_CLAIM_EVENT_DETAILS_SUCCESS, id: id, debtorClaimEventDetail: json });
        },
        failure: (error: string) => {
          dispatch({ type: ActionTypes.GET_DEBTOR_CLAIM_EVENT_DETAILS_FAILURE, id });
          dispatch({ type: NotificationsStoreActionTypes.ADD_NOTIFICATION, notification: { message: `Kunne ikke hente detaljer for hendelse ${id}`, variant: 'error' } });
        }
      }
    });
  },
};

export const initialState: DebtorClaimsState = { selectedDebtorClaims: [], debtorClaimEventDetails: {}, isLoadingDebtorClaimEventDetails: false };

export const reducer: Reducer<DebtorClaimsState | undefined, KnownAction> = (state: DebtorClaimsState = initialState, action: KnownAction) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_DEBTOR_CLAIM_SELECTION:
      const index = state.selectedDebtorClaims.map(x => x.number).indexOf(action.debtorClaim.number);
      if (index !== -1) {
        return {
          ...state, selectedDebtorClaims: [
            ...state.selectedDebtorClaims.slice(0, index),
            ...state.selectedDebtorClaims.slice(index + 1)
          ]
        };
      }
      else {
        return { ...state, selectedDebtorClaims: [...state.selectedDebtorClaims, action.debtorClaim] };
      }
    case ActionTypes.CLEAR_DEBTOR_CLAIMS_SELECTION:
      return { ...state, selectedDebtorClaims: [] };
    case ActionTypes.GET_DEBTOR_CLAIM_EVENT_DETAILS_REQUEST:
      return { ...state, isLoadingDebtorClaimEventDetails: true };
    case ActionTypes.GET_DEBTOR_CLAIM_EVENT_DETAILS_SUCCESS:
      return { ...state, debtorClaimEventDetails: { ...state.debtorClaimEventDetails, [action.id]: action.debtorClaimEventDetail }, isLoadingDebtorClaimEventDetails: false};
    case ActionTypes.GET_DEBTOR_CLAIM_EVENT_DETAILS_FAILURE:
      return { ...state, isLoadingDebtorClaimEventDetails: false };
    case ActionTypes.RESET_DEBTOR_CLAIMS:
      return initialState;
    default:
      return state;
  }
};