import { Reducer } from 'redux';
import { AppThunkAction } from '../store';
import { ResponseOfDebtorIndex } from '../api/client';
import { ActionTypes as NotificationsStoreActionTypes, AddNotificationAction } from './NotificationsStore';
import { FetchConfig } from './middlewares/FetchMiddleware';

export enum ActionTypes {
  GET_DEBTOR_BY_NATIONAL_IDENTIFICATION_NUMBER = '@@debtorNationalIdentificationNumberSearch/GET_DEBTOR_BY_NATIONAL_IDENTIFICATION_NUMBER',
  GET_DEBTOR_BY_NATIONAL_IDENTIFICATION_NUMBER_REQUEST = '@@debtorNationalIdentificationNumberSearch/GET_DEBTOR_BY_NATIONAL_IDENTIFICATION_NUMBER_REQUEST',
  GET_DEBTOR_BY_NATIONAL_IDENTIFICATION_NUMBER_SUCCESS = '@@debtorNationalIdentificationNumberSearch/GET_DEBTOR_BY_NATIONAL_IDENTIFICATION_NUMBER_SUCCESS',
  GET_DEBTOR_BY_NATIONAL_IDENTIFICATION_NUMBER_FAILURE = '@@debtorNationalIdentificationNumberSearch/GET_DEBTOR_BY_NATIONAL_IDENTIFICATION_NUMBER_FAILURE',
  CLEAR_SEARCH_BY_NATIONAL_IDENTIFICATION_NUMBER_RESULTS = '@@debtorNationalIdentificationNumberSearch/CLEAR_SEARCH_BY_NATIONAL_IDENTIFICATION_NUMBER_RESULTS',
  SET_VALID_SEARCH = '@@debtorNationalIdentificationNumberSearch/SET_VALID_SEARCH'
}

export interface DebtorNationalIdentificationNumberSearchState {
  criteria: string;
  isSearching: boolean;
  searchComplete: boolean;
  validSearch?: boolean | null;
  results: ResponseOfDebtorIndex | null;
}

interface GetDebtorsAction { type: ActionTypes.GET_DEBTOR_BY_NATIONAL_IDENTIFICATION_NUMBER; fetchConfig: FetchConfig; }
interface GetDebtorsRequestAction { type: ActionTypes.GET_DEBTOR_BY_NATIONAL_IDENTIFICATION_NUMBER_REQUEST; criteria: string; }
interface GetDebtorsSuccessAction { type: ActionTypes.GET_DEBTOR_BY_NATIONAL_IDENTIFICATION_NUMBER_SUCCESS; response: ResponseOfDebtorIndex | null; }
interface GetDebtorsFailureAction { type: ActionTypes.GET_DEBTOR_BY_NATIONAL_IDENTIFICATION_NUMBER_FAILURE; response: string; }
interface SetValidSearchAction { type: ActionTypes.SET_VALID_SEARCH; validSearch: boolean; }
export interface ClearSearchResultsAction { type: ActionTypes.CLEAR_SEARCH_BY_NATIONAL_IDENTIFICATION_NUMBER_RESULTS; }

export type KnownAction = GetDebtorsAction
  | GetDebtorsRequestAction
  | GetDebtorsSuccessAction
  | GetDebtorsFailureAction
  | ClearSearchResultsAction
  | SetValidSearchAction
  | AddNotificationAction;

export const actionCreators = {
  searchDebtorsByNationalIdentificationNumber: (criteria: string): AppThunkAction<KnownAction> => dispatch => {
    return dispatch({
      type: ActionTypes.GET_DEBTOR_BY_NATIONAL_IDENTIFICATION_NUMBER,
      fetchConfig: {
        init: { type: ActionTypes.GET_DEBTOR_BY_NATIONAL_IDENTIFICATION_NUMBER_REQUEST, criteria },
        path: `/api/Debtors/${criteria}/nationalIdentityNumber`,
        signal: undefined,
        success: (json) => {
          dispatch({ type: ActionTypes.GET_DEBTOR_BY_NATIONAL_IDENTIFICATION_NUMBER_SUCCESS, response: json });
        },
        failure: (error: string) => {
          dispatch({ type: ActionTypes.GET_DEBTOR_BY_NATIONAL_IDENTIFICATION_NUMBER_FAILURE, response: error });
          dispatch({ type: NotificationsStoreActionTypes.ADD_NOTIFICATION, notification: { message: 'Kunne ikke utføre søket', variant: 'error' } });
        }
      }
    });
  },
  clearSearchDebtorsByNationalIdentificationNumberResults: (): AppThunkAction<KnownAction> => dispatch => {
    dispatch({ type: ActionTypes.CLEAR_SEARCH_BY_NATIONAL_IDENTIFICATION_NUMBER_RESULTS });
  },
  setValidSearch: (validSearch: boolean): AppThunkAction<KnownAction> => dispatch => {
    dispatch({ type: ActionTypes.SET_VALID_SEARCH, validSearch });
  }
};

export const initialState: DebtorNationalIdentificationNumberSearchState = { criteria: '', isSearching: false, searchComplete: false, results: null, validSearch: null };

export const reducer: Reducer<DebtorNationalIdentificationNumberSearchState | undefined, KnownAction> = (state: DebtorNationalIdentificationNumberSearchState = initialState, action: KnownAction) => {
  switch (action.type) {
    case ActionTypes.GET_DEBTOR_BY_NATIONAL_IDENTIFICATION_NUMBER_REQUEST:
      return { ...state, criteria: action.criteria, isSearching: true };
    case ActionTypes.GET_DEBTOR_BY_NATIONAL_IDENTIFICATION_NUMBER_SUCCESS:
      return { ...state, isSearching: false, searchComplete: true, results: action.response };
    case ActionTypes.GET_DEBTOR_BY_NATIONAL_IDENTIFICATION_NUMBER_FAILURE:
      return { ...state, isSearching: false, searchComplete: true };
    case ActionTypes.SET_VALID_SEARCH:
      return { ...state, validSearch: action.validSearch };
    case ActionTypes.CLEAR_SEARCH_BY_NATIONAL_IDENTIFICATION_NUMBER_RESULTS:
      return initialState;
    default:
      return state;
  }
};