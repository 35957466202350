import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { actionCreators as DebtorClaimsStoreActionCreators } from '../../../../store/DebtorClaimsStore';
import { DebtorClaim } from '../../../../api/client';
import { hasSelectedDebtorClaims, getOutstandingDebtorClaims } from '../../../../store/Selectors';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Step } from '../ConversationSupervisorStep';
import StepZilla from 'react-stepzilla';
import ArrowRight from '@mui/icons-material/ArrowRight';
import PreviousStep from './PreviousStep';

interface PropsFromState {
  hasSelectedDebtorClaims: boolean;
  outstandingDebtorClaims: DebtorClaim[];
}

interface PropsFromDispatch {
  clearDebtorClaimsSelection: typeof DebtorClaimsStoreActionCreators.clearDebtorClaimsSelection;
  toggleDebtorClaimSelection: typeof DebtorClaimsStoreActionCreators.toggleDebtorClaimSelection;
}

interface InjectedProps {
  jumpToStep?: typeof StepZilla.jumpToStep;
}

type ComponentProps = PropsFromState & PropsFromDispatch & InjectedProps;

class PaymentAgreement extends React.Component<ComponentProps> {
  componentDidMount() {
    this.props.clearDebtorClaimsSelection();
    this.props.outstandingDebtorClaims.forEach(
      debtorClaim => debtorClaim.type === 'Invoice' || debtorClaim.type === 'DebtCollection'
        ? this.props.toggleDebtorClaimSelection({ providerSystem: debtorClaim.providerSystem, number: debtorClaim.number!.toString(), type: debtorClaim.type!.toString(), externalReferenceId: debtorClaim.externalReferenceId! })
      : null
    );
  }

  public render() {
    return (
      <React.Fragment>
        <PreviousStep previousStep={() => this.props.jumpToStep(Step.Start)} />
        <List>
          <ListItem sx={{
            paddingRight: 0
          }} button={true} disabled={!this.props.hasSelectedDebtorClaims} onClick={() => this.props.jumpToStep(Step.PaymentInformation)}>
            <ListItemText primary="Fullt oppgjør/resterende" />
            <ArrowRight />
          </ListItem>
        </List>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  hasSelectedDebtorClaims: hasSelectedDebtorClaims(state.debtorClaims),
  outstandingDebtorClaims: getOutstandingDebtorClaims(state.debtorInvoices, state.debtorLeapClaims),
});

const mapDispatchToProps: PropsFromDispatch = {
  clearDebtorClaimsSelection: DebtorClaimsStoreActionCreators.clearDebtorClaimsSelection,
  toggleDebtorClaimSelection: DebtorClaimsStoreActionCreators.toggleDebtorClaimSelection,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentAgreement);
