import { AppThunkAction } from '../store';
import { ActionTypes as NotificationsStoreActionTypes, AddNotificationAction } from './NotificationsStore';
import { FetchConfig } from './middlewares/FetchMiddleware';
import { history } from '../AppHistory';
import { PowerOfAttorney, AvailablePowerOfAttorney } from '../api/client';
import { Reducer } from 'redux';
import { v4 } from 'uuid';

export enum ActionTypes {
  GET_AVAILABLE_POWER_OF_ATTORNEYS = '@@powerOfAttorney/GET_AVAILABLE_POWER_OF_ATTORNEYS',
  GET_AVAILABLE_POWER_OF_ATTORNEYS_REQUEST = '@@powerOfAttorney/GET_AVAILABLE_POWER_OF_ATTORNEYS_REQUEST',
  GET_AVAILABLE_POWER_OF_ATTORNEYS_SUCCESS = '@@powerOfAttorney/GET_AVAILABLE_POWER_OF_ATTORNEYS_SUCCESS',
  GET_AVAILABLE_POWER_OF_ATTORNEYS_FAILURE = '@@powerOfAttorney/GET_AVAILABLE_POWER_OF_ATTORNEYS_FAILURE',
  REGISTER_POWER_OF_ATTORNEY = '@@powerOfAttorney/SUBMIT_CONVERSATION',
  REGISTER_POWER_OF_ATTORNEY_REQUEST = '@@powerOfAttorney/REGISTER_POWER_OF_ATTORNEY_REQUEST',
  REGISTER_POWER_OF_ATTORNEY_SUCCESS = '@@powerOfAttorney/REGISTER_POWER_OF_ATTORNEY_SUCCESS',
  REGISTER_POWER_OF_ATTORNEY_FAILURE = '@@powerOfAttorney/REGISTER_POWER_OF_ATTORNEY_FAILURE',
  DEACTIVATE_POWER_OF_ATTORNEY = '@@powerOfAttorney/DEACTIVATE_POWER_OF_ATTORNEY',
  DEACTIVATE_POWER_OF_ATTORNEY_REQUEST = '@@powerOfAttorney/DEACTIVATE_POWER_OF_ATTORNEY_REQUEST',
  DEACTIVATE_POWER_OF_ATTORNEY_SUCCESS = '@@powerOfAttorney/DEACTIVATE_POWER_OF_ATTORNEY_SUCCESS',
  DEACTIVATE_POWER_OF_ATTORNEY_FAILURE = '@@powerOfAttorney/DEACTIVATE_POWER_OF_ATTORNEY_FAILURE',
  REGISTER_SELECTED_POWER_OF_ATTORNEY = '@@powerOfAttorney/REGISTER_SELECTED_POWER_OF_ATTORNEY',
  CLEAR_SELECTED_POWER_OF_ATTORNEY = '@@powerOfAttorney/CLEAR_SELECTED_POWER_OF_ATTORNEY',
  GET_SELECTED_POWER_OF_ATTORNEY = '@@powerOfAttorney/GET_SELECTED_POWER_OF_ATTORNEY',
  CLEAR_POWER_OF_ATTORNEYS = '@@powerOfAttorney/CLEAR_POWER_OF_ATTORNEYS',
}

export interface PowerOfAttorneyState {
  availablePowerOfAttorneys: AvailablePowerOfAttorney[];
  selectedPowerOfAttorney: AvailablePowerOfAttorney | null;
    isLoading: boolean;
}

interface GetAvailablePowerOfAttorneysAction { type: ActionTypes.GET_AVAILABLE_POWER_OF_ATTORNEYS; fetchConfig: FetchConfig; }
interface GetAvailablePowerOfAttorneysRequestAction { type: ActionTypes.GET_AVAILABLE_POWER_OF_ATTORNEYS_REQUEST; debtorId: string; }
interface GetAvailablePowerOfAttorneysSuccessAction { type: ActionTypes.GET_AVAILABLE_POWER_OF_ATTORNEYS_SUCCESS; debtorId: string; response: AvailablePowerOfAttorney[]; }
interface GetAvailablePowerOfAttorneysFailureAction { type: ActionTypes.GET_AVAILABLE_POWER_OF_ATTORNEYS_FAILURE; debtorId: string; }
interface RegisterPowerOfAttorneyAction { type: ActionTypes.REGISTER_POWER_OF_ATTORNEY; fetchConfig: FetchConfig; }
interface RegisterPowerOfAttorneyRequestAction { type: ActionTypes.REGISTER_POWER_OF_ATTORNEY_REQUEST; }
interface RegisterPowerOfAttorneySuccessAction { type: ActionTypes.REGISTER_POWER_OF_ATTORNEY_SUCCESS; }
interface RegisterPowerOfAttorneyFailureAction { type: ActionTypes.REGISTER_POWER_OF_ATTORNEY_FAILURE; }
interface DeactivatePowerOfAttorneyAction { type: ActionTypes.DEACTIVATE_POWER_OF_ATTORNEY; fetchConfig: FetchConfig; }
interface DeactivatePowerOfAttorneyRequestAction { type: ActionTypes.DEACTIVATE_POWER_OF_ATTORNEY_REQUEST; debtorId: string; id: string; }
interface DeactivatePowerOfAttorneySuccessAction { type: ActionTypes.DEACTIVATE_POWER_OF_ATTORNEY_SUCCESS; debtorId: string; id: string; deactivatedBy: string; }
interface DeactivatePowerOfAttorneyFailureAction { type: ActionTypes.DEACTIVATE_POWER_OF_ATTORNEY_FAILURE; debtorId: string; id: string; }
interface RegisterSelectedPowerOfAttorneyAction { type: ActionTypes.REGISTER_SELECTED_POWER_OF_ATTORNEY; powerOfAttorney: AvailablePowerOfAttorney; }
interface ClearSelectedPowerOfAttorneyAction { type: ActionTypes.CLEAR_SELECTED_POWER_OF_ATTORNEY; }
interface GetSelectedPowerOfAttorneyAction { type: ActionTypes.GET_SELECTED_POWER_OF_ATTORNEY; powerOfAttorney: AvailablePowerOfAttorney; }
interface ResetPowerOfAttorneysAction { type: ActionTypes.CLEAR_POWER_OF_ATTORNEYS; }

export type KnownAction = AddNotificationAction
  | GetAvailablePowerOfAttorneysAction
  | GetAvailablePowerOfAttorneysRequestAction
  | GetAvailablePowerOfAttorneysSuccessAction
  | GetAvailablePowerOfAttorneysFailureAction
  | RegisterPowerOfAttorneyAction
  | RegisterPowerOfAttorneyRequestAction
  | RegisterPowerOfAttorneySuccessAction
  | RegisterPowerOfAttorneyFailureAction
  | DeactivatePowerOfAttorneyAction
  | DeactivatePowerOfAttorneyRequestAction
  | DeactivatePowerOfAttorneySuccessAction
  | DeactivatePowerOfAttorneyFailureAction
  | RegisterSelectedPowerOfAttorneyAction
  | ClearSelectedPowerOfAttorneyAction
  | GetSelectedPowerOfAttorneyAction
  | ResetPowerOfAttorneysAction;

export const actionCreators = {
  getAvailablePowerOfAttorneys: (debtorId: string): AppThunkAction<KnownAction> => dispatch => {
    return dispatch({
      type: ActionTypes.GET_AVAILABLE_POWER_OF_ATTORNEYS,
      fetchConfig: {
        init: { type: ActionTypes.GET_AVAILABLE_POWER_OF_ATTORNEYS_REQUEST, debtorId },
        path: `/api/powerofattorneys?debtorId=${debtorId}`,
        signal: undefined,
        success: (json) => {
          dispatch({ type: ActionTypes.GET_AVAILABLE_POWER_OF_ATTORNEYS_SUCCESS, debtorId: debtorId, response: json });
        },
        failure: (error: string) => {
          dispatch({ type: ActionTypes.GET_AVAILABLE_POWER_OF_ATTORNEYS_FAILURE, debtorId });
          dispatch({ type: NotificationsStoreActionTypes.ADD_NOTIFICATION, notification: { message: `Kunne ikke hente fullmakter for debtor ${debtorId}`, variant: 'error' } });
        }
      }
    });
  },
  registerPowerOfAttorney: (
    debtorId: string,
    onBehalfOf: string,
    grantTo: string,
    duration: string,
    includeAccess: boolean,
    includeAgreement: boolean
  ): AppThunkAction<KnownAction> => (dispatch, getState) => {

    const powerOfAttorney: PowerOfAttorney = {
      id: v4(),
      debtorId: debtorId,
      onBehalfOf: onBehalfOf,
      grantTo: grantTo,
      duration: duration,
      includeAccess: includeAccess,
      includeAgreement: includeAgreement
    };
    const selectedPowerOfAttorneyFromNew: AvailablePowerOfAttorney = {
      id: powerOfAttorney.id,
      debtorId: powerOfAttorney.debtorId,
      onBehalfOf: powerOfAttorney.onBehalfOf,
      grantTo: powerOfAttorney.grantTo,
      includeAccess: powerOfAttorney.includeAccess,
      includeAgreement: powerOfAttorney.includeAgreement,
      duration: powerOfAttorney.duration,
      registeredAtUtc: new Date(),
      registeredBy: undefined,
      active: true,
      deactivatedBy: undefined,
      deactivatedAtUtc: undefined
    };

    return dispatch({
      type: ActionTypes.REGISTER_POWER_OF_ATTORNEY,
      fetchConfig: {
        init: { type: ActionTypes.REGISTER_POWER_OF_ATTORNEY_REQUEST },
        path: `/api/powerofattorneys`,
        method: 'POST',
        body: powerOfAttorney,
        signal: undefined,
        success: (json) => {
          dispatch({ type: ActionTypes.REGISTER_POWER_OF_ATTORNEY_SUCCESS });
          dispatch({ type: ActionTypes.REGISTER_SELECTED_POWER_OF_ATTORNEY, powerOfAttorney: selectedPowerOfAttorneyFromNew });
          history.push(`/debtorlookup`);
        },
        failure: (error: string) => {
          dispatch({ type: ActionTypes.REGISTER_POWER_OF_ATTORNEY_FAILURE });
          dispatch({ type: NotificationsStoreActionTypes.ADD_NOTIFICATION, notification: { message: 'Kunne ikke registrere fullmakten', variant: 'error' } });
        }
      }
    });
  },
  deactivatePowerOfAttorney: (debtorId: string, id: string, deactivatedBy: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
    return dispatch({
      type: ActionTypes.DEACTIVATE_POWER_OF_ATTORNEY,
      fetchConfig: {
        init: { type: ActionTypes.DEACTIVATE_POWER_OF_ATTORNEY_REQUEST },
        path: `/api/powerofattorneys`,
        method: 'PUT',
        body: { debtorId: debtorId, powerOfAttorneyId: id },
        signal: undefined,
        success: (json) => {
          dispatch({ type: ActionTypes.DEACTIVATE_POWER_OF_ATTORNEY_SUCCESS, debtorId: debtorId, id: id, deactivatedBy: deactivatedBy });
        },
        failure: (error: string) => {
          dispatch({ type: ActionTypes.DEACTIVATE_POWER_OF_ATTORNEY_FAILURE, debtorId: debtorId, id: id });
          dispatch({ type: NotificationsStoreActionTypes.ADD_NOTIFICATION, notification: { message: 'Kunne ikke deaktivere fullmakten', variant: 'error' } });
        }
      }
    });
  },
  registerSelectedPowerOfAttorney: (powerOfAttorney: AvailablePowerOfAttorney): AppThunkAction<KnownAction> => (dispatch, getState) => {
    return dispatch({ type: ActionTypes.REGISTER_SELECTED_POWER_OF_ATTORNEY, powerOfAttorney: powerOfAttorney });
  },
  clearSelectedPowerOfAttorney: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    return dispatch({ type: ActionTypes.CLEAR_SELECTED_POWER_OF_ATTORNEY });
  },
  clearPowerOfAttorneys: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    return dispatch({ type: ActionTypes.CLEAR_POWER_OF_ATTORNEYS });
  },
};

export const initialState: PowerOfAttorneyState = { availablePowerOfAttorneys: [], selectedPowerOfAttorney: null, isLoading: false };

export const reducer: Reducer<PowerOfAttorneyState | undefined, KnownAction> = (state: PowerOfAttorneyState = initialState, action: KnownAction) => {
  switch (action.type) {
    case ActionTypes.GET_AVAILABLE_POWER_OF_ATTORNEYS_REQUEST:
      return { ...state, isLoading: true };
    case ActionTypes.GET_AVAILABLE_POWER_OF_ATTORNEYS_SUCCESS:
      return { ...state, availablePowerOfAttorneys: action.response, isLoading: false };
    case ActionTypes.REGISTER_SELECTED_POWER_OF_ATTORNEY:
      return { ...state, selectedPowerOfAttorney: action.powerOfAttorney };
    case ActionTypes.CLEAR_SELECTED_POWER_OF_ATTORNEY:
      return { ...state, selectedPowerOfAttorney: null };
    case ActionTypes.DEACTIVATE_POWER_OF_ATTORNEY_SUCCESS:
      return {
        ...state, availablePowerOfAttorneys: state.availablePowerOfAttorneys.map(powerOfAttorney => {
          if (powerOfAttorney.id === action.id) {
            powerOfAttorney.active = false;
            powerOfAttorney.deactivatedBy = action.deactivatedBy;
            powerOfAttorney.deactivatedAtUtc = new Date();
          }
          return powerOfAttorney;
        })
      };
    case ActionTypes.GET_AVAILABLE_POWER_OF_ATTORNEYS_FAILURE:
      return initialState;
    case ActionTypes.CLEAR_POWER_OF_ATTORNEYS:
      return initialState;
    default:
      return state;
  }
};