import React from 'react';
import { formatDate } from '../../Utils';
import { ObjectionRegistered } from '../../../api/client';
import Typography from '@mui/material/Typography';

interface Props {
  item: ObjectionRegistered;
}

export default function ObjectionRegisteredDetails(props: Props) {
  return (
    <React.Fragment>
      <Typography>
        <b>Dato:</b> {formatDate(props.item.eventDate)}
      </Typography>
      <Typography>
        <b>Av:</b> {props.item.registeredBy}
      </Typography>
      <Typography sx={{ whiteSpace: 'pre-line' }}>
        <b>Årsak: </b>{props.item.reason}
      </Typography>
    </React.Fragment>
  )
};
