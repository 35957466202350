import React from 'react';
import { LoginType, MsalAuthProviderFactory } from 'react-aad-msal';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/AuthenticationStore';
import Typography from '@mui/material/Typography';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import compose from 'recompose/compose';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface AuthProvider {
  login(): void;
  userInfoChangedCallback(user: any): void;
  isLoggedIn(): boolean;
  checkIfUserAuthenticated(): void;
  acquireTokens(idToken: string): void;
}

interface PropsFromRoute extends RouteComponentProps<{}> {
}

interface PropsFromDispatch {
  authenticated: typeof actionCreators.authenticated;
}

type ComponentProps = PropsFromRoute & PropsFromDispatch;

class Login extends React.Component<ComponentProps> {
  constructor(props: any) {
    super(props);

    const providerFactory: MsalAuthProviderFactory = new MsalAuthProviderFactory({
      clientID: this.getClientId(),
      scopes: this.getScope(),
      authority: process.env.REACT_APP_AUTHORIZATION_AUTHORITY,
      type: LoginType.Redirect,
      persistLoginPastSession: true
    });

    this.provider = providerFactory.getAuthProvider() as AuthProvider;
    this.provider.userInfoChangedCallback = (payload: any) => {
      this.props.authenticated(payload);
      // Until state parameter is available we use local storage https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/262
      if (this.timer === null) {
        this.timer = setInterval(
          () => {
            this.provider.acquireTokens('not in use atm');
          },
          1 * 60 * 1000); // Refreshing token from cache unless it has expired and is within the offset of 5 mins.
        this.props.history.push('/');
      }
    };
  }

  private provider: AuthProvider;
  private timer: NodeJS.Timer | null = null;

  componentDidMount() {
    if (this.isAuthenticating() === false && this.provider.isLoggedIn() === false) {
      this.provider.login();
    }
  }

  public render() {
    return (
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: '20px'
      }}>
        <CircularProgress size={75} />
        <Typography>Holder på å logge deg inn...</Typography>
      </Box>
    );
  }

  private isAuthenticating(): boolean {
    return window.location.hash !== '';
  }

  private getClientId(): string | undefined {
    const hostname = window.location.hostname;

    if (hostname.startsWith('conveneportal.dev') || hostname.startsWith('app-mcportal-webapp-dev.azurewebsites.net') || hostname === 'localhost') {
      return process.env.REACT_APP_AUTHORIZATION_CLIENT_ID_DEV;
    }

    if (hostname.startsWith('conveneportal.qa') || hostname.startsWith('app-mcportal-webapp-qa.azurewebsites.net')) {
      return process.env.REACT_APP_AUTHORIZATION_CLIENT_ID_QA;
    }

    return process.env.REACT_APP_AUTHORIZATION_CLIENT_ID_PROD;
  }

  private getScope() : Array<string> {
    const hostname = window.location.hostname;
    if (hostname.startsWith('conveneportal.dev') || hostname.startsWith('app-mcportal-webapp-dev.azurewebsites.net') || hostname === 'localhost') {
      return new Array(`https://conveneportal.dev.convenegroup.com${process.env.REACT_APP_AUTHORIZATION_SCOPES}`);
    }

    if (hostname.startsWith('conveneportal.qa') || hostname.startsWith('app-mcportal-webapp-qa.azurewebsites.net')) {
      return new Array(`https://conveneportal.qa.convenegroup.com${process.env.REACT_APP_AUTHORIZATION_SCOPES}`);
    }

    return new Array(`https://conveneportal.convenegroup.com${process.env.REACT_APP_AUTHORIZATION_SCOPES}`);
  };
}

const mapDispatchToProps: PropsFromDispatch = {
  authenticated: actionCreators.authenticated,
};

export default compose(
  connect(null, mapDispatchToProps))
  (withRouter(Login));
