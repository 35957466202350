import React from 'react';
import { history } from '../../AppHistory';
import securePage from '../../components/authentication/SecurePage';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { getStartingPageForLoginWithRoles } from '../Utils';
import { PageProps } from '../authentication/DefaultPage';

class LayoutComponent extends React.Component<PageProps> {
  componentDidMount() {
    console.group('RootNode');
    const startPageForRole = getStartingPageForLoginWithRoles(this.props.roles);
    const localStorageRedirectTo = localStorage.getItem('authorization.redirectTo');
    const redirect =
      (
        localStorageRedirectTo && localStorageRedirectTo !== '/'
          ? localStorageRedirectTo
          : startPageForRole
            ? startPageForRole
            : '/forbidden'
      );
    console.log('rootnode redirecting to:', redirect, localStorageRedirectTo, startPageForRole, this.props.roles, this.props.isAuthenticated);
    console.groupEnd();
    if (!this.props.isAuthenticated) {
      history.push('/login');
    } else {
      history.push(redirect);
    }
  }

  render() {
    return (
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: '20px',
      }}>
        <CircularProgress size={75} />
        <Typography>
          Prøver å finne startsiden din.
        </Typography>
      </Box>
    );
  }
}

export default securePage(LayoutComponent);
