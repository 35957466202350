import React from 'react';
import SecurePage from '../../components/authentication/SecurePage';
import { Redirect } from 'react-router-dom';
import { PageProps } from './DefaultPage';

const terminalWorkerSecurePageHoc = Page => class TerminalPage extends React.Component<PageProps> {
  render() {
    if (!this.props.terminalWorker) {
      return <Redirect to="/forbidden" />;
    }

    return <Page {...this.props} />;
  }
};

const secureTerminalWorkerPage = Page => SecurePage(terminalWorkerSecurePageHoc(Page));

export default secureTerminalWorkerPage;
