import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ReactHtmlParser from 'react-html-parser';

interface Props {
  highlights: any;
}

function translateField(value: string): string {
  switch (value) {
    case 'name': return 'Navn';
    case 'dateOfBirth': return 'Fødselsdato';
    case 'debtorIds': return 'Kundenummer';
    case 'nationalIdentityNumber': return 'Fødselsnummer';
    case 'phoneNumbers': return 'Telefonnummer';
    case 'invoiceNumbers': return 'Fakturanummer';
    case 'caseNumbers': return 'Saksnummer';
    case 'orderNumbers': return 'Ordrenummer';
    case 'kidNumbers': return 'KID';
    default:
      return value;
  }
}

export default function DebtorSearchResultReason(props: Props) {
  return (
    <Box sx={{
      marginTop: '10px',
      display: 'flex'
    }}>
      <Typography sx={{
        paddingTop: '3px',
        fontWeight: 'bold',
        marginRight: '6px',
        fontSize: '0.875rem'
      }}>
        Treff på:
      </Typography>
      {props.highlights && Object.keys(props.highlights).map((item, i) =>
        <Typography sx={{
          borderWidth: '1px',
          borderStyle: 'dashed',
          borderColor: 'primary.dark',
          borderRadius: '25px',
          padding: '3px 8px',
          fontSize: '0.875rem',
          '& em': {
            backgroundColor: 'primary.light',
          },
        }} key={i}>
          {translateField(item)}:{' '}{ReactHtmlParser(props.highlights[item])}
        </Typography>
      )}
    </Box>
  )
};
