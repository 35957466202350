import React from 'react';
import compose from 'recompose/compose';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { actionCreators as PowerOfAttorneyStoreActionCreators } from '../../store/PowerOfAttorneyStore';
import { history } from '../../AppHistory';
import { AvailablePowerOfAttorney } from '../../api/client';
import { ResultOfDebtorIndex } from '../../api/client';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RegisterNewPowerOfAttorney from '../../components/powerOfAttorney/containers/RegisterNewPowerOfAttorney';
import ExistingPowerOfAttorneys from '../../components/powerOfAttorney/containers/ExistingPowerOfAttorneys';
import NoPowerOfAttorney from '../../components/powerOfAttorney/containers/NoPowerOfAttorney';
import { getExistingPowerOfAttorneys } from '../../store/Selectors';
import { actionCreators as DebtorSearchActionCreators } from '../../store/DebtorSearchStore';

interface PropsFromState {
  isLoading: boolean;
  availablePowerOfAttorneys: AvailablePowerOfAttorney[];
}

interface PropsFromDispatch {
  getAvailablePowerOfAttorneys: typeof PowerOfAttorneyStoreActionCreators.getAvailablePowerOfAttorneys;
  clearSelectedPowerOfAttorney: typeof PowerOfAttorneyStoreActionCreators.clearSelectedPowerOfAttorney;
  selectDebtor: typeof DebtorSearchActionCreators.selectDebtor;
}

interface InjectedProps {
  debtor: ResultOfDebtorIndex;
}

interface State {
  isModalOpen: boolean;
  expandedPanel: Panels;
}

enum Panels {
  RegisterNewPowerOfAttorney = 0,
  NoPowerOfAttorney = 1,
  ExistingPowerOfAttorneys = 2,
  InsightWithoutPowerOfAttorney = 3,
}

type ComponentProps = PropsFromState & PropsFromDispatch & InjectedProps;

export class PowerOfAttorney extends React.Component<ComponentProps, State> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      isModalOpen: false,
      expandedPanel: Panels.RegisterNewPowerOfAttorney
    };
  }

  toggleModal = () => {
    this.props.clearSelectedPowerOfAttorney();
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  handleOK = () => {
    this.props.selectDebtor(this.props.debtor);
    history.push(`/debtorlookup`);
  }

  selectPanel = selectedPanel => (event, expanded) => {
    this.setState({
      expandedPanel: expanded ? selectedPanel : false,
    });
  }

  public render() {
    const { expandedPanel } = this.state;

    return (
      <React.Fragment>
        <Button variant="contained" onClick={() => { this.toggleModal(); this.props.getAvailablePowerOfAttorneys(this.props.debtor.document!.id!); }}>
          Annen ringer
        </Button>
        <Modal
          aria-labelledby="annen-ringer"
          aria-describedby="annen-ringer"
          open={this.state.isModalOpen}
          onClose={this.toggleModal}
        >
          <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '600px',
            bgcolor: 'background.paper',
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)',
            padding: '32px',
          }}>
            <Typography variant="h6" sx={{
              fontWeight: 'bold',
              paddingBottom: '8px'
            }}>
                Fullmakt
              </Typography>
              <Accordion expanded={expandedPanel === Panels.RegisterNewPowerOfAttorney} onChange={this.selectPanel(Panels.RegisterNewPowerOfAttorney)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Registrer ny fullmakt</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ display: 'block' }}>
                  <RegisterNewPowerOfAttorney toggleModal={this.toggleModal} debtor={this.props.debtor} />
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expandedPanel === Panels.NoPowerOfAttorney} onChange={this.selectPanel(Panels.NoPowerOfAttorney)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Har ikke fullmakt</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ display: 'block' }}>
                  <NoPowerOfAttorney toggleModal={this.toggleModal} debtor={this.props.debtor.document!} />
                </AccordionDetails>
              </Accordion>

              {this.props.isLoading
              ? <CircularProgress sx={{
                display: 'block',
                margin: '30px auto 0px auto'
              }} size={50} />
              : this.props.availablePowerOfAttorneys && this.props.availablePowerOfAttorneys.length > 0 &&
              <React.Fragment>
                <Accordion expanded={expandedPanel === Panels.ExistingPowerOfAttorneys} onChange={this.selectPanel(Panels.ExistingPowerOfAttorneys)}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Eksisterende fullmakter ({this.props.availablePowerOfAttorneys.length})</Typography>
                  </AccordionSummary>
                    <AccordionSummary sx={{ display: 'block' }}>
                    <ExistingPowerOfAttorneys toggleModal={this.toggleModal} handleOk={this.handleOK} />
                  </AccordionSummary>
                </Accordion>
              </React.Fragment>
            }
          </Box>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  isLoading: state.powerOfAttorney.isLoading,
  availablePowerOfAttorneys: getExistingPowerOfAttorneys(state),
});

const mapDispatchToProps: PropsFromDispatch = {
  getAvailablePowerOfAttorneys: PowerOfAttorneyStoreActionCreators.getAvailablePowerOfAttorneys,
  clearSelectedPowerOfAttorney: PowerOfAttorneyStoreActionCreators.clearSelectedPowerOfAttorney,
  selectDebtor: DebtorSearchActionCreators.selectDebtor
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(PowerOfAttorney);
