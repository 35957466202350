import React, { useCallback, useEffect, useState } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { DebtorClaim, SelectedDebtorClaim, FeeType } from '../../../api/client';
import { formatDate } from '../../../components/Utils';
import { colors } from '../../../colors';
import { getChildInvoicesForDebtCollectionClaim, getConversationActions, isDebtorClaimSelectableForConversationSupervisorStep } from '../../../store/Selectors';
import { actionCreators as DebtorClaimsStoreActionCreators } from '../../../store/DebtorClaimsStore';
import { actionCreators as DebtorDebtCollectionsStoreActionCreators } from '../../../store/DebtorDebtCollectionsStore';
import { actionCreators as DebtorInvoicesStoreActionCreators } from '../../../store/DebtorInvoicesStore';
import { actionCreators as DebtorLeapClaimsStoreActionCreators } from '../../../store/DebtorLeapClaimsStore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DebtorClaimIcon from '../../debtorOverview/presentation/DebtorClaimIcon';
import DebtorClaimSystemBadge from '../../debtorOverview/presentation/DebtorClaimSystemBadge';
import DebtorPaymentInformation from '../../debtorOverview/presentation/DebtorPaymentInformation';
import DebtorClaimEventComponent from '../../debtorClaimEvents/presentation/DebtorClaimEvent';
import DebtorClaimChildren from '../../debtorOverview/presentation/DebtorClaimChildren';
import ExternalLinkToInvoice from '../../debtorOverview/presentation/ExternalLinkToInvoice';
import ExternalDebtCollectionInfo from '../presentation/ExternalDebtCollectionInfo';
import CreditorName from '../../debtorOverview/presentation/CreditorName';
import currencyFormatter from 'currency-formatter';
import { useAppDispatch } from '../../../hooks';
import Box from '@mui/material/Box';

interface PropsFromState {
  expanded?: boolean;
  isLoadingEvents: boolean;
  isSelected: boolean;
  childClaims: DebtorClaim[];
  conversationActions: number;
  isSelectable: boolean;
}
interface InjectedProps {
  debtorClaim: DebtorClaim;
  isTopLevel: boolean;
}

type ComponentProps = PropsFromState  & InjectedProps;
const DebtorClaimComponent = (props: ComponentProps) => {
  const {
    expanded,
    isTopLevel,
    debtorClaim,
    conversationActions,
    isSelectable,
    isSelected,
    childClaims,
  } = props;

  const [isExpanded, setIsExpanded] = useState(expanded ?? false);
  const bannerBackgroundColor = props.debtorClaim.transferredToExternalCollection ? colors.secondary.lemon + '80' : !isExpanded ? colors.white: colors.lightCyan;
  const collapsePanelBackgroundColor = isTopLevel ? colors.white : colors.lightCyan;

  const dispatch = useAppDispatch();

  const toggleDebtorClaimSelection = useCallback((debtorClaim: SelectedDebtorClaim) => {
    dispatch(DebtorClaimsStoreActionCreators.toggleDebtorClaimSelection(debtorClaim));
  }, [dispatch]);

  const clearDebtorClaimsSelection = useCallback(() => {
    dispatch(DebtorClaimsStoreActionCreators.clearDebtorClaimsSelection());
  }, [dispatch]);

  const getLeapDebtCollectionEvents = useCallback((caseNumber: string) => {
    dispatch(DebtorLeapClaimsStoreActionCreators.getLeapDebtCollectionEvents(caseNumber));
  }, [dispatch]);

  const getDebtCollectionEvents = useCallback((caseNumber: string) => {
    dispatch(DebtorDebtCollectionsStoreActionCreators.getDebtCollectionEvents(caseNumber));
  }, [dispatch]);

  const getInvoiceEvents = useCallback((debtorId: string, referenceId: number, invoiceNumber: string) => {
    dispatch(DebtorInvoicesStoreActionCreators.getInvoiceEvents(debtorId, referenceId, invoiceNumber));
  }, [dispatch]);

  const getLeapEvents  = useCallback((claimId: string) => {
    dispatch(DebtorLeapClaimsStoreActionCreators.getLeapEvents(claimId));
  }, [dispatch]);

  useEffect(() => {
    clearDebtorClaimsSelection();
  }, [clearDebtorClaimsSelection])

  const handleExpandClick = useCallback(() => {
    setIsExpanded(!isExpanded);
    if (!debtorClaim.eventsHaveBeenFetched) {
      if (debtorClaim.type === 'Invoice') {
        getInvoiceEvents(debtorClaim.debtorId!.toString(), debtorClaim.eventsReferenceId, debtorClaim.number ? debtorClaim.number!.toString() : '');
      }
      else if (debtorClaim.type === 'DebtCollection') {
        getDebtCollectionEvents(debtorClaim.number!.toString());
      }
      else if (debtorClaim.type === 'LeapClaim') {
        getLeapEvents(debtorClaim.externalReferenceId!.toString());
      }
      else if (debtorClaim.type === 'LeapDebtCollectionClaim') {
        getLeapDebtCollectionEvents(debtorClaim.number!.toString());
      }
    }
  }, [
    debtorClaim.debtorId,
    debtorClaim.eventsHaveBeenFetched,
    debtorClaim.eventsReferenceId,
    debtorClaim.externalReferenceId,
    debtorClaim.number,
    debtorClaim.type,
    getDebtCollectionEvents,
    getInvoiceEvents,
    getLeapDebtCollectionEvents,
    getLeapEvents,
    isExpanded
  ]);

  const handleCheckboxChange = useCallback(() => {
    toggleDebtorClaimSelection({
      providerSystem: debtorClaim.providerSystem, number: debtorClaim.number!.toString(), type: debtorClaim.type!.toString(), externalReferenceId: debtorClaim.externalReferenceId!, balanceAmount: debtorClaim.amount,
      invoiceFeeAmount: debtorClaim.fees?.filter(x => x.type === FeeType.InvoiceFee).reduce((sum, current) => sum + current.amount, 0), noticeFeeAmount: debtorClaim.fees?.filter(x => x.type === FeeType.DebtCollectionNoticeFee).reduce((sum, current) => sum + current.amount, 0),
      
    });
  }, [debtorClaim.providerSystem, debtorClaim.externalReferenceId, debtorClaim.number, debtorClaim.type, toggleDebtorClaimSelection, debtorClaim.amount, debtorClaim.fees]);

  const enabledOpacity = 1;
  const disabledOpacity = 0.3;
  const getOutstandingText = () => {
    if (debtorClaim.transferredToExternalCollection) return 'Overført til ' + debtorClaim.externalDebtCollectionCompany!.name ?? 'ekstern innkreving';
    if (debtorClaim.isClaimToAnotherPayer) return 'FTAB';
    if (debtorClaim.amount === null || debtorClaim.amount === 0) return '';
    return `Utestående:
    ${currencyFormatter.format(debtorClaim.amount!, { code: 'NOK' })}`;
  }

  const getDebtCollectionCaseType = () => {
    if (debtorClaim.claimStage === 5 && (debtorClaim.type === 'DebtCollection' || debtorClaim.type === 'LeapDebtCollectionClaim' )) {
      if (debtorClaim.isTopLevelClaim && debtorClaim.isParentCase && debtorClaim.isCombinedDebtCollectionCase) return 'Hovedsak';
      if (debtorClaim.parentClaimIdentificationNumber) return 'Undersak';
      return '';
    } 
  }

  return (
    <Badge
      color="primary"
      badgeContent={conversationActions}
      sx={{
        margin: '0 auto',
        maxWidth: '1000px',
        minWidth: '550px',
        ...(conversationActions === 0 ? { display: 'hidden' } : { display: 'flex' }),
      }}
    >
      <Card data-id="debtorClaimItem" sx={{
          margin: '0 auto 8px auto',
          width: '100%',
          maxWidth: '1000px',
          minWidth: '550px',
          paddingBottom: '0px',
          opacity: isSelectable ? enabledOpacity : disabledOpacity}}>

        <CardContent sx={{
            display: 'flex',
            padding: 0,
            paddingBottom: 0,
            backgroundColor: bannerBackgroundColor
          }}>

          <DebtorClaimIcon variant={debtorClaim.typeAndStatus} />

          <Box sx={{
            paddingLeft: '8px',
            paddingBottom: '8px',
            paddingTop: '8px',
            width: '100%',
            minWidth: '250px',
          }}>
            <React.Fragment>
              <DebtorClaimSystemBadge system={debtorClaim.type} />
              <Box sx={{ display: 'flex' }}>
                { debtorClaim.type === 'Invoice' ?
                  <ExternalLinkToInvoice debtorId={debtorClaim.debtorId!} invoiceNumber={debtorClaim.number!} /> :
                  <Typography sx={{
                    fontWeight: 'bold',
                    fontSize: '14px'
                  }}>
                    {debtorClaim.number}
                  </Typography>
                }
                <Typography sx={{
                  fontWeight: 'bold',
                  color: 'red',
                  paddingLeft: '12px'
                }}>
                  {getDebtCollectionCaseType()}
                </Typography>
              </Box>
            </React.Fragment>
            <Box sx={{ paddingTop: '15px' }}>
              <CreditorName creditorName={debtorClaim.creditorName}/>
            </Box>
          </Box>

          <Box sx={{
            paddingLeft: '8px',
            paddingBottom: '8px',
            paddingTop: '8px',
            width: '100%',
          }}>
            <Typography variant='body2'>
              {debtorClaim.type === 'DebtCollection' ? 'Registreringsdato' : 'Ordredato'}: {formatDate(debtorClaim.date)}
            </Typography>
          </Box>
          <Box sx={{
            paddingLeft: '8px',
            paddingBottom: '8px',
            paddingTop: '8px',
            width: '100%',
          }}>
            <Typography sx={{
              overflow: 'hidden',
              fontWeight: 'bold'
            }}>
              {getOutstandingText()}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <IconButton
              onClick={handleExpandClick}
              aria-expanded={isExpanded}
              data-id="debtorClaimItemExpandButton"
              sx={{
                transform: 'rotate(0deg)',
                transition: (theme) => theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
                marginLeft: 'auto',
                alignSelf: 'center',
                ...(isExpanded === true && {
                  transform: 'rotate(180deg)'
                })
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Checkbox sx={{
              alignSelf: 'center',
              marginRight: '8px'
            }} color="default" checked={isSelected} onChange={handleCheckboxChange} disabled={!isSelectable} />
          </Box>
        </CardContent>
        <CardActions style={{ display: 'none' }} />
        <Collapse in={isExpanded} timeout="auto" unmountOnExit={true} style={{ backgroundColor: collapsePanelBackgroundColor }}>

          {!debtorClaim.transferredToExternalCollection && <DebtorPaymentInformation kid={debtorClaim.kid} accountNumber={debtorClaim.debtorPaymentAccount} />}
          {debtorClaim.transferredToExternalCollection && <ExternalDebtCollectionInfo companyInfo={debtorClaim.externalDebtCollectionCompany!} />}
          
          <Box sx={{
            maxWidth: '90%',
            margin: '0 auto',
            marginBottom: '50px'
          }}>
            {debtorClaim.events && debtorClaim.events.map((item, i) => <DebtorClaimEventComponent key={i} event={item} debtorId={debtorClaim.debtorId!} />)}
          </Box>
          {<DebtorClaimChildren childClaims={childClaims} />}
        </Collapse>
      </Card>
    </Badge>
  );
};

const mapStateToProps = (state: ApplicationState, ownProps: ComponentProps): PropsFromState => ({
  isLoadingEvents: state.debtorInvoices.isLoadingEvents || state.debtorDebtCollections.isLoadingEvents,
  isSelected: state.debtorClaims.selectedDebtorClaims.map(x => x.number).indexOf(ownProps.debtorClaim.number!) !== -1,
  childClaims: getChildInvoicesForDebtCollectionClaim(state, ownProps.debtorClaim),
  conversationActions: getConversationActions(state, ownProps.debtorClaim),
  isSelectable: isDebtorClaimSelectableForConversationSupervisorStep(state.conversationSupervisor, ownProps.debtorClaim),
});

export default compose(
  connect(mapStateToProps)
)(DebtorClaimComponent);
