import React from 'react';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { ConversationAction } from '../../../api/client';
import { actionCreators } from '../../../store/ConversationSupervisorStore';
import DeleteIcon from '@mui/icons-material/Delete';
import CircleIcon from '@mui/icons-material/Circle';
import { getConversationActionSummary, getConversationActionName } from '../../../api/actions';

export interface Props {
  index: number;
  item: ConversationAction;
  conversationWasInterrupted: boolean;
  removeConversationAction: typeof actionCreators.removeConversationAction;
}

export const getSecondaryText = (item: ConversationAction) => {
  const summary = getConversationActionSummary(item);
  return item.selectedDebtorClaims
    ? item.selectedDebtorClaims.map(x => x.number).join(', ') + ': ' + summary
    : summary;
};

export const handleDelete = (props: Props) => {
  props.removeConversationAction(props.index);
};

export const ConversationSummaryItem: React.FunctionComponent<Props> = props => {
  return (
    <ListItem>
      <ListItemIcon
        sx={{
          alignSelf: 'start',
          paddingTop: '10px',
          minWidth: '26px'
        }}>
        <CircleIcon sx={{ fontSize: '14px' }} />
      </ListItemIcon>
      <ListItemText
        primary={getConversationActionName(props.item)}
        secondary={getSecondaryText(props.item)}
        primaryTypographyProps={{
          fontSize: 16,
          fontWeight: '600',
          letterSpacing: 0,
        }}
        secondaryTypographyProps={{
          fontSize: 14,
          fontWeight: '400',
          letterSpacing: 0,
          maxHeight: '60px',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      />
      <ListItemSecondaryAction>
        <IconButton
          aria-label="Slett"
          onClick={() => handleDelete(props)}
          sx={{
            alignSelf: 'end',
            padding: 0
          }}>
          <DeleteIcon
            sx={{
              fontSize: '30px',
              marginLeft: '4px'
            }} />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default ConversationSummaryItem;
