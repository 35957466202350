import React from 'react';
import terminalWorkerPage from '../authentication/TerminalWorkerPage';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface State {
  height: number;
}

type ComponentProps = State;

class TerminalWorker extends React.Component<ComponentProps, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      height: window.innerHeight
    };
  }

  acquireToken = (event: any) => {
    if (event.data === 'acquireToken') {
      event.source.postMessage(localStorage.getItem('accessToken'), '*');
    }
  }

  updateWindowHeight = () => {
    this.setState({
      height: window.innerHeight
    });
  }

  componentDidMount() {
    window.addEventListener('message', this.acquireToken, false);
    window.addEventListener('resize', this.updateWindowHeight, false);
    console.groupEnd();
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.acquireToken, false);
    window.removeEventListener('resize', this.updateWindowHeight, false);
  }

  public render() {
    return (
      <Box sx={{
        height: `${this.state.height - 110}px`
      }}>
        <Box sx={{
          width: '100%',
          height: '100%'
        }}>
          <Typography sx={{
            color: 'secondary.dark'
          }}>
            Terminal page
          </Typography>
        </Box>
      </Box>
    );
  }
}

export default terminalWorkerPage(TerminalWorker);
