import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/icons/logo-white.svg';

interface Props {
  userProfile?: string;
  defaultPath: string;
}

export default function Logo(props: Props) {
  return (
    <Link to={props.defaultPath}>
      <img src={logo} style={{
        height: '50px'
      }} alt="logo" />
    </Link>
  )
};
