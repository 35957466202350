import React from 'react';
import Typography from '@mui/material/Typography';
import { DebtorLogItem } from '../../../api/client';
import { formatDateLong } from '../../../components/Utils';
import { colors } from '../../../colors';
import Box from '@mui/material/Box';

interface Props {
  logItem: DebtorLogItem;
  selected: boolean;
}

export default function DebtorLogItemComponent(props: Props) {
  return (
    <Box sx={{
      width: '100%',
      margin: '10px 0 10px 0'
    }}>
      <Typography sx={{ fontSize: '10px', textAlign: 'center' }}>{formatDateLong(props.logItem.registeredAt)}</Typography>
      <Box sx={{
        borderRadius: '1px',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.08)',
        fontSize: '14px',
        margin: '8px',
        padding: '8px',
        ...(props.selected === true && { backgroundColor: colors.secondary.lemon }),
        ...(props.selected === false && { backgroundColor: colors.marble })
      }}>
        {props.logItem.debtorClaimNumbers && props.logItem.debtorClaimNumbers.map(
          (item, i) => <Typography key={i} sx={{ fontWeight: 'bold' }}>{item}{'\n'}</Typography>)}
        <Typography sx={{ whiteSpace: 'pre-wrap' }}>{props.logItem.text}</Typography>
      </Box>
      <Typography sx={{
        fontSize: '10px',
        color: colors.gray,
        textAlign: 'right',
        margin: '0 8px 0 0'
      }}>
        {props.logItem.registeredBy}
      </Typography>
    </Box>
  )
};
