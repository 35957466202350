import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { ResultOfDebtorIndex } from '../../api/client';
import { actionCreators as DebtorNationalIdentificationNumberSearchActionCreators } from '../../store/DebtorNationalIdentificationNumberSearchStore';
import CircularProgress from '@mui/material/CircularProgress';
import { Redirect } from 'react-router-dom';
import { createDebtorIdsQueryString } from '../Utils';
import Box from '@mui/material/Box';


interface PropsFromState {
  isSearching: boolean;
  searchComplete: boolean;
  selectedResult?: ResultOfDebtorIndex | null;
  nationalIdentificationNumberResults?: ResultOfDebtorIndex[];
  validSearch?: boolean | null;
}

interface PropsFromDispatch {
  searchDebtorsByNationalIdentificationNumber: typeof DebtorNationalIdentificationNumberSearchActionCreators.searchDebtorsByNationalIdentificationNumber;
  setValidSearch: typeof DebtorNationalIdentificationNumberSearchActionCreators.setValidSearch;
}

type ComponentProps = PropsFromState & PropsFromDispatch;

class DebtorSearchResultFindRelatedDebtorIds extends React.Component<ComponentProps> {

  componentDidMount() {
    if (
      this.props.selectedResult != null &&
      this.props.selectedResult.document != null &&
      this.props.selectedResult.document.nationalIdentityNumber != null &&
      this.props.selectedResult.document.nationalIdentityNumber.length === 11 &&
      this.props.selectedResult.highlights &&
      (Object.keys(this.props.selectedResult.highlights)).indexOf('nationalIdentityNumber') === -1
    ) {
      this.props.setValidSearch(true);
      this.props.searchDebtorsByNationalIdentificationNumber(this.props.selectedResult.document.nationalIdentityNumber);
    } else {
      this.props.setValidSearch(false);
    }
  }

  public render() {
    if (
      (
        this.props.validSearch === false ||
        (this.props.searchComplete === true && !this.props.nationalIdentificationNumberResults)
      ) || (
        this.props.validSearch === true &&
        this.props.searchComplete === true &&
        (this.props.nationalIdentificationNumberResults == null || this.props.nationalIdentificationNumberResults[0] == null)
      )
    ) {
      const debtorUrl = `/debtor?${createDebtorIdsQueryString(this.props.selectedResult!.document!.relatedDebtorIds)}`;
      return (
        <Redirect to={debtorUrl} />
      );
    }
    if (
      this.props.validSearch === true &&
      this.props.searchComplete === true &&
      this.props.nationalIdentificationNumberResults != null &&
      this.props.nationalIdentificationNumberResults[0] != null &&
      this.props.nationalIdentificationNumberResults[0].document != null &&
      this.props.nationalIdentificationNumberResults[0].document.relatedDebtorIds != null &&
      this.props.nationalIdentificationNumberResults[0].document.relatedDebtorIds.length > 0
    ) {
      const debtorUrl = `/debtor?${createDebtorIdsQueryString(this.props.nationalIdentificationNumberResults![0].document!.relatedDebtorIds)}`;
      return (
        <Redirect to={debtorUrl} />
      );
    }
    return (
      <React.Fragment>
        {!this.props.searchComplete &&
          <Box sx={{
            width: '75px',
            margin: '0 auto',
          }}>
            <CircularProgress size={75} />
          </Box>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ debtorSearch, debtorNationalIdentificationNumberSearch }: ApplicationState): PropsFromState => ({
  isSearching: debtorNationalIdentificationNumberSearch.isSearching,
  selectedResult: debtorSearch.selectedResult,
  searchComplete: debtorNationalIdentificationNumberSearch.searchComplete,
  nationalIdentificationNumberResults: debtorNationalIdentificationNumberSearch.results ? debtorNationalIdentificationNumberSearch.results.results : [],
  validSearch: debtorNationalIdentificationNumberSearch.validSearch
});

const mapDispatchToProps: PropsFromDispatch = {
  searchDebtorsByNationalIdentificationNumber:  DebtorNationalIdentificationNumberSearchActionCreators.searchDebtorsByNationalIdentificationNumber,
  setValidSearch: DebtorNationalIdentificationNumberSearchActionCreators.setValidSearch
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(DebtorSearchResultFindRelatedDebtorIds);
