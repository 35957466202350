import React from 'react';
import Box from '@mui/material/Box';
import { colors } from '../../../colors';
import invoiceWhite from '../../../assets/images/icons/invoice-white.svg';
import invoiceLost from '../../../assets/images/icons/invoice-lost.svg';
import debtCollectionWhite from '../../../assets/images/icons/debt-collection-white.svg';

const icons = {};
icons['InvoiceActive'] = { tooltip: 'Aktiv', backgroundColor: colors.blue, path: invoiceWhite };
icons['InvoicePaid'] = { tooltip: 'Betalt', backgroundColor: colors.green, path: invoiceWhite };
icons['InvoiceForgiven'] = { tooltip: 'Ettergitt', backgroundColor: colors.darkGray, path: invoiceWhite };
icons['InvoiceCredited'] = { tooltip: 'Kreditert', backgroundColor: colors.darkGray, path: invoiceWhite };
icons['InvoiceCancelled'] = { tooltip: 'Kansellert', backgroundColor: colors.darkGray, path: invoiceWhite };
icons['InvoiceBookedAsLost'] = { tooltip: 'Tapsført', backgroundColor: colors.blue, path: invoiceWhite };
icons['InvoiceDebtCollection'] = { tooltip: 'Sendt til inkasso', backgroundColor: colors.blue, path: invoiceLost };
icons['InvoiceHalted'] = { tooltip: 'Stanset', backgroundColor: colors.orange, path: invoiceWhite };
icons['InvoiceExpired'] = { tooltip: 'Foreldet', backgroundColor: colors.secondary.zeus, path: invoiceWhite };

icons['LeapClaimActive'] = { tooltip: 'Aktiv', backgroundColor: colors.blue, path: invoiceWhite };
icons['LeapClaimPaid'] = { tooltip: 'Betalt', backgroundColor: colors.green, path: invoiceWhite };
icons['LeapClaimCredited'] = { tooltip: 'Kreditert', backgroundColor: colors.darkGray, path: invoiceWhite };
icons['LeapClaimDebtCollection'] = { tooltip: 'Sendt til inkasso', backgroundColor: colors.blue, path: invoiceLost };
icons['LeapClaimExternalDebtCollection'] = { tooltip: 'Sendt til ekstern inkasso', backgroundColor: colors.red, path: invoiceWhite };

icons['LeapDebtCollectionClaimActive'] = { tooltip: 'Aktiv inkasso', backgroundColor: colors.red, path: invoiceWhite };
icons['LeapDebtCollectionClaimPaid'] = { tooltip: 'Betalt inkasso', backgroundColor: colors.green, path: invoiceWhite };
icons['LeapDebtCollectionClaimCredited'] = { tooltip: 'Kreditert inkasso', backgroundColor: colors.darkGray, path: invoiceWhite };
icons['LeapDebtCollectionClaimClosed'] = { tooltip: 'Avsluttet inkasso', backgroundColor: colors.darkGray, path: debtCollectionWhite };
icons['LeapDebtCollectionClaimDebtCollection'] = { tooltip: 'Inkasso', backgroundColor: colors.red, path: debtCollectionWhite };
icons['LeapDebtCollectionClaimExpired'] = { tooltip: 'Foreldet inkasso', backgroundColor: colors.secondary.zeus, path: debtCollectionWhite };

icons['DebtCollectionActive'] = { tooltip: 'Aktiv', backgroundColor: colors.red, path: debtCollectionWhite };
icons['DebtCollectionPaid'] = { tooltip: 'Betalt', backgroundColor: colors.green, path: debtCollectionWhite };
icons['DebtCollectionForgiven'] = { tooltip: 'Ettergitt', backgroundColor: colors.darkGray, path: debtCollectionWhite };
icons['DebtCollectionClosed'] = { tooltip: 'Avsluttet inkasso', backgroundColor: colors.darkGray, path: debtCollectionWhite };
icons['DebtCollectionHalted'] = { tooltip: 'Stanset', backgroundColor: colors.orange, path: debtCollectionWhite };
icons['DebtCollectionObjectionReceived'] = { tooltip: 'Innsigelse', backgroundColor: colors.orange, path: debtCollectionWhite };
icons['DebtCollectionExpired'] = { tooltip: 'Foreldet inkasso', backgroundColor: colors.secondary.zeus, path: debtCollectionWhite };

interface Props {
  variant: any;
}

export default function DebtorClaimIcon(props: Props) {
  const icon = icons[props.variant];

  return (
    <Box sx={{
        flex: 1,
        padding: '1em',
        maxWidth: '33px',
        backgroundColor: icon.backgroundColor
      }}
    >
      <img src={ icon.path } alt="icon" title={ icon.tooltip } style={{
          display: 'block'
        }}
      />
    </Box>
  );
};
