import React from 'react';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { colors } from '../../../colors';
import Link from '@mui/material/Link';

interface Props {
  debtorId: string;
  invoiceNumber: string;
}

const path = '/Melin/Admin/EconomyCustomerAccountCard.aspx?handler=handleCustomerAccountCardForm';

enum InvoiceSystems {
  MMSYS1 = 'mmsys1',
  MMSYS2 = 'mmsys2'
}

const getSystem = (debtorId: string) => {
  return debtorId.startsWith(InvoiceSystems.MMSYS1) ? InvoiceSystems.MMSYS1 : InvoiceSystems.MMSYS2;
};

const getHostName = (debtorId: string) => {
  const hostname = window.location.hostname;
  const system = getSystem(debtorId);
  if (hostname.startsWith('conveneportal.dev') || hostname === 'localhost') {
    return system ===  InvoiceSystems.MMSYS1 ? process.env.REACT_APP_MMSYS1_HOST_NAME_DEV : process.env.REACT_APP_MMSYS2_HOST_NAME_DEV;
  }

  if (hostname.startsWith('conveneportal.qa')) {
    return system === InvoiceSystems.MMSYS1 ? process.env.REACT_APP_MMSYS1_HOST_NAME_QA : process.env.REACT_APP_MMSYS2_HOST_NAME_QA;
  }

  return system === InvoiceSystems.MMSYS1 ? process.env.REACT_APP_MMSYS1_HOST_NAME_PROD : process.env.REACT_APP_MMSYS2_HOST_NAME_PROD;
};

const trimOffSystem = (debtorId: string) => {
  return debtorId.slice(debtorId.indexOf('-') + 1);
};

export default function ExternalLinkToInvoice(props: Props) {
  return (
    <Link
      href={`${getHostName(props.debtorId)}${path}&debitorId=${trimOffSystem(props.debtorId)}&debitorType=0&invoiceNumber=${props.invoiceNumber}`}
      rel="noreferrer"
      target="_blank"
      sx={{
        color: colors.black,
        fontFamily: (theme) => theme.typography.fontFamily,
        fontSize: (theme) => theme.typography.fontSize,
        fontWeight: 'bold',
        textDecoration: 'none'
      }}>
      {props.invoiceNumber}
      <OpenInNew sx={{
        fontSize: '14px',
        marginLeft: '4px'
      }}>
      </OpenInNew>
    </Link>
  )
};
